.customElementor {
  --e-global-color-primary: #093f46;
  --e-global-color-secondary: #c0d20f;
  --e-global-color-text: #093f45;
  --e-global-color-accent: #d14e45;
  --e-global-color-710cb3cb: #12575f;
  --e-global-color-1ab4e635: #edf7f8;
  --e-global-color-693e8419: #d7625a;
  --e-global-color-3515e9d8: #ddf0f2;
  --e-global-color-431f50da: #ebcd12;
  --e-global-color-73111589: #fff;
  --e-global-color-292496a1: #1ab2c5;
  --e-global-color-2a62708b: #c1dadd;
  --e-global-color-3ceddc8f: #f9a11e;
  --e-global-color-7de6810: #854592;
  --e-global-color-38e98fb2: #000000;
  --e-global-color-3eddc05a: #5cb147;
  --e-global-color-1659519: #fff5ed;
  --e-global-typography-primary-font-family: 'Nunito';
  --e-global-typography-primary-font-weight: 900;
  --e-global-typography-secondary-font-family: 'Nunito';
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: 'Montserrat';
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: 'Montserrat';
  --e-global-typography-accent-font-weight: 500;
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1095px;
}

.e-con {
  --container-max-width: 1095px;
}

.elementor-widget:not(:last-child) {
  margin-block-end: 20px;
}

.elementor-element {
  --widgets-spacing: 20px 20px;
}

h1.entry-title {
  display: var(--page-title-display);
}

.elementor-kit-15651 e-page-transition {
  background-color: #ffbc7d;
}

@media (max-width: 1024px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1024px;
  }

  .e-con {
    --container-max-width: 1024px;
  }
}

@media (max-width: 767px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 767px;
  }

  .e-con {
    --container-max-width: 767px;
  }
}
