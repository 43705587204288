/*! elementor-pro - v3.18.0 - 20-12-2023 */
@charset "UTF-8";

.customElementor {
  :global {
    .elementor-bg-transform .elementor-bg {
      will-change: transform;
    }
    .elementor-bg-transform-zoom-in:hover .elementor-bg {
      transform: scale(1.2);
    }
    .elementor-bg-transform-zoom-out .elementor-bg {
      transform: scale(1.2);
    }
    .elementor-bg-transform-zoom-out:hover .elementor-bg {
      transform: scale(1);
    }
    .elementor-bg-transform-move-left .elementor-bg {
      transform: scale(1.2) translateX(8%);
    }
    .elementor-bg-transform-move-left:hover .elementor-bg {
      transform: scale(1.2) translateX(-8%);
    }
    .elementor-bg-transform-move-right .elementor-bg {
      transform: scale(1.2) translateX(-8%);
    }
    .elementor-bg-transform-move-right:hover .elementor-bg {
      transform: scale(1.2) translateX(8%);
    }
    .elementor-bg-transform-move-up .elementor-bg {
      transform: scale(1.2) translateY(8%);
    }
    .elementor-bg-transform-move-up:hover .elementor-bg {
      transform: scale(1.2) translateY(-8%);
    }
    .elementor-bg-transform-move-down .elementor-bg {
      transform: scale(1.2) translateY(-8%);
    }
    .elementor-bg-transform-move-down:hover .elementor-bg {
      transform: scale(1.2) translateY(8%);
    }

    /*---------------------------------------------------------------------------*/
    .elementor-animated-content {
      --translate: 0, 0;
    }
    .elementor-animated-content:hover .elementor-animated-item--grow,
    .elementor-animated-content:focus .elementor-animated-item--grow {
      transform: scale(1.1);
    }
    .elementor-animated-content:hover .elementor-animated-item--shrink,
    .elementor-animated-content:focus .elementor-animated-item--shrink {
      transform: scale(0.85);
    }
    .elementor-animated-content:hover
      .elementor-animated-item--shrink-contained,
    .elementor-animated-content:focus
      .elementor-animated-item--shrink-contained {
      transform: scale(1);
    }
    .elementor-animated-content:hover .elementor-animated-item--enter-zoom-out,
    .elementor-animated-content:hover .elementor-animated-item--enter-zoom-in,
    .elementor-animated-content:hover .elementor-animated-item--fade-in,
    .elementor-animated-content:focus .elementor-animated-item--enter-zoom-out,
    .elementor-animated-content:focus .elementor-animated-item--enter-zoom-in,
    .elementor-animated-content:focus .elementor-animated-item--fade-in {
      transform: scale(1);
      opacity: 1;
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-zoom-out,
    .elementor-animated-content:hover .elementor-animated-item--exit-zoom-in,
    .elementor-animated-content:hover .elementor-animated-item--fade-out,
    .elementor-animated-content:focus .elementor-animated-item--exit-zoom-out,
    .elementor-animated-content:focus .elementor-animated-item--exit-zoom-in,
    .elementor-animated-content:focus .elementor-animated-item--fade-out {
      opacity: 0;
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-zoom-out,
    .elementor-animated-content:focus .elementor-animated-item--exit-zoom-out {
      transform: scale(0.2);
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-zoom-in,
    .elementor-animated-content:focus .elementor-animated-item--exit-zoom-in {
      transform: scale(2);
    }
    .elementor-animated-content:hover
      .elementor-animated-item--enter-from-right,
    .elementor-animated-content:hover .elementor-animated-item--enter-from-left,
    .elementor-animated-content:hover .elementor-animated-item--enter-from-top,
    .elementor-animated-content:hover
      .elementor-animated-item--enter-from-bottom,
    .elementor-animated-content:focus
      .elementor-animated-item--enter-from-right,
    .elementor-animated-content:focus .elementor-animated-item--enter-from-left,
    .elementor-animated-content:focus .elementor-animated-item--enter-from-top,
    .elementor-animated-content:focus
      .elementor-animated-item--enter-from-bottom {
      opacity: 1;
      transform: translateY(0) translateX(0);
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-to-right,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-right {
      transform: translateX(1000px);
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-to-left,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-left {
      transform: translateX(-1000px);
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-to-top,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-top {
      transform: translateY(-600px);
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-to-bottom,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-bottom {
      transform: translateY(600px);
    }
    .elementor-animated-content:hover .elementor-animated-item--exit-to-right,
    .elementor-animated-content:hover .elementor-animated-item--exit-to-left,
    .elementor-animated-content:hover .elementor-animated-item--exit-to-top,
    .elementor-animated-content:hover .elementor-animated-item--exit-to-bottom,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-right,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-left,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-top,
    .elementor-animated-content:focus .elementor-animated-item--exit-to-bottom {
      opacity: 0;
    }
    .elementor-animated-content:hover .elementor-animated-item--move-right,
    .elementor-animated-content:focus .elementor-animated-item--move-right {
      transform: translateX(30px);
    }
    .elementor-animated-content:hover .elementor-animated-item--move-left,
    .elementor-animated-content:focus .elementor-animated-item--move-left {
      transform: translateX(-30px);
    }
    .elementor-animated-content:hover .elementor-animated-item--move-up,
    .elementor-animated-content:focus .elementor-animated-item--move-up {
      transform: translateY(-30px);
    }
    .elementor-animated-content:hover .elementor-animated-item--move-down,
    .elementor-animated-content:focus .elementor-animated-item--move-down {
      transform: translateY(30px);
    }
    .elementor-animated-content:hover
      .elementor-animated-item--move-contained-right,
    .elementor-animated-content:focus
      .elementor-animated-item--move-contained-right {
      --translate: 8%, 0;
    }
    .elementor-animated-content:hover
      .elementor-animated-item--move-contained-left,
    .elementor-animated-content:focus
      .elementor-animated-item--move-contained-left {
      --translate: -8%, 0;
    }
    .elementor-animated-content:hover
      .elementor-animated-item--move-contained-top,
    .elementor-animated-content:focus
      .elementor-animated-item--move-contained-top {
      --translate: 0, -8%;
    }
    .elementor-animated-content:hover
      .elementor-animated-item--move-contained-bottom,
    .elementor-animated-content:focus
      .elementor-animated-item--move-contained-bottom {
      --translate: 0, 8%;
    }
    .elementor-animated-content *[class^='elementor-animated-item'] {
      will-change: transform, opacity;
    }
    .elementor-animated-content .elementor-animated-item--shrink-contained {
      transform: scale(1.17);
    }
    .elementor-animated-content .elementor-animated-item--enter-zoom-in {
      transform: scale(0.2);
    }
    .elementor-animated-content .elementor-animated-item--enter-zoom-out {
      transform: scale(2);
    }
    .elementor-animated-content .elementor-animated-item--enter-zoom-out,
    .elementor-animated-content .elementor-animated-item--enter-zoom-in,
    .elementor-animated-content .elementor-animated-item--fade-in {
      opacity: 0;
    }
    .elementor-animated-content .elementor-animated-item--exit-zoom-out,
    .elementor-animated-content .elementor-animated-item--exit-zoom-in,
    .elementor-animated-content .elementor-animated-item--fade-out {
      opacity: 1;
      transform: scale(1);
    }
    .elementor-animated-content .elementor-animated-item--enter-from-right {
      transform: translateX(1000px);
    }
    .elementor-animated-content .elementor-animated-item--enter-from-left {
      transform: translateX(-1000px);
    }
    .elementor-animated-content .elementor-animated-item--enter-from-top {
      transform: translateY(-600px);
    }
    .elementor-animated-content .elementor-animated-item--enter-from-bottom {
      transform: translateY(500px);
    }
    .elementor-animated-content .elementor-animated-item--enter-from-right,
    .elementor-animated-content .elementor-animated-item--enter-from-left,
    .elementor-animated-content .elementor-animated-item--enter-from-top,
    .elementor-animated-content .elementor-animated-item--enter-from-bottom {
      opacity: 0;
    }
    .elementor-animated-content .elementor-animated-item--exit-to-right,
    .elementor-animated-content .elementor-animated-item--exit-to-left,
    .elementor-animated-content .elementor-animated-item--exit-to-top,
    .elementor-animated-content .elementor-animated-item--exit-to-bottom {
      opacity: 1;
      transform: translateY(0) translateX(0);
    }
    .elementor-animated-content .elementor-animated-item--move-contained-right,
    .elementor-animated-content .elementor-animated-item--move-contained-left,
    .elementor-animated-content .elementor-animated-item--move-contained-top,
    .elementor-animated-content
      .elementor-animated-item--move-contained-bottom {
      transform: scale(1.2) translate(var(--translate));
    }

    .elementor-editor-active
      .elementor.elementor-edit-mode
      .elementor-widget.elementor-global-widget:hover {
      outline: 1px solid var(--e-p-border-global);
    }
    .elementor-editor-active
      .elementor.elementor-edit-mode
      .elementor-global-widget
      .elementor-editor-widget-settings {
      background-color: var(--e-p-border-global);
    }
    .elementor-editor-active
      .elementor.elementor-edit-mode
      .elementor-global-widget
      .elementor-editor-widget-settings
      .elementor-editor-element-setting {
      background-color: var(--e-p-border-global);
      color: var(--e-p-border-global-invert);
    }
    .elementor-editor-active
      .elementor.elementor-edit-mode
      .elementor-global-widget
      .elementor-editor-widget-settings
      .elementor-editor-element-setting:hover {
      background-color: var(--e-p-border-global-hover);
    }
    .elementor-editor-active
      .elementor.elementor-edit-mode
      .elementor-global-widget
      .elementor-editor-widget-settings
      .elementor-editor-element-setting.elementor-editor-element-save {
      display: none;
    }

    .elementor-theme-builder-content-area {
      height: 400px;
    }

    .elementor-location-header:before,
    .elementor-location-footer:before {
      content: '';
      display: table;
      clear: both;
    }

    .elementor-posts.elementor-posts--skin-archive_full_content
      article.elementor-post {
      display: block;
    }

    .elementor-sticky--active {
      z-index: 99;
    }

    .e-con.elementor-sticky--active {
      z-index: var(--z-index, 99);
    }

    [data-elementor-type='popup']:not(.elementor-edit-area) {
      display: none;
    }
    [data-elementor-type='popup']
      .elementor-section-wrap:not(:empty)
      + #elementor-add-new-section {
      display: none;
    }

    .elementor-popup-modal {
      display: flex;
      pointer-events: none;
      background-color: transparent;
      -webkit-user-select: auto;
      -moz-user-select: auto;
      user-select: auto;
    }
    .elementor-popup-modal .dialog-header,
    .elementor-popup-modal .dialog-buttons-wrapper {
      display: none;
    }
    .elementor-popup-modal .dialog-close-button {
      display: none;
      top: 20px;
      margin-top: 0;
      right: 20px;
      opacity: 1;
      z-index: 9999;
      pointer-events: all;
    }
    .elementor-popup-modal .dialog-close-button svg {
      fill: #1f2124;
      height: 1em;
      width: 1em;
    }
    .elementor-popup-modal .dialog-widget-content {
      background-color: #ffffff;
      width: initial;
      overflow: visible;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      box-shadow: none;
      pointer-events: all;
    }
    .elementor-popup-modal .dialog-message {
      width: 640px;
      max-width: 100vw;
      max-height: 100vh;
      padding: 0;
      overflow: auto;
      display: flex;
    }
    .elementor-popup-modal .elementor {
      width: 100%;
    }

    .elementor-motion-effects-element,
    .elementor-motion-effects-layer {
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0, 0.33, 0.07, 1.03);
      transition-duration: 1s;
    }

    .elementor-motion-effects-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x);
    }
    .elementor-motion-effects-layer {
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .elementor-motion-effects-perspective {
      perspective: 1200px;
    }
    .elementor-motion-effects-element {
      transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x);
    }

    body.woocommerce
      #content
      div.product
      .elementor-widget-woocommerce-product-images
      div.images,
    body.woocommerce
      div.product
      .elementor-widget-woocommerce-product-images
      div.images,
    body.woocommerce-page
      #content
      div.product
      .elementor-widget-woocommerce-product-images
      div.images,
    body.woocommerce-page
      div.product
      .elementor-widget-woocommerce-product-images
      div.images {
      float: none;
      width: 100%;
      padding: 0;
    }
    body.rtl.woocommerce
      #content
      div.product
      .elementor-widget-woocommerce-product-images
      div.images,
    body.rtl.woocommerce
      div.product
      .elementor-widget-woocommerce-product-images
      div.images,
    body.rtl.woocommerce-page
      #content
      div.product
      .elementor-widget-woocommerce-product-images
      div.images,
    body.rtl.woocommerce-page
      div.product
      .elementor-widget-woocommerce-product-images
      div.images {
      float: none;
      padding: 0;
    }

    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      form.cart {
      margin: 0;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      form.cart:not(.grouped_form):not(.variations_form),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      form.cart.variations_form
      .woocommerce-variation-add-to-cart {
      display: flex;
      flex-wrap: nowrap;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      form.cart
      button:where(:not(:first-child)),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      form.cart
      .button:where(:not(:first-child)) {
      margin-top: 0;
      margin-left: var(--button-spacing, 10px);
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      .e-loop-add-to-cart-form-container {
      display: flex;
      flex-wrap: wrap;
      gap: var(--view-cart-spacing, 10px);
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      .e-loop-add-to-cart-form-container
      > * {
      display: flex;
      flex-basis: auto;
      margin: 0;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      .quantity {
      vertical-align: middle;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      .quantity
      .qty {
      vertical-align: top;
      margin-right: 0;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      .quantity
      input {
      height: 100%;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      form.cart:not(.grouped_form):not(.variations_form),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      form.cart.variations_form
      .woocommerce-variation-add-to-cart,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-auto
      form.cart:not(.grouped_form):not(.variations_form),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-auto
      form.cart.variations_form
      .woocommerce-variation-add-to-cart {
      display: block;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-atc-qty-button-holder,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-auto
      .e-atc-qty-button-holder {
      display: flex;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-loop-add-to-cart-form-container {
      flex-wrap: wrap;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-loop-add-to-cart-form-container
      > * {
      flex-basis: 100%;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-atc-qty-button-holder {
      flex-wrap: wrap;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-atc-qty-button-holder
      > * {
      flex-basis: 100%;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-atc-qty-button-holder
      button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-atc-qty-button-holder
      .button {
      flex-basis: auto;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-atc-qty-button-holder
      button:where(:not(:first-child)),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-stacked
      .e-atc-qty-button-holder
      .button:where(:not(:first-child)) {
      margin-left: 0;
      margin-top: var(--button-spacing, 10px);
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-auto
      .e-atc-qty-button-holder {
      flex-wrap: nowrap;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-auto
      .e-atc-qty-button-holder
      .quantity {
      margin-right: initial;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-auto
      .e-atc-qty-button-holder
      button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--layout-auto
      .e-atc-qty-button-holder
      .button {
      vertical-align: middle;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
      text-align: left;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
      form.cart:not(.grouped_form):not(.variations_form),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
      form.cart.variations_form
      .woocommerce-variation-add-to-cart {
      justify-content: flex-start;
      text-align: left;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left[class*='--layout-stacked']
      .e-atc-qty-button-holder,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left[class*='--layout-auto']
      .e-atc-qty-button-holder {
      justify-content: flex-start;
      text-align: left;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left
      .e-loop-add-to-cart-form-container {
      justify-content: flex-start;
      text-align: left;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
      text-align: right;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
      form.cart:not(.grouped_form):not(.variations_form),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
      form.cart.variations_form
      .woocommerce-variation-add-to-cart {
      justify-content: flex-end;
      text-align: right;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right[class*='--layout-stacked']
      .e-atc-qty-button-holder,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right[class*='--layout-auto']
      .e-atc-qty-button-holder {
      justify-content: flex-end;
      text-align: right;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right
      .e-loop-add-to-cart-form-container {
      justify-content: flex-end;
      text-align: right;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
      text-align: center;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
      form.cart:not(.grouped_form):not(.variations_form),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
      form.cart.variations_form
      .woocommerce-variation-add-to-cart {
      justify-content: center;
      text-align: center;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center[class*='--layout-stacked']
      .e-atc-qty-button-holder,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center[class*='--layout-auto']
      .e-atc-qty-button-holder {
      justify-content: center;
      text-align: center;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center
      .e-loop-add-to-cart-form-container {
      justify-content: center;
      text-align: center;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left
      form.cart
      div.quantity,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right
      form.cart
      div.quantity,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center
      form.cart
      div.quantity {
      margin-right: initial;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left
      form.cart
      button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-left
      form.cart
      .button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right
      form.cart
      button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-right
      form.cart
      .button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center
      form.cart
      button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-center
      form.cart
      .button {
      flex-basis: auto;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-justify:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart'])
      .elementor-button {
      width: 100%;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-justify
      .e-loop-add-to-cart-form-container
      > * {
      flex-basis: 100%;
      justify-content: center;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-justify
      .e-loop-add-to-cart-form-container
      a.added_to_cart {
      flex-basis: auto;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-justify
      form.cart
      div.quantity {
      margin-right: auto;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-justify
      form.cart
      button,
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-add-to-cart--align-justify
      form.cart
      .button {
      flex-basis: 100%;
    }
    @media (min-width: -1) {
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left
        .e-loop-add-to-cart-form-container {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right
        .e-loop-add-to-cart-form-container {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center
        .e-loop-add-to-cart-form-container {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center
        form.cart
        div.quantity {
        margin-right: initial;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-left
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-right
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-center
        form.cart
        .button {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-justify:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart'])
        .elementor-button {
        width: 100%;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-justify
        .e-loop-add-to-cart-form-container
        > * {
        flex-basis: 100%;
        justify-content: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-justify
        .e-loop-add-to-cart-form-container
        a.added_to_cart {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-justify
        form.cart
        div.quantity {
        margin-right: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-justify
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-widescreen--align-justify
        form.cart
        .button {
        flex-basis: 100%;
      }
    }
    @media (max-width: -1) {
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left
        .e-loop-add-to-cart-form-container {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right
        .e-loop-add-to-cart-form-container {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center
        .e-loop-add-to-cart-form-container {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center
        form.cart
        div.quantity {
        margin-right: initial;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-left
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-right
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-center
        form.cart
        .button {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-justify:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart'])
        .elementor-button {
        width: 100%;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-justify
        .e-loop-add-to-cart-form-container
        > * {
        flex-basis: 100%;
        justify-content: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-justify
        .e-loop-add-to-cart-form-container
        a.added_to_cart {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-justify
        form.cart
        div.quantity {
        margin-right: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-justify
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-laptop--align-justify
        form.cart
        .button {
        flex-basis: 100%;
      }
    }
    @media (max-width: -1) {
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left
        .e-loop-add-to-cart-form-container {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right
        .e-loop-add-to-cart-form-container {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center
        .e-loop-add-to-cart-form-container {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center
        form.cart
        div.quantity {
        margin-right: initial;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-left
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-right
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-center
        form.cart
        .button {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-justify:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart'])
        .elementor-button {
        width: 100%;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-justify
        .e-loop-add-to-cart-form-container
        > * {
        flex-basis: 100%;
        justify-content: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-justify
        .e-loop-add-to-cart-form-container
        a.added_to_cart {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-justify
        form.cart
        div.quantity {
        margin-right: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-justify
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet_extra--align-justify
        form.cart
        .button {
        flex-basis: 100%;
      }
    }
    @media (max-width: 1024px) {
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left
        .e-loop-add-to-cart-form-container {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right
        .e-loop-add-to-cart-form-container {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center
        .e-loop-add-to-cart-form-container {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center
        form.cart
        div.quantity {
        margin-right: initial;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-left
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-right
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-center
        form.cart
        .button {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-justify:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart'])
        .elementor-button {
        width: 100%;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-justify
        .e-loop-add-to-cart-form-container
        > * {
        flex-basis: 100%;
        justify-content: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-justify
        .e-loop-add-to-cart-form-container
        a.added_to_cart {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-justify
        form.cart
        div.quantity {
        margin-right: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-justify
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-tablet--align-justify
        form.cart
        .button {
        flex-basis: 100%;
      }
    }
    @media (max-width: -1) {
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left
        .e-loop-add-to-cart-form-container {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right
        .e-loop-add-to-cart-form-container {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center
        .e-loop-add-to-cart-form-container {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center
        form.cart
        div.quantity {
        margin-right: initial;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-left
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-right
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-center
        form.cart
        .button {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-justify:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart'])
        .elementor-button {
        width: 100%;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-justify
        .e-loop-add-to-cart-form-container
        > * {
        flex-basis: 100%;
        justify-content: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-justify
        .e-loop-add-to-cart-form-container
        a.added_to_cart {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-justify
        form.cart
        div.quantity {
        margin-right: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-justify
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile_extra--align-justify
        form.cart
        .button {
        flex-basis: 100%;
      }
    }
    @media (max-width: 767px) {
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left
        .e-loop-add-to-cart-form-container {
        justify-content: flex-start;
        text-align: left;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right
        .e-loop-add-to-cart-form-container {
        justify-content: flex-end;
        text-align: right;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart']) {
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart:not(.grouped_form):not(.variations_form),
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center:not([class*='--layout-stacked']):not([class*='--layout-auto'])
        form.cart.variations_form
        .woocommerce-variation-add-to-cart {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center[class*='--layout-stacked']
        .e-atc-qty-button-holder,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center[class*='--layout-auto']
        .e-atc-qty-button-holder {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center
        .e-loop-add-to-cart-form-container {
        justify-content: center;
        text-align: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right
        form.cart
        div.quantity,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center
        form.cart
        div.quantity {
        margin-right: initial;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-left
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-right
        form.cart
        .button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-center
        form.cart
        .button {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-justify:not([class*='--layout-stacked']):not([class*='--layout-auto']):not([class*='-product-add-to-cart'])
        .elementor-button {
        width: 100%;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-justify
        .e-loop-add-to-cart-form-container
        > * {
        flex-basis: 100%;
        justify-content: center;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-justify
        .e-loop-add-to-cart-form-container
        a.added_to_cart {
        flex-basis: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-justify
        form.cart
        div.quantity {
        margin-right: auto;
      }
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-justify
        form.cart
        button,
      :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
          div.product
          .elementor-widget-woocommerce-product-add-to-cart, .elementor-widget-wc-add-to-cart, .woocommerce
          div.product
          .elementor-widget-wc-add-to-cart).elementor-add-to-cart-mobile--align-justify
        form.cart
        .button {
        flex-basis: 100%;
      }
    }

    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart):not(.e-add-to-cart--show-quantity-yes)
      form.cart
      .quantity {
      display: none !important;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart):not(.e-add-to-cart--show-quantity-yes)
      form.cart
      button:where(:not(:first-child)),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart):not(.e-add-to-cart--show-quantity-yes)
      form.cart
      .button:where(:not(:first-child)) {
      margin-left: 0;
    }
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart):not(.e-add-to-cart--show-quantity-yes)[class*='--layout-stacked']
      form.cart
      button:where(:not(:first-child)),
    :is(.elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .elementor-widget-woocommerce-product-add-to-cart):not(.e-add-to-cart--show-quantity-yes)[class*='--layout-stacked']
      form.cart
      .button:where(:not(:first-child)) {
      margin-top: 0;
    }

    :is(.e-loop-item
        .elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .e-loop-item
        .elementor-widget-woocommerce-product-add-to-cart)
      form.cart
      input.qty.disabled {
      pointer-events: none;
    }
    :is(.e-loop-item
        .elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .e-loop-item
        .elementor-widget-woocommerce-product-add-to-cart)
      form.cart
      .button {
      text-align: center;
    }
    :is(.e-loop-item
        .elementor-widget-woocommerce-product-add-to-cart, .woocommerce
        div.product
        .e-loop-item
        .elementor-widget-woocommerce-product-add-to-cart)
      .added_to_cart {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }

    :is(.elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      .quantity {
      vertical-align: middle;
    }
    :is(.elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart)
      .quantity
      .qty {
      vertical-align: top;
      margin-right: 0;
      width: 3.631em;
      text-align: center;
    }
    :is(.elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-button-info
      button.button.alt.elementor-button {
      background-color: #5bc0de;
    }
    :is(.elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-button-success
      button.button.alt.elementor-button {
      background-color: #5cb85c;
    }
    :is(.elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-button-warning
      button.button.alt.elementor-button {
      background-color: #f0ad4e;
    }
    :is(.elementor-widget-wc-add-to-cart, .woocommerce
        div.product
        .elementor-widget-wc-add-to-cart).elementor-button-danger
      button.button.alt.elementor-button {
      background-color: #d9534f;
    }

    .woocommerce div.product.elementor {
      /*  Reset WC tabs style  */
      /*  End Reset WC tabs style  */
    }
    .woocommerce div.product.elementor ul.tabs:before {
      position: static;
      content: none;
      width: auto;
      bottom: auto;
      left: auto;
      border-bottom: 0;
      z-index: auto;
    }
    .woocommerce div.product.elementor ul.tabs {
      margin: 0;
    }
    .woocommerce div.product.elementor ul.tabs li {
      padding: 0;
    }
    .woocommerce div.product.elementor ul.tabs li a {
      padding: 0.8em 1.2em;
      line-height: 1;
    }
    .woocommerce div.product.elementor ul.tabs li:after,
    .woocommerce div.product.elementor ul.tabs li:before {
      border: 0;
      position: static;
      bottom: auto;
      width: auto;
      height: auto;
      content: none;
      box-sizing: border-box;
    }
    .woocommerce div.product.elementor .woocommerce-tabs .panel {
      margin: 0;
      padding: 20px;
      border-radius: 0;
      border-width: 0;
      border-top: 1px solid #d3ced2;
      box-shadow: none;
      margin-top: -1px;
    }

    .woocommerce
      .elementor-product-price-block-yes.elementor-widget-woocommerce-product-price
      .price
      del,
    .woocommerce
      .elementor-product-price-block-yes.elementor-widget-woocommerce-product-price
      .price
      ins {
      display: block;
    }

    .elementor-widget-woocommerce-product-meta .detail-container {
      position: relative;
    }
    .elementor-widget-woocommerce-product-meta .detail-container:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .elementor-widget-woocommerce-product-meta .detail-label {
      font-weight: bold;
    }

    .elementor-woo-meta--view-inline .product_meta {
      display: flex;
      flex-wrap: wrap;
    }
    .elementor-woo-meta--view-inline .product_meta .detail-container:after {
      width: auto;
      left: auto;
      right: auto;
      position: absolute;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-top: 0;
      border-bottom: 0;
      border-right: 0;
      border-left-width: 1px;
      border-style: solid;
      right: -8px;
    }
    .elementor-woo-meta--view-table .product_meta {
      display: flex;
      flex-direction: column;
    }
    .elementor-woo-meta--view-table .product_meta .detail-container {
      display: flex;
    }
    .elementor-woo-meta--view-table .product_meta .detail-label {
      min-width: 108px;
    }
    .elementor-woo-meta--view-stacked .product_meta .detail-container {
      display: block;
    }

    .woocommerce
      .elementor-widget-woocommerce-product-rating
      .elementor-widget-container
      .woocommerce-product-rating {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .woocommerce
      .elementor-widget-woocommerce-product-rating
      .elementor-widget-container
      .star-rating {
      margin-top: 0;
    }

    .elementor-product-rating--align-left .woocommerce-product-rating {
      justify-content: flex-start;
    }
    .elementor-product-rating--align-right .woocommerce-product-rating {
      justify-content: flex-end;
    }
    .elementor-product-rating--align-center .woocommerce-product-rating {
      justify-content: center;
    }
    .elementor-product-rating--align-justify
      .woocommerce-product-rating
      .woocommerce-review-link {
      margin-left: auto;
    }

    .elementor-products-grid ul.products.elementor-grid {
      display: grid;
      margin: 0;
      grid-column-gap: 20px;
      grid-row-gap: 40px;
    }
    .elementor-products-grid ul.products.elementor-grid:before,
    .elementor-products-grid ul.products.elementor-grid:after {
      content: none;
      display: none;
    }
    .elementor-products-grid ul.products.elementor-grid li.product {
      width: auto;
      padding: 0;
      margin: 0;
      float: none;
      display: var(--button-align-display);
      flex-direction: var(--button-align-direction);
      justify-content: var(--button-align-justify);
    }
    .elementor-products-grid ul.products.elementor-grid li.product .onsale {
      padding: 0;
      display: none;
    }
    .elementor-products-grid
      ul.products.elementor-grid
      li.product
      a.woocommerce-loop-product__link {
      display: block;
      position: relative;
    }

    .elementor-products-grid:not(.show-heading-yes) .products > h2 {
      display: none;
    }
    .elementor-products-grid nav.woocommerce-pagination {
      margin-top: 40px;
    }
    .elementor-products-grid:not(.elementor-show-pagination-border-yes)
      nav.woocommerce-pagination
      ul {
      border: none 0;
    }
    .elementor-products-grid:not(.elementor-show-pagination-border-yes)
      nav.woocommerce-pagination
      ul
      li {
      border-right: none 0;
      border-left: none 0;
    }

    .elementor-widget-woocommerce-products:not(.products-heading-show)
      .related
      > h2,
    .elementor-widget-woocommerce-products:not(.products-heading-show)
      .upsells
      > h2,
    .elementor-widget-woocommerce-products:not(.products-heading-show)
      .cross-sells
      > h2 {
      display: none;
    }
    .elementor-widget-woocommerce-products.products-heading-show .related > h2,
    .elementor-widget-woocommerce-products.products-heading-show .upsells > h2,
    .elementor-widget-woocommerce-products.products-heading-show
      .cross-sells
      > h2 {
      display: block;
      text-align: var(--products-title-alignment, left);
      color: var(--products-title-color);
      margin-bottom: var(--products-title-spacing, 1rem);
    }

    .elementor-product-loop-item--align-left
      ul.products
      li.product
      .star-rating {
      margin-right: auto;
    }
    .elementor-product-loop-item--align-right
      ul.products
      li.product
      .star-rating {
      margin-left: auto;
    }
    .elementor-product-loop-item--align-center
      ul.products
      li.product
      .star-rating {
      margin-right: auto;
      margin-left: auto;
    }

    .woocommerce
      .elementor-element.elementor-products-grid
      ul.products
      li.product {
      width: auto;
    }
    .woocommerce
      div.product
      .elementor-element.elementor-products-grid
      .related.products
      ul.products
      li.product,
    .woocommerce
      div.product
      .elementor-element.elementor-products-grid
      .upsells.products
      ul.products
      li.product {
      width: auto;
    }

    @media (min-width: 1025px) {
      .elementor-widget-wc-archive-products .woocommerce.columns-2 ul.products {
        grid-template-columns: repeat(2, 1fr);
      }
      .elementor-widget-wc-archive-products .woocommerce.columns-3 ul.products {
        grid-template-columns: repeat(3, 1fr);
      }
      .elementor-widget-wc-archive-products .woocommerce.columns-4 ul.products {
        grid-template-columns: repeat(4, 1fr);
      }
      .elementor-widget-wc-archive-products .woocommerce.columns-5 ul.products {
        grid-template-columns: repeat(5, 1fr);
      }
      .elementor-widget-wc-archive-products .woocommerce.columns-6 ul.products {
        grid-template-columns: repeat(6, 1fr);
      }
      .elementor-widget-wc-archive-products .woocommerce.columns-7 ul.products {
        grid-template-columns: repeat(7, 1fr);
      }
      .elementor-widget-wc-archive-products .woocommerce.columns-8 ul.products {
        grid-template-columns: repeat(8, 1fr);
      }
      .elementor-widget-wc-archive-products .woocommerce.columns-9 ul.products {
        grid-template-columns: repeat(9, 1fr);
      }
      .elementor-widget-wc-archive-products
        .woocommerce.columns-10
        ul.products {
        grid-template-columns: repeat(10, 1fr);
      }
      .elementor-widget-wc-archive-products
        .woocommerce.columns-11
        ul.products {
        grid-template-columns: repeat(11, 1fr);
      }
      .elementor-widget-wc-archive-products
        .woocommerce.columns-12
        ul.products {
        grid-template-columns: repeat(12, 1fr);
      }
    }
    @media (max-width: 1024px) {
      .elementor-widget-wc-archive-products .products {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media (max-width: 767px) {
      .elementor-widget-wc-archive-products .products {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .elementor.product
      .woocommerce-product-gallery__trigger
      + .woocommerce-product-gallery__wrapper {
      overflow: hidden;
    }

    .woocommerce .elementor-widget-woocommerce-product-images span.onsale {
      padding: 0;
    }

    .elementor-menu-cart__wrapper {
      text-align: var(--main-alignment, left);
    }
    .elementor-menu-cart__toggle_wrapper {
      display: inline-block;
      position: relative;
    }
    .elementor-menu-cart__toggle {
      display: inline-block;
    }
    .elementor-menu-cart__toggle .elementor-button {
      background-color: var(--toggle-button-background-color, transparent);
      color: var(--toggle-button-text-color, #69727d);
      border-style: var(--toggle-button-border-type, solid);
      border-width: var(--toggle-button-border-width, 1px);
      border-color: var(--toggle-button-border-color, #69727d);
      border-radius: var(--toggle-button-border-radius, 0px);
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: var(--toggle-icon-padding, 12px 24px);
    }
    .elementor-menu-cart__toggle .elementor-button:hover {
      color: var(--toggle-button-hover-text-color, #69727d);
      background-color: var(
        --toggle-button-hover-background-color,
        transparent
      );
      border-color: var(--toggle-button-hover-border-color, #69727d);
    }
    .elementor-menu-cart__toggle
      .elementor-button:hover
      .elementor-button-icon {
      color: var(--toggle-button-icon-hover-color, #69727d);
    }
    .elementor-menu-cart__toggle .elementor-button svg {
      fill: var(--toggle-button-icon-color, #69727d);
    }
    .elementor-menu-cart__toggle .elementor-button svg:hover {
      fill: var(--toggle-button-icon-hover-color, #69727d);
    }
    .elementor-menu-cart__toggle .elementor-button-text {
      margin-inline-end: 0.3em;
    }
    .elementor-menu-cart__toggle .elementor-button-icon {
      position: relative;
      transition: color 0.1s;
      color: var(--toggle-button-icon-color, #69727d);
      font-size: var(--toggle-icon-size, inherit);
    }
    .elementor-menu-cart__toggle .e-toggle-cart-custom-icon {
      color: var(--toggle-button-icon-color, #69727d);
      font-size: var(--toggle-icon-size, inherit);
    }
    .elementor-menu-cart__toggle .e-toggle-cart-custom-icon:hover {
      color: var(--toggle-button-icon-hover-color, #69727d);
    }
    .elementor-menu-cart--items-indicator-bubble
      .elementor-menu-cart__toggle
      .elementor-button-icon
      .elementor-button-icon-qty[data-counter] {
      display: block;
      position: absolute;
      min-width: 1.6em;
      height: 1.6em;
      line-height: 1.5em;
      top: -0.7em;
      inset-inline-end: -0.7em;
      border-radius: 100%;
      color: var(--items-indicator-text-color, #fff);
      background-color: var(--items-indicator-background-color, #d9534f);
      text-align: center;
      font-size: 10px;
    }
    .elementor-menu-cart--items-indicator-plain
      .elementor-menu-cart__toggle
      .elementor-button-icon
      .elementor-button-icon-qty[data-counter] {
      display: inline-block;
      font-weight: normal;
    }
    .elementor-menu-cart--items-indicator-none
      .elementor-menu-cart__toggle
      .elementor-button-icon
      .elementor-button-icon-qty[data-counter] {
      display: none;
    }
    .elementor-menu-cart__container {
      transform: scale(1);
      overflow: hidden;
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      transition: background-color 0.4s, transform 0s;
      text-align: left;
    }
    .elementor-menu-cart__main {
      position: fixed;
      left: var(--side-cart-alignment-left, auto);
      right: var(--side-cart-alignment-right, 0);
      transform: translateX(0);
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      font-size: 14px;
      min-height: 200px;
      width: 350px;
      max-width: 100%;
      transition: 0.3s;
      padding: var(--cart-padding, 20px 30px);
      background-color: var(--cart-background-color, #fff);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      border-style: var(--cart-border-style, none);
      border-color: var(--cart-border-color, initial);
      border-radius: var(--cart-border-radius, 0px);
      margin-top: var(--mini-cart-spacing, 0px);
    }
    .elementor-menu-cart__main .widget_shopping_cart_content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .elementor-menu-cart__main
      .widget_shopping_cart_content
      .woocommerce-mini-cart__empty-message {
      color: var(--empty-message-color, inherit);
      text-align: var(--empty-message-alignment, left);
    }
    body.elementor-default
      .elementor-widget-woocommerce-menu-cart:not(.elementor-menu-cart--shown)
      .elementor-menu-cart__container {
      background-color: transparent;
      transform: scale(0);
      transition: background-color 0.4s, transform 0s 0.4s;
    }
    body.elementor-default
      .elementor-widget-woocommerce-menu-cart:not(.elementor-menu-cart--shown)
      .elementor-menu-cart__container
      .dialog-lightbox-close-button {
      display: none;
    }
    body.elementor-default
      .elementor-widget-woocommerce-menu-cart:not(.elementor-menu-cart--shown)
      .elementor-menu-cart__main {
      overflow: hidden;
      opacity: 0;
      transform: var(--side-cart-alignment-transform, translateX(100%));
    }
    .elementor-menu-cart__close-button {
      width: var(--cart-close-icon-size, 25px);
      height: var(--cart-close-icon-size, 25px);
      position: relative;
      margin: 0 0 20px;
      align-self: flex-end;
      cursor: pointer;
      display: inline-block;
      font-family: eicons;
      font-size: 20px;
      line-height: 1;
      transition: 0.3s;
    }
    .elementor-menu-cart__close-button:before,
    .elementor-menu-cart__close-button:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: var(--cart-close-button-color, #69727d);
      border-radius: 1px;
      transition: 0.3s;
    }
    .elementor-menu-cart__close-button:hover::before,
    .elementor-menu-cart__close-button:hover::after {
      background: var(--cart-close-button-hover-color, #69727d);
    }
    .elementor-menu-cart__close-button::before {
      transform: rotate(45deg);
    }
    .elementor-menu-cart__close-button::after {
      transform: rotate(-45deg);
    }
    .elementor-menu-cart__close-button-custom {
      position: relative;
      margin: 0 0 20px;
      align-self: flex-end;
      cursor: pointer;
      display: inline-block;
      font-family: eicons;
      font-size: 20px;
      line-height: 1;
      transition: 0.3s;
    }
    .elementor-menu-cart__close-button-custom:hover::before,
    .elementor-menu-cart__close-button-custom:hover::after {
      background: var(--cart-close-button-hover-color, #69727d);
    }
    .elementor-menu-cart__close-button-custom .e-close-cart-custom-icon {
      font-size: var(--cart-close-icon-size, 25px);
      color: var(--cart-close-button-color, #69727d);
    }
    .elementor-menu-cart__close-button-custom .e-close-cart-custom-icon:hover {
      color: var(--cart-close-button-hover-color, #69727d);
    }
    .elementor-menu-cart__close-button-custom svg {
      fill: var(--cart-close-button-color, #69727d);
      width: var(--cart-close-icon-size, 25px);
      height: var(--cart-close-icon-size, 25px);
    }
    .elementor-menu-cart__close-button-custom svg:hover {
      fill: var(--cart-close-button-hover-color, #69727d);
    }
    .elementor-menu-cart__products {
      max-height: calc(100vh - 250px);
      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    .elementor-menu-cart__product {
      display: grid;
      grid-template-columns: 28% auto;
      grid-template-rows: var(
        --price-quantity-position--grid-template-rows,
        auto auto
      );
      position: relative;
      border-width: 0 0 var(--divider-width, 1px);
      border-bottom-style: var(--divider-style, solid);
      border-bottom-color: var(--divider-color, #d5d8dc);
    }
    .elementor-menu-cart__product .variation {
      display: grid;
      grid-template-columns: max-content auto;
      margin: 10px 0;
      color: var(--product-variations-color, #1f2124);
    }
    .elementor-menu-cart__product .variation dt {
      grid-column-start: 1;
    }
    .elementor-menu-cart__product .variation dd {
      grid-column-start: 2;
      margin-inline-start: 5px;
    }
    .elementor-menu-cart__product .variation dd p {
      margin-bottom: 0;
    }
    .elementor-menu-cart__product-image {
      grid-row-start: 1;
      grid-row-end: 3;
      width: 100%;
    }
    .elementor-menu-cart__product-image img,
    .elementor-menu-cart__product-image a {
      display: block;
    }
    .elementor-menu-cart__product-name {
      grid-column-start: 2;
      grid-column-end: 3;
      margin: 0;
    }
    .elementor-menu-cart__product-name a {
      transition: 0.3s;
    }
    .elementor-menu-cart__product-price {
      grid-column-start: 2;
      grid-column-end: 3;
      align-self: var(--price-quantity-position--align-self, end);
      font-weight: 300;
      color: var(--product-price-color, #d5d8dc);
    }
    .elementor-menu-cart__product-name,
    .elementor-menu-cart__product-price {
      font-size: 14px;
      padding-left: 20px;
    }
    .elementor-menu-cart__product-remove {
      color: #69727d;
      width: var(--remove-item-button-size, 22px);
      height: var(--remove-item-button-size, 22px);
      border-radius: var(--remove-item-button-size, 22px);
      border: 1px solid var(--remove-item-button-color, #d5d8dc);
      text-align: center;
      overflow: hidden;
      position: absolute;
      right: 0;
      bottom: 20px;
      transition: 0.3s;
    }
    .elementor-menu-cart__product-remove:hover {
      border: 1px solid var(--remove-item-button-hover-color, #d5d8dc);
    }
    .elementor-menu-cart__product-remove:hover::before,
    .elementor-menu-cart__product-remove:hover::after {
      background: var(--remove-item-button-hover-color, #d5d8dc);
    }
    .elementor-menu-cart__product-remove:before,
    .elementor-menu-cart__product-remove:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 50%;
      top: 50%;
      left: 25%;
      margin-top: -1px;
      background: var(--remove-item-button-color, #d5d8dc);
      z-index: 1;
      transition: 0.3s;
    }
    .elementor-menu-cart__product-remove::before {
      transform: rotate(45deg);
    }
    .elementor-menu-cart__product-remove::after {
      transform: rotate(-45deg);
    }
    .elementor-menu-cart__product-remove > a {
      display: block;
      z-index: 2;
      width: 100%;
      height: 100%;
      overflow: hidden;
      opacity: 0;
      position: absolute;
    }
    .elementor-menu-cart__product-remove > a.remove_from_cart_button {
      display: var(--remove-from-cart-button, block);
    }
    .elementor-menu-cart__product-remove > a.elementor_remove_from_cart_button {
      display: var(--elementor-remove-from-cart-button, none);
    }
    .elementor-menu-cart__product:last-child {
      border: none;
    }
    .elementor-menu-cart__product:not(:first-of-type),
    .elementor-menu-cart__subtotal,
    .elementor-menu-cart__footer-buttons {
      padding-top: var(--product-divider-gap, 20px);
    }
    .elementor-menu-cart__product {
      padding-right: 30px;
    }
    .elementor-menu-cart__product,
    .elementor-menu-cart__subtotal {
      padding-bottom: var(--product-divider-gap, 20px);
    }
    .elementor-menu-cart__subtotal {
      font-size: 20px;
      text-align: var(--menu-cart-subtotal-text-align, center);
      font-weight: 600;
      color: var(--menu-cart-subtotal-color, inherit);
      border-style: var(--subtotal-divider-style, solid);
      border-width: var(--subtotal-divider-top-width, 1px)
        var(--subtotal-divider-right-width, 1px)
        var(--subtotal-divider-bottom-width, 1px)
        var(--subtotal-divider-left-width, 1px);
      border-color: var(--subtotal-divider-color, #d5d8dc);
    }
    .elementor-menu-cart__footer-buttons {
      font-size: 20px;
      text-align: var(--cart-footer-buttons-alignment-text-align, center);
      display: var(--cart-footer-buttons-alignment-display, grid);
      grid-template-columns: var(--cart-footer-layout, 1fr 1fr);
      margin-top: var(--cart-buttons-position-margin, 0);
      grid-column-gap: var(--space-between-buttons, 10px);
      grid-row-gap: var(--space-between-buttons, 10px);
    }
    .elementor-menu-cart__footer-buttons .elementor-button {
      display: inline-block;
      border-radius: var(--cart-footer-buttons-border-radius, 0px);
      height: -moz-fit-content;
      height: fit-content;
    }
    .elementor-menu-cart__footer-buttons .elementor-button--view-cart {
      display: var(--view-cart-button-display, inline-block);
      color: var(--view-cart-button-text-color, #fff);
      padding: var(--view-cart-button-padding, 15px);
      background-color: var(--view-cart-button-background-color, #69727d);
    }
    .elementor-menu-cart__footer-buttons .elementor-button--view-cart:hover {
      color: var(--view-cart-button-hover-text-color, #fff);
      background-color: var(--view-cart-button-hover-background-color, #69727d);
    }
    .elementor-menu-cart__footer-buttons .elementor-button--checkout {
      display: var(--checkout-button-display, inline-block);
      color: var(--checkout-button-text-color, #fff);
      padding: var(--checkout-button-padding, 15px);
      background-color: var(--checkout-button-background-color, #69727d);
    }
    .elementor-menu-cart__footer-buttons .elementor-button--checkout:hover {
      color: var(--checkout-button-hover-text-color, #fff);
      background-color: var(--checkout-button-hover-background-color, #69727d);
    }
    @media (max-width: 767px) {
      .elementor-menu-cart__footer-buttons .elementor-button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    /* The following is all to apply settings from the controls */
    .elementor-widget-woocommerce-menu-cart {
      /* Fix to prevent the cart modal flash when using the typography controls */
      /* Fix for short-lived legacy cart type */
    }
    .elementor-widget-woocommerce-menu-cart:not(.elementor-menu-cart--show-subtotal-yes)
      .elementor-menu-cart__toggle
      .elementor-button-text {
      display: none;
    }
    .elementor-widget-woocommerce-menu-cart.elementor-menu-cart--empty-indicator-hide
      .elementor-menu-cart__toggle
      .elementor-button-icon
      .elementor-button-icon-qty[data-counter='0'] {
      display: none;
    }
    .elementor-widget-woocommerce-menu-cart:not(.elementor-menu-cart--show-remove-button-yes)
      .elementor-menu-cart__product {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 25% auto;
    }
    .elementor-widget-woocommerce-menu-cart:not(.elementor-menu-cart--show-remove-button-yes)
      .elementor-menu-cart__product-remove {
      display: none;
    }
    .elementor-widget-woocommerce-menu-cart.remove-item-position--top
      .elementor-menu-cart__product-remove {
      top: 0;
      bottom: auto;
    }
    .elementor-widget-woocommerce-menu-cart.remove-item-position--top
      .elementor-menu-cart__products
      .cart_item:not(:first-of-type)
      .elementor-menu-cart__product-remove {
      top: 20px;
      bottom: auto;
    }
    .elementor-widget-woocommerce-menu-cart.remove-item-position--middle
      .elementor-menu-cart__product-remove {
      transform: translateY(50%);
      bottom: 50%;
    }
    .elementor-widget-woocommerce-menu-cart.remove-item-position--bottom
      .elementor-menu-cart__product-remove {
      top: auto;
      bottom: 20px;
    }
    .elementor-widget-woocommerce-menu-cart.elementor-menu-cart--cart-type-mini-cart
      .elementor-menu-cart__container {
      position: absolute;
      width: auto;
      height: auto;
      overflow: visible;
      top: 100%;
      bottom: auto;
      background: none;
      min-width: 330px;
      left: 0;
      right: auto;
      transform: scale(1);
      transition: background-color 0.4s, transform 0s;
    }
    .elementor-widget-woocommerce-menu-cart.elementor-menu-cart--cart-type-mini-cart
      .elementor-menu-cart__main {
      width: auto;
      height: auto;
      position: relative;
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      overflow: visible;
      transform: translateY(0);
      transition: 0.3s;
    }
    @media (max-width: 767px) {
      .elementor-widget-woocommerce-menu-cart.elementor-menu-cart--cart-type-mini-cart
        .elementor-menu-cart__container {
        min-width: 300px;
      }
    }
    body.elementor-default
      .elementor-widget-woocommerce-menu-cart.elementor-menu-cart--cart-type-mini-cart:not(.elementor-menu-cart--shown)
      .elementor-menu-cart__container {
      transform: scale(0);
      transition: background-color 0.4s, transform 0s 0.4s;
    }
    body.elementor-default
      .elementor-widget-woocommerce-menu-cart.elementor-menu-cart--cart-type-mini-cart:not(.elementor-menu-cart--shown)
      .elementor-menu-cart__main {
      opacity: 0;
      transform: translateY(-10px);
    }
    .elementor-edit-area-active
      .elementor-widget-woocommerce-menu-cart.elementor-widget.elementor-loading.elementor-menu-cart--shown {
      opacity: 1;
      /**
   * This keeps side-cart and mini-cart widget above other widgets
   * so there's no flash when using the typography controls
   */
    }
    .elementor-edit-area-active
      .elementor-widget-woocommerce-menu-cart.elementor-widget.elementor-loading.elementor-menu-cart--shown
      .elementor-menu-cart__container {
      z-index: 9999;
    }
    .elementor-widget-woocommerce-menu-cart.elementor-menu-cart--cart-type-dropdown
      .elementor-menu-cart__container {
      display: none;
    }

    .elementor-widget-woocommerce-purchase-summary {
      font-size: 14px;
      font-family: Roboto, sans-serif;
      color: #69727d;
      /* override theme defaults for themes that have hover background colors on these elements. The hover background colors do not play well with the widget's initial design. */
    }
    .elementor-widget-woocommerce-purchase-summary table tbody tr:hover > td,
    .elementor-widget-woocommerce-purchase-summary table tbody tr:hover > th {
      background-color: initial;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-thankyou-order-details {
      padding-inline-start: 0;
      margin: 0 0 2em;
      display: flex;
      flex-wrap: wrap;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-thankyou-order-details
      li {
      border-right-style: var(--payment-details-border-type, solid);
      border-right-color: var(--payment-details-border-color, #d5d8dc);
      border-right-width: var(--payment-details-border-width, 1px);
      color: var(--payment-details-titles-color, #000000);
      font-weight: 700;
      font-size: 14px;
      text-transform: capitalize;
      margin-right: var(--payment-details-space-between, 4em);
      padding-right: var(--payment-details-space-between, 4em);
      float: unset;
      margin-bottom: 30px;
      /* By default, WooCommerce styles the order data as Bold, and the 'Headings' as normal text. In Elementor's default design, the headings are bold, and the `<strong>` element which contains the order data, needs to be reset to look like normal text. */
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-purchase-summary
        .woocommerce
        .woocommerce-thankyou-order-details
        li {
        border-right: none;
        border-bottom-width: var(--payment-details-border-width, 1px);
        border-bottom-style: var(--payment-details-border-type, solid);
        border-bottom-color: var(--payment-details-border-color, #d5d8dc);
        margin-right: 0;
        padding-right: 0;
        width: 100%;
        padding-top: calc(var(--payment-details-space-between, 20px) / 2);
        padding-bottom: calc(var(--payment-details-space-between, 20px) / 2);
        justify-content: space-between;
        display: flex;
        margin-bottom: 0;
      }
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-thankyou-order-details
      li
      strong {
      color: var(--payment-details-items-color, #69727d);
      font-weight: 400;
      font-size: 14px;
      margin-top: var(--payment-details-titles-spacing, 10px);
      text-transform: none;
      line-height: initial;
      text-shadow: none;
      font-style: normal;
      letter-spacing: 0px;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-purchase-summary
        .woocommerce
        .woocommerce-thankyou-order-details
        li
        strong {
        margin-top: 0;
      }
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-bacs-bank-details
      .wc-bacs-bank-details {
      padding-inline-start: 0;
      display: flex;
      flex-wrap: wrap;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-bacs-bank-details
      .wc-bacs-bank-details
      li {
      border-right-style: var(--bank-details-border-type, solid);
      border-right-color: var(--bank-details-border-color, #d5d8dc);
      border-right-width: var(--bank-details-border-width, 1px);
      color: var(--bank-details-titles-color, #000000);
      font-weight: 700;
      font-size: 14px;
      text-transform: capitalize;
      margin-right: var(--bank-details-space-between, 4em);
      padding-right: var(--bank-details-space-between, 4em);
      float: unset;
      margin-bottom: 30px;
      /* By default, WooCommerce styles the order data as Bold, and the 'Headings' as normal text. In Elementor's default design, the headings are bold, and the `<strong>` element which contains the order data, needs to be reset to look like normal text. */
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-purchase-summary
        .woocommerce
        .woocommerce-bacs-bank-details
        .wc-bacs-bank-details
        li {
        border-right: none;
        border-bottom-width: var(--bank-details-border-width, 1px);
        border-bottom-style: var(--bank-details-border-type, solid);
        border-bottom-color: var(--bank-details-border-color, #d5d8dc);
        margin-right: 0;
        padding-right: 0;
        width: 100%;
        padding-top: calc(var(--bank-details-space-between, 20px) / 2);
        padding-bottom: calc(var(--bank-details-space-between, 20px) / 2);
        justify-content: space-between;
        display: flex;
        margin-bottom: 0;
      }
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-bacs-bank-details
      .wc-bacs-bank-details
      li
      strong {
      color: var(--bank-details-items-color, #69727d);
      font-weight: 400;
      font-size: 14px;
      margin-top: var(--bank-details-titles-spacing, 10px);
      text-transform: none;
      line-height: initial;
      text-shadow: none;
      font-style: normal;
      letter-spacing: 0px;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-purchase-summary
        .woocommerce
        .woocommerce-bacs-bank-details
        .wc-bacs-bank-details
        li
        strong {
        margin-top: 0;
      }
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-bacs-bank-details
      .wc-bacs-bank-details
      li:last-of-type {
      border-right: none;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .wc-item-meta
      li
      p,
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .wc-item-meta
      .wc-item-meta-label {
      color: var(--order-details-variations-color, #69727d);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce .shop_table {
      font-size: 14px;
      margin-bottom: 0;
      padding: var(--sections-padding, 15px 30px);
      background-color: var(--sections-background-color, #ffffff);
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-radius: var(--sections-border-radius, 3px);
      border-width: 1px;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      .button.alt {
      background-color: transparent;
      border-style: var(--buttons-border-type, solid);
      border-color: var(--buttons-border-color, #5bc0de);
      border-radius: var(--button-border-radius, 3px);
      border-width: 2px;
      vertical-align: middle;
      color: var(--button-normal-text-color, #69727d);
      padding: var(--button-padding, 5px 10px);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      .button.alt:hover {
      color: var(--button-hover-text-color, #69727d);
      transition-duration: var(--button-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce .shop_table td,
    .elementor-widget-woocommerce-purchase-summary .woocommerce .shop_table th {
      border: 0px;
      border-top-style: var(--tables-divider-border-type, solid);
      border-top-width: var(--tables-divider-border-width, 1px);
      border-top-color: var(--tables-divider-border-color, #d5d8dc);
      padding-top: calc(var(--order-details-rows-gap, 18px) / 2);
      padding-bottom: calc(var(--order-details-rows-gap, 18px) / 2);
      padding-left: 0;
      padding-right: 0;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      thead
      tr
      th {
      color: var(--order-details-titles-totals-color, #000000);
      border-top: none;
      padding-top: 0;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      thead
      tr
      th
      span {
      color: var(--order-details-titles-totals-color, #000000);
    }
    @media (min-width: 1025px) {
      .elementor-widget-woocommerce-purchase-summary
        .woocommerce
        .shop_table
        tbody
        td {
        vertical-align: top;
        line-height: unset;
      }
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      tbody
      td
      .woocommerce-Price-amount {
      color: var(--order-details-items-color, #69727d);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      tfoot
      th,
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      tfoot
      td {
      color: var(--order-details-titles-totals-color, #000000);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      tfoot
      tr:last-child
      th,
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      tfoot
      tr:last-child
      td {
      padding-bottom: 0;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      .product-quantity,
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      td.download-remaining,
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      td.download-expires {
      font-weight: 400;
      color: var(--order-details-items-color, #69727d);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .shop_table
      .product-purchase-note
      td {
      border-top: none;
      padding-top: 0;
      color: var(--general-text-color, #69727d);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-table--order-downloads
      tr
      td:before {
      color: var(--order-details-titles-totals-color, #000000);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .order-again
      .button {
      background: transparent;
      border: 2px solid #5bc0de;
      border-style: var(--buttons-border-type, solid);
      border-color: var(--buttons-border-color, #5bc0de);
      border-width: 2px;
      border-radius: var(--button-border-radius, 3px);
      vertical-align: middle;
      color: var(--button-normal-text-color, #69727d);
      margin-top: 40px;
      margin-bottom: 0;
      padding: var(--button-padding, 12px 32px);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .order-again
      .button:hover {
      color: var(--button-hover-text-color, #69727d);
      transition-duration: var(--button-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce h2 {
      color: var(--titles-color, #000000);
      margin-bottom: var(--titles-spacing, 45px);
      font-weight: 400;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-order-details
      h2 {
      text-align: var(--order-summary-alignment, inherit);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .wc-bacs-bank-details-heading {
      text-align: var(--bank-details-alignment, inherit);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-order-downloads__title {
      text-align: var(--downloads-alignment, inherit);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-customer-details
      > h2,
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-column--billing-address
      h2 {
      text-align: var(--billing-details-alignment, inherit);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-column--shipping-address
      h2 {
      text-align: var(--shipping-details-alignment, inherit);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce a {
      color: var(--order-details-product-links-normal-color, #5bc0de);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce a:hover {
      color: var(--order-details-product-links-hover-color, #5bc0de);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce p {
      margin-bottom: 20px;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-thankyou-order-received {
      margin-bottom: var(--sections-spacing, 40px);
      color: var(--confirmation-message-color, #69727d);
      text-align: var(--confirmation-message-alignment, inherit);
      display: var(--confirmation-message-display, none);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce strong {
      color: var(--general-text-color, #000000);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      table
      tbody
      > tr:nth-child(odd)
      > td,
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      table
      tbody
      > tr:nth-child(odd)
      > th {
      background-color: transparent;
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce address {
      padding: var(--sections-padding, 15px 30px);
      background-color: var(--sections-background-color, #ffffff);
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-radius: var(--sections-border-radius, 3px);
      border-width: 1px;
      color: var(--general-text-color, #69727d);
    }
    @media (max-width: 767px) {
      .elementor-widget-woocommerce-purchase-summary .woocommerce-column--2 {
        margin-top: 2em;
      }
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .woocommerce-thankyou-order-details
      + p {
      color: var(--general-text-color, #69727d);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .wc-bacs-bank-details-account-name {
      color: var(--account-title-color, #000000);
      font-weight: 700;
      font-size: 14px;
      margin-bottom: var(--account-title-spacing, 1rem);
    }
    .elementor-widget-woocommerce-purchase-summary .woocommerce section {
      margin-top: var(--sections-spacing, 4em);
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce
      .wc-bacs-bank-details:last-child {
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce-table__line-item.order_item
      .woocommerce-table__product-name.product-purchase-note-is-below {
      padding-bottom: 0;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce-table__line-item.order_item
      .woocommerce-table__product-total.product-total.product-purchase-note-is-below {
      padding-bottom: 0;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce-table--order-details {
      table-layout: fixed;
    }
    .elementor-widget-woocommerce-purchase-summary
      .woocommerce-table--order-details
      td {
      word-wrap: break-word;
    }

    /* Select2 dropdown options are outside of the widget container */
    .e-woo-select2-wrapper .select2-results__option {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #69727d;
    }
    .e-woo-select2-wrapper .select2-results__option:focus {
      color: #69727d;
    }

    .elementor-widget-woocommerce-checkout-page {
      background-color: transparent;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 21px;
      color: #69727d;
      /* one column layout */
      /* override theme defaults for themes that have hover background colors on these elements. The hover background colors do not play well with the widget's initial design. */
      /* Make select2 appear like other inputs start */
      /* Make select2 appear like other inputs end */
    }
    .elementor-widget-woocommerce-checkout-page.e-checkout-layout-one-column
      .e-checkout__container {
      grid-template-columns: auto;
    }
    .elementor-widget-woocommerce-checkout-page ::-moz-placeholder {
      color: var(--forms-fields-normal-color, inherit);
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-widget-woocommerce-checkout-page ::placeholder {
      color: var(--forms-fields-normal-color, inherit);
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-widget-woocommerce-checkout-page table tbody tr:hover > td,
    .elementor-widget-woocommerce-checkout-page table tbody tr:hover > th {
      background-color: transparent;
    }
    .elementor-widget-woocommerce-checkout-page
      .select2-container--default
      .select2-selection--single {
      color: var(--forms-fields-normal-color, #69727d);
      background-color: #f9fafa;
      border-radius: var(--forms-fields-border-radius, 0px);
      border: none;
      height: 45px;
    }
    .elementor-widget-woocommerce-checkout-page
      .select2-container--default
      .select2-selection--single:focus {
      color: var(--forms-fields-focus-color, #69727d);
      background-color: #f9fafa;
      border-color: initial;
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-checkout-page
      .select2-container--default
      .select2-selection--single
      .select2-selection__placeholder {
      color: var(--forms-fields-normal-color, #69727d);
    }
    .elementor-widget-woocommerce-checkout-page
      .select2-container--default
      .select2-selection--single
      .select2-selection__rendered {
      color: var(--forms-fields-normal-color, #69727d);
      line-height: 45px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .elementor-widget-woocommerce-checkout-page
      .select2-container--default
      .select2-selection--single
      .select2-selection__arrow {
      height: 45px;
    }
    .elementor-widget-woocommerce-checkout-page
      .select2-container--open
      .select2-dropdown--below {
      background-color: #f9fafa;
    }
    .elementor-widget-woocommerce-checkout-page .e-description {
      color: var(--sections-descriptions-color, #69727d);
      padding-bottom: var(--sections-descriptions-spacing, 0px);
      font-size: 14px;
      font-weight: 400;
    }
    .elementor-widget-woocommerce-checkout-page .e-woocommerce-login-section {
      margin-bottom: 24px;
    }
    .elementor-widget-woocommerce-checkout-page
      .e-woocommerce-login-section
      .e-checkout-secondary-title {
      text-align: var(--login-title-alignment, left);
    }
    .elementor-widget-woocommerce-checkout-page .e-woocommerce-login-nudge {
      margin-top: var(--sections-secondary-title-spacing, 24px);
      margin-bottom: 15px;
    }
    .elementor-widget-woocommerce-checkout-page .e-coupon-anchor {
      margin-top: var(--sections-secondary-title-spacing, 24px);
    }
    .elementor-widget-woocommerce-checkout-page .e-coupon-box {
      margin-top: 24px;
    }
    .elementor-widget-woocommerce-checkout-page .e-coupon-anchor-description {
      color: var(--forms-labels-color, #69727d);
      margin-bottom: var(--forms-label-spacing, 3px);
    }
    .elementor-widget-woocommerce-checkout-page .e-login-wrap {
      display: flex;
      align-items: center;
    }
    .elementor-widget-woocommerce-checkout-page .e-login-wrap-start {
      flex: 75%;
    }
    .elementor-widget-woocommerce-checkout-page .e-login-wrap-end {
      flex: 20%;
      text-align: right;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page .e-login-wrap {
        display: block;
      }
      .elementor-widget-woocommerce-checkout-page .e-login-wrap-end {
        text-align: var(--login-button-alignment, left);
        margin-top: 15px;
      }
      .elementor-widget-woocommerce-checkout-page
        .e-login-wrap-end
        label.e-login-label {
        display: none;
      }
    }
    .elementor-widget-woocommerce-checkout-page .e-login-actions-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;
    }
    .elementor-widget-woocommerce-checkout-page
      .e-login-actions-wrap-end
      .lost_password {
      margin-bottom: 0;
      font-size: 12px;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page
        .e-login-actions-wrap-end
        .lost_password {
        font-size: 11px;
      }
    }
    .elementor-widget-woocommerce-checkout-page .e-apply-coupon {
      width: 90%; /* 90% to match the design */
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page .e-apply-coupon {
        width: var(--coupon-button-width, auto);
      }
    }
    .elementor-widget-woocommerce-checkout-page .e-checkout__container {
      display: grid;
      flex-wrap: wrap;
      grid-template-columns: 56% auto; /* column widths - to match the design */
      align-items: stretch;
      grid-column-gap: var(--sections-margin, 24px);
      grid-row-gap: var(--sections-margin, 24px);
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page .e-checkout__container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .elementor-widget-woocommerce-checkout-page .e-checkout-secondary-title {
      color: var(--sections-secondary-title-color, #69727d);
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-checkout-page .e-woocommerce-coupon-nudge {
      text-align: var(--coupon-title-alignment, left);
    }
    .elementor-widget-woocommerce-checkout-page #ship-to-different-address {
      margin-top: 13px;
      padding-left: var(--shipping-heading-padding-start, 30px);
    }
    .elementor-widget-woocommerce-checkout-page
      #ship-to-different-address
      span {
      font-weight: 400;
    }
    .elementor-widget-woocommerce-checkout-page a {
      color: var(--links-normal-color, #5bc0de);
    }
    .elementor-widget-woocommerce-checkout-page a:hover {
      color: var(--links-hover-color, #5bc0de);
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce {
      /* Need to use these specific selectors to override WooCommerce CSS */
      /* Need the <button> selector here so that we can target .woocommerce-button
  without being overwritten by WooCommerce's button.button selector.
  We are also targeting .woocommerce-button because none of this styling should
  apply to the Purchase button. Only The Login/Apply coupon button. */
      /* Main Sections styling start */
      /* Main Sections styling end */
      /* Styling for everything classified as 'Messages' start */
      /* Styling for everything classified as 'Messages' end */
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row {
      padding-left: var(--forms-columns-gap-padding, 0px);
      padding-right: var(--forms-columns-gap-padding, 0px);
      margin-left: var(--forms-columns-gap-margin, 0px);
      margin-right: var(--forms-columns-gap-margin, 0px);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row
      label,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row
      label,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row
      label {
      color: var(--forms-labels-color, #69727d);
      margin-bottom: var(--forms-label-spacing, 3px);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row
      .input-text,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row
      select,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row
      textarea,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row
      .input-text,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row
      select,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row
      textarea,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row
      .input-text,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row
      select,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row
      textarea {
      color: var(--forms-fields-normal-color, #69727d);
      background-color: #f9fafa;
      border-radius: var(--forms-fields-border-radius, 0px);
      padding: var(--forms-fields-padding, 16px);
      font-size: 14px;
      border: none;
      font-weight: 400;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row
      .input-text:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row
      select:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .form-row
      textarea:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row
      .input-text:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row
      select:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      .form-row
      textarea:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row
      .input-text:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row
      select:focus,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row
      textarea:focus {
      color: var(--forms-fields-focus-color, #69727d);
      background-color: #f9fafa;
      border-color: #69727d;
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      #billing_address_1_field,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-anchor
      #billing_address_1_field,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      #billing_address_1_field {
      margin-bottom: 5px;
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce .create-account,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-coupon-box
      .form-row {
      /* need to use !important here to override the style controls,
  these items must not be affected by the controls */
      margin-bottom: 0 !important;
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce .input-radio,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #shipping_method
      li
      input {
      vertical-align: middle;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-form__input-checkbox {
      vertical-align: middle;
      margin: 0 5px 0 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-form__label-for-checkbox
      span {
      position: relative;
      top: 2px;
      color: var(--sections-checkboxes-color, #69727d);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .wc_payment_method
      label,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #shipping_method
      li
      label {
      color: var(--sections-radio-buttons-color, #69727d);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .wc_payment_method
      label {
      display: inline;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      button.woocommerce-button {
      background-color: var(--e-a-bg-default);
      color: var(--forms-buttons-normal-text-color, #6f6f6f);
      border-radius: var(--forms-buttons-border-radius, 3px);
      padding: 1rem;
      border-style: var(--forms-buttons-border-type, solid);
      border-color: var(--forms-buttons-border-color, #5bc0de);
      border-width: 2px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      button.woocommerce-button:hover {
      color: var(--forms-buttons-hover-text-color, #6f6f6f);
      transition-duration: var(--forms-buttons-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce #coupon_code {
      margin-right: 1%;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page .woocommerce #coupon_code {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce-info {
      border-top-color: transparent;
      background-color: transparent;
      padding: 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-privacy-policy-text
      p {
      font-weight: 400;
      font-size: 12px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-form-login-toggle
      .woocommerce-info {
      font-weight: 400;
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-woocommerce-login-section,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-checkout__order_review,
    .elementor-widget-woocommerce-checkout-page .woocommerce .e-coupon-box,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout
      #payment,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .col-1,
    .elementor-widget-woocommerce-checkout-page .woocommerce .shipping_address,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-additional-fields {
      background: var(--sections-background-color, #ffffff);
      border-radius: var(--sections-border-radius, 3px);
      padding: var(--sections-padding, 16px 30px);
      margin: var(--sections-margin, 0 0 24px 0);
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-width: 1px;
      display: block;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-additional-fields {
      display: var(--additional-information-display, none);
    }
    @media (min-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page
        .woocommerce
        .woocommerce-additional-fields {
        margin: var(--sections-margin, 0);
      }
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout
      #payment
      .payment_box,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-privacy-policy-text,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .e-checkout-message {
      color: var(--sections-messages-color, #69727d);
      font-weight: 400;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-privacy-policy-text
      p {
      margin-top: 25px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .col2-set
      .col-1 {
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #customer_details
      .col2-set
      .col-2 {
      padding-top: 15px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #order_review_heading {
      text-align: var(--order-review-title-alignment, left);
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce .shop_table {
      margin-bottom: 0;
      border: 0;
      font-size: 14px;
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce .shop_table thead {
      background-color: transparent;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      thead
      tr
      th {
      padding-top: 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      tbody
      td {
      color: #000000;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      tbody
      td
      .product-quantity {
      font-weight: normal;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      tfoot
      th,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      tfoot
      td {
      color: #69727d;
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce .shop_table td,
    .elementor-widget-woocommerce-checkout-page .woocommerce .shop_table tr,
    .elementor-widget-woocommerce-checkout-page .woocommerce .shop_table th {
      border: 0;
      padding-left: 0;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      .order-total
      th,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      .order-total
      tr,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      .order-total
      td {
      padding-bottom: 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      tr:nth-child(odd)
      > td,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .shop_table
      tr:nth-child(odd)
      > th {
      background-color: transparent;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      .cart_item
      td {
      font-weight: 400;
      color: var(--order-summary-items-color, #000000);
      border-bottom-color: var(--order-summary-items-divider-color, #69727d);
      border-bottom-width: var(--order-summary-items-divider-weight, 0px);
      border-bottom-style: solid;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      .cart_item
      td.product-name {
      padding-right: 40px;
      max-width: 150px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      .cart_item
      td.product-total {
      vertical-align: top;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      td,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      th {
      padding-top: var(--order-summary-rows-gap-top, 15px);
      padding-bottom: var(--order-summary-rows-gap-bottom, 15px);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      thead
      th,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      tfoot
      td,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      tfoot
      th {
      color: var(--order-summary-totals-color, #69727d);
      vertical-align: top;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      .order-total
      td,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .woocommerce-checkout-review-order-table
      .order-total
      th {
      border-top-color: var(--order-summary-totals-divider-color, #69727d);
      border-top-width: var(--order-summary-totals-divider-weight, 0px);
      border-top-style: solid;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-shipping-totals
      td {
      max-width: 70px;
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce h3 {
      font-size: 14px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: var(--sections-title-spacing, 30px);
      color: var(--sections-title-color, #000000);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .form-row
      > span {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 3px;
      color: #69727d;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .form-row-first,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .form-row-last {
      /* use 48% to match the design. Default WC CSS uses 47% */
      width: 48%;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .form-row
      .input-checkbox {
      vertical-align: middle;
      margin: 0 5px 0 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .woocommerce-billing-fields
      h3 {
      text-align: var(--billing-details-title-alignment, left);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .woocommerce-billing-fields
      .form-row,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .woocommerce-shipping-fields
      .form-row,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .woocommerce-account-fields
      .form-row {
      margin-bottom: var(--forms-rows-gap, 5px);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .woocommerce-billing-fields
      .form-row:last-child,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .woocommerce-shipping-fields
      .form-row:last-child,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .woocommerce-account-fields
      .form-row:last-child {
      margin-bottom: 15px;
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce-checkout.login {
      margin-top: -8px;
      z-index: 999;
      background: #ffffff;
      border-top-width: 0;
      position: relative;
      margin-bottom: 0;
      color: #69727d;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page
        .woocommerce-checkout
        .form-row-first,
      .elementor-widget-woocommerce-checkout-page
        .woocommerce-checkout
        .form-row-last {
        width: 100%;
      }
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-form-coupon-toggle {
      display: none;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-form-login__submit {
      width: 85%; /* 85% to match the design */
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page
        .woocommerce-form-login__submit {
        width: var(--login-button-width, 35%); /* 35% to match the design */
      }
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-additional-fields
      h3 {
      text-align: var(--additional-fields-title-alignment, left);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-shipping-fields
      .shipping_address {
      margin-bottom: var(--sections-margin, 20px);
    }
    .elementor-widget-woocommerce-checkout-page .woocommerce-checkout #payment {
      margin-top: 24px;
      padding: 15px 25px 25px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      .payment_methods {
      border-bottom: none;
      padding: 0;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      .payment_methods
      .payment_box {
      background-color: #f9fafa;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      .payment_methods
      .payment_box:before {
      display: none;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      .payment_methods
      li {
      line-height: 21px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      .payment_methods
      li
      label
      a {
      padding-left: 15px;
      font-size: 12px;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page
        .woocommerce-checkout
        #payment
        .payment_methods
        li
        label
        a {
        float: none;
        font-size: 11px;
        padding-left: 10px;
      }
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page
        .woocommerce-checkout
        #payment
        .payment_methods
        li
        label
        img {
        width: 55px; /* 55px to match the design */
      }
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      .place-order {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0;
      margin-bottom: 0;
      align-items: var(--place-order-title-alignment, stretch);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      #place_order {
      background-color: #5bc0de;
      width: var(--purchase-button-width, auto);
      float: none;
      color: var(--purchase-button-normal-text-color, #ffffff);
      min-height: auto;
      padding: var(--purchase-button-padding, 1em 1em);
      border-radius: var(--purchase-button-border-radius, 3px);
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      #place_order:hover {
      background-color: #5bc0de;
      color: var(--purchase-button-hover-text-color, #ffffff);
      border-color: var(--purchase-button-hover-border-color, #5bc0de);
      transition-duration: var(
        --purchase-button-hover-transition-duration,
        0.3s
      );
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      #payment
      .woocommerce-info:before {
      display: none;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .col2-set
      .col-1,
    .elementor-widget-woocommerce-checkout-page
      .woocommerce-checkout
      .col2-set
      .col-2 {
      width: auto;
      float: none;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .coupon-container-grid {
      display: grid;
      grid-template-columns: auto auto; /*columns widths*/
      align-items: center;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .coupon-container-grid
      .coupon-col-2 {
      text-align: right;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-checkout-page
        .woocommerce
        .coupon-container-grid {
        display: block;
      }
      .elementor-widget-woocommerce-checkout-page
        .woocommerce
        .coupon-container-grid
        .coupon-col-2 {
        text-align: var(--coupon-button-alignment, left);
      }
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      #account_password_field {
      /* need to override the style controls,
  these items must not be affected by the controls */
      margin-bottom: 10px;
    }
    .elementor-widget-woocommerce-checkout-page
      .woocommerce
      .product-name
      .variation {
      color: var(--order-summary-variations-color, #000000);
      /* We need to specify these values so that the 'Items' Typography control does not affect
  the 'Variations' */
      font-size: 14px;
      font-style: normal;
      text-transform: none;
      letter-spacing: normal;
      text-decoration: none;
      line-height: 21px;
    }

    /* Select2 dropdown options are outside of the widget container */
    .e-woo-select2-wrapper .select2-results__option {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #69727d;
    }
    .e-woo-select2-wrapper .select2-results__option:focus {
      color: #69727d;
    }

    .elementor-widget-woocommerce-cart {
      /* one column layout */
      /* Make select2 appear like other inputs start */
      /* Make select2 appear like other inputs end */
    }
    .elementor-widget-woocommerce-cart.e-cart-layout-one-column
      .e-cart__container {
      grid-template-columns: auto;
    }
    .elementor-widget-woocommerce-cart table tbody tr:hover > td,
    .elementor-widget-woocommerce-cart table tbody tr:hover > th {
      background-color: inherit;
    }
    .elementor-widget-woocommerce-cart .e {
      /* .e-clear is a class that is added between the cart sections to fix collapsing margins,
  especially when custom margins are being set by the Style Controls, then the margins could
  start overriding the other margin, leaving one margin */
    }
    .elementor-widget-woocommerce-cart .e-clear {
      overflow: hidden;
      height: 0;
      width: 0;
    }
    .elementor-widget-woocommerce-cart .e-cart {
      /* Main sections styling start */
      /* Main sections styling end */
      /* one column layout */
    }
    .elementor-widget-woocommerce-cart .e-cart-section {
      background-color: var(--sections-background-color, #ffffff);
      padding: var(--sections-padding, 16px 30px);
      margin: var(--sections-margin, 0 0 30px 0);
      border-radius: var(--sections-border-radius, 3px);
      border-width: 1px;
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
    }
    .elementor-widget-woocommerce-cart .e-cart-section--no-coupon {
      margin: var(--sections-margin, 0 0 0 0);
    }
    @media (min-width: 1024px) {
      .elementor-widget-woocommerce-cart .e-cart-section:last-child {
        margin: var(--sections-margin, 0);
      }
    }
    .elementor-widget-woocommerce-cart .e-cart-content {
      color: var(--sections-descriptions-color, #69727d);
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0;
    }
    .elementor-widget-woocommerce-cart .e-cart__container {
      display: grid;
      flex-wrap: wrap;
      grid-template-columns: 59% auto; /* 59% to match the required design */
      align-items: stretch;
      grid-column-gap: var(--sections-margin, 30px);
      grid-row-gap: var(--sections-margin, 30px);
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart .e-cart__container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .elementor-widget-woocommerce-cart
      .e-cart-layout-one-column
      .e-cart__container {
      grid-template-columns: auto;
    }
    .elementor-widget-woocommerce-cart .select2-container {
      margin-bottom: 4px;
    }
    .elementor-widget-woocommerce-cart
      .select2-container--default
      .select2-selection--single {
      color: var(--forms-fields-normal-color, #69727d);
      background-color: #f9fafa;
      border-radius: var(--forms-fields-border-radius, 0px);
      border: none;
      height: 45px;
    }
    .elementor-widget-woocommerce-cart
      .select2-container--default
      .select2-selection--single:focus {
      color: var(--forms-fields-focus-color, #69727d);
      background-color: #f9fafa;
      border-color: var(--forms-fields-focus-border-color, initial);
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-cart
      .select2-container--default
      .select2-selection--single
      .select2-selection__placeholder {
      color: var(--forms-fields-normal-color, #69727d);
    }
    .elementor-widget-woocommerce-cart
      .select2-container--default
      .select2-selection--single
      .select2-selection__rendered {
      color: var(--forms-fields-normal-color, #69727d);
      line-height: 45px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .elementor-widget-woocommerce-cart
      .select2-container--default
      .select2-selection--single
      .select2-selection__arrow {
      height: 45px;
    }
    .elementor-widget-woocommerce-cart
      .select2-container--open
      .select2-dropdown--below {
      background-color: #f9fafa;
    }
    .elementor-widget-woocommerce-cart .woocommerce {
      font-family: Roboto, sans-serif;
      /* The :not() selector is to avoid Cart widget styling to affect any widgets used in the custom Empty Cart template. */
      /*
   * Disable updating the shipping method or using the the shipping calculator while in the editor. If the
   * document is still a draft (autosaved, or just not published), updating will result in the old non-edited
   * document being returned.
   */
    }
    .elementor-widget-woocommerce-cart .woocommerce button.button {
      background-color: var(--e-a-bg-default);
      color: var(--forms-buttons-normal-text-color, #69727d);
      border-radius: var(--forms-buttons-border-radius, 3px);
      /* 0.88rem is used here so that this button will match the design layout from Product. */
      padding: var(--forms-buttons-padding, 0.88rem 2rem);
      width: var(--forms-buttons-width, auto);
      border-style: var(--forms-buttons-border-type, solid);
      border-color: var(--forms-buttons-border-color, #5bc0de);
      border-width: 2px;
    }
    .elementor-widget-woocommerce-cart .woocommerce button.button:hover {
      color: var(--forms-buttons-hover-text-color, #69727d);
      transition-duration: var(--forms-buttons-hover-transition-duration, 0.3s);
      border-color: var(--forms-buttons-hover-border-color, #5bc0de);
    }
    .elementor-widget-woocommerce-cart .woocommerce button.button.disabled,
    .elementor-widget-woocommerce-cart .woocommerce button.button:disabled,
    .elementor-widget-woocommerce-cart
      .woocommerce
      button.button:disabled[disabled] {
      /* 0.88rem is used here so that this button will match the design layout from Product. */
      padding: var(--forms-buttons-padding, 0.88rem 2rem);
      color: var(--forms-buttons-normal-text-color, #69727d);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      button.button.disabled:hover,
    .elementor-widget-woocommerce-cart
      .woocommerce
      button.button:disabled:hover,
    .elementor-widget-woocommerce-cart
      .woocommerce
      button.button:disabled[disabled]:hover {
      background-color: var(--forms-buttons-hover-background-color, #ffffff);
      color: var(--forms-buttons-hover-text-color, #69727d);
      transition-duration: var(--forms-buttons-hover-transition-duration, 0.3s);
      border-color: var(--forms-buttons-hover-border-color, #5bc0de);
    }
    .elementor-widget-woocommerce-cart .woocommerce #shipping_method li label {
      color: var(--sections-radio-buttons-color, #69727d);
    }
    .elementor-widget-woocommerce-cart .woocommerce #shipping_method li input {
      vertical-align: middle;
    }
    .elementor-widget-woocommerce-cart .woocommerce .shipping-calculator-form {
      margin-top: var(--sections-descriptions-spacing, 15px);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .woocommerce-shipping-destination {
      color: var(--sections-descriptions-color, #69727d);
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table_responsive
      tbody
      th,
    .elementor-widget-woocommerce-cart
      .woocommerce-page
      .cart_totals
      table.shop_table_responsive
      tbody
      th {
      display: none;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table_responsive
      tr.woocommerce-shipping-totals
      td,
    .elementor-widget-woocommerce-cart
      .woocommerce-page
      .cart_totals
      table.shop_table_responsive
      tr.woocommerce-shipping-totals
      td {
      text-align: left !important; /* need important here because Woo also has !important */
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table_responsive
      tr.woocommerce-shipping-totals
      td::before,
    .elementor-widget-woocommerce-cart
      .woocommerce-page
      .cart_totals
      table.shop_table_responsive
      tr.woocommerce-shipping-totals
      td::before {
      width: 100%;
      margin-bottom: 10px;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table_responsive
      tr
      td,
    .elementor-widget-woocommerce-cart
      .woocommerce-page
      .cart_totals
      table.shop_table_responsive
      tr
      td {
      text-align: right !important; /* need important here because Woo also has !important */
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table_responsive
      tr
      td::before,
    .elementor-widget-woocommerce-cart
      .woocommerce-page
      .cart_totals
      table.shop_table_responsive
      tr
      td::before {
      content: attr(data-title);
      font-weight: 700;
      float: left;
    }
    .elementor-widget-woocommerce-cart .woocommerce .cart_totals h2 {
      text-align: var(--totals-title-alignment, left);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table
      td {
      padding-top: var(--totals-rows-gap-top, 12px);
      padding-bottom: var(--totals-rows-gap-bottom, 9px);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table
      td:before,
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table
      td
      .woocommerce-Price-amount {
      color: var(--totals-color, #69727d);
      font-weight: 700;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart_totals
      table.shop_table
      tr.order-total
      td {
      border-top-color: var(--totals-divider-color, #69727d);
      border-top-width: var(--totals-divider-weight, 0);
    }
    .elementor-widget-woocommerce-cart .woocommerce .cart_totals .form-row {
      margin-bottom: var(--forms-rows-gap, 6px);
    }
    .elementor-widget-woocommerce-cart .woocommerce .product-name a {
      color: var(--product-link-normal-color, #000000);
    }
    .elementor-widget-woocommerce-cart .woocommerce .product-name a:hover {
      color: var(--product-link-hover-color, #000000);
    }
    .elementor-widget-woocommerce-cart .woocommerce .form-row {
      padding: 3px 0;
      margin: 0;
    }
    .elementor-widget-woocommerce-cart .woocommerce .input-text,
    .elementor-widget-woocommerce-cart .woocommerce select {
      color: var(--forms-fields-normal-color, #69727d);
      background-color: #f9fafa;
      border-radius: var(--forms-fields-border-radius, 3px);
      border: none;
      padding: var(--forms-fields-padding, 16px);
      font-size: 14px;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .input-text::-moz-placeholder,
    .elementor-widget-woocommerce-cart .woocommerce select::-moz-placeholder {
      color: var(--forms-fields-normal-color, inherit);
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-widget-woocommerce-cart .woocommerce .input-text::placeholder,
    .elementor-widget-woocommerce-cart .woocommerce select::placeholder {
      color: var(--forms-fields-normal-color, inherit);
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-widget-woocommerce-cart .woocommerce .input-text:focus,
    .elementor-widget-woocommerce-cart .woocommerce select:focus {
      color: var(--forms-fields-focus-color, #69727d);
      background-color: #f9fafa;
      border-color: var(--forms-fields-focus-border-color, transparent);
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .input-text:focus::-moz-placeholder,
    .elementor-widget-woocommerce-cart
      .woocommerce
      select:focus::-moz-placeholder {
      color: var(--forms-fields-focus-color, #69727d);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .input-text:focus::placeholder,
    .elementor-widget-woocommerce-cart .woocommerce select:focus::placeholder {
      color: var(--forms-fields-focus-color, #69727d);
    }
    .elementor-widget-woocommerce-cart .woocommerce .input-text.qty {
      background-color: transparent;
      color: var(--order-summary-color, #000000);
      border-width: var(--order-summary-quantity-border-weight, 1px);
      border-style: solid;
      border-color: var(--order-summary-quantity-border-color, #d5d8dc);
      border-radius: 3px;
      padding: 1px 2px;
      text-align: left;
      font-size: 14px;
      min-height: 28px;
      max-height: 28px;
    }
    .elementor-widget-woocommerce-cart .woocommerce .wc-proceed-to-checkout {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: var(--place-order-title-alignment, stretch);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .wc-proceed-to-checkout
      .checkout-button {
      padding: var(--checkout-button-padding, 15px 1em);
      width: var(--checkout-button-width, auto);
      font-size: 15px;
      text-align: center;
      color: var(--checkout-button-normal-text-color, #ffffff);
      background-color: #5bc0de;
      border-radius: var(--checkout-button-border-radius, 3px);
      min-height: auto;
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .wc-proceed-to-checkout
      .checkout-button:hover {
      color: var(--checkout-button-hover-text-color, #ffffff);
      background-color: #5bc0de;
      border-color: var(--checkout-button-hover-border-color, #5bc0de);
      transition-duration: var(
        --checkout-button-hover-transition-duration,
        0.3s
      );
    }
    .elementor-widget-woocommerce-cart .woocommerce .remove {
      background: transparent;
      color: var(
        --order-summary-remove-icon-normal-color,
        #000000
      ) !important; /* need important here because Woo also has !important */
      width: auto;
    }
    .elementor-widget-woocommerce-cart .woocommerce .remove:hover {
      background: transparent;
      color: var(
        --order-summary-remove-icon-hover-color,
        #5bc0de
      ) !important; /* need important here because Woo also has !important */
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart-collaterals
      .cart_totals {
      float: none;
      width: 100%;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart-collaterals
      .cart_totals
      td {
      padding-left: 0;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .cart-collaterals
      .cart_totals
      .woocommerce-shipping-totals
      td {
      padding-right: 0;
    }
    .elementor-widget-woocommerce-cart .woocommerce .coupon .button {
      float: right;
      width: 100%;
    }
    .elementor-widget-woocommerce-cart .woocommerce .coupon .form-row {
      padding: 0;
    }
    .elementor-widget-woocommerce-cart .woocommerce .coupon-col {
      display: flex;
    }
    .elementor-widget-woocommerce-cart .woocommerce .coupon-col-start {
      flex: 2;
      padding-right: 30px;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart .woocommerce .coupon-col-start {
        padding-right: 0;
      }
    }
    .elementor-widget-woocommerce-cart .woocommerce .coupon-col-end {
      flex: 1;
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart .woocommerce .coupon-col-end {
        text-align: var(--apply-coupon-button-alignment, center);
      }
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart .woocommerce .coupon-col {
        display: block;
      }
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart .woocommerce .coupon {
        margin: var(--sections-margin, 0);
      }
      .elementor-widget-woocommerce-cart .woocommerce .coupon #coupon_code {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
      .elementor-widget-woocommerce-cart .woocommerce .coupon .button {
        width: var(--apply-coupon-button-width, 100%);
        float: none;
      }
    }
    .elementor-widget-woocommerce-cart .woocommerce table.shop_table {
      border: 0 solid;
    }
    .elementor-widget-woocommerce-cart .woocommerce table.shop_table .button {
      float: left;
      font-size: 14px;
      font-weight: 700;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table.cart
      .actions {
      display: table-cell;
      /* need important here because Woo also has !important */
      text-align: var(--update-cart-button-alignment, left) !important;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table.cart
      .actions
      .button {
      display: inline-block !important;
      float: none;
      width: var(--update-cart-button-width, auto);
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.shop_table.cart
        .actions
        .button {
        width: var(--update-cart-button-width, 100%) !important;
      }
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table
      .shipping-calculator-form
      p:last-of-type {
      text-align: var(--update-shipping-button-alignment, start);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table
      .shipping-calculator-form
      .button {
      float: none;
      width: var(--update-shipping-button-width, auto);
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.shop_table
        .shipping-calculator-form
        .button {
        width: var(--update-shipping-button-width, 100%);
      }
    }
    .elementor-widget-woocommerce-cart .woocommerce table.shop_table td,
    .elementor-widget-woocommerce-cart .woocommerce table.shop_table tr,
    .elementor-widget-woocommerce-cart .woocommerce table.shop_table th {
      border: 0 solid;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table
      tr:nth-child(odd)
      > td,
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table
      tr:nth-child(odd)
      > th {
      background-color: transparent;
    }
    .elementor-widget-woocommerce-cart .woocommerce table.shop_table .actions {
      padding: 16px 0 0;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table
      .product-remove
      a {
      display: inline-block;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.shop_table.cart
      tbody
      tr:last-child {
      display: var(--update-cart-automatically-display, table-row);
    }
    .elementor-widget-woocommerce-cart .woocommerce table.cart {
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-cart .woocommerce table.cart img {
      width: 55px;
      height: auto;
      display: block;
    }
    .elementor-widget-woocommerce-cart .woocommerce table.cart td {
      padding-top: var(--order-summary-rows-gap-top, 20px);
      padding-right: 20px;
      padding-bottom: var(--order-summary-rows-gap-bottom, 20px);
      padding-left: 0;
    }
    .elementor-widget-woocommerce-cart .woocommerce table.cart td span {
      color: var(--order-summary-color, #000000);
      font-size: 14px;
      font-weight: 400;
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.cart
      td.product-remove,
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.cart
      td.product-thumbnail,
    .elementor-widget-woocommerce-cart .woocommerce table.cart td.product-name,
    .elementor-widget-woocommerce-cart .woocommerce table.cart td.product-price,
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.cart
      td.product-quantity,
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.cart
      td.product-subtotal,
    .elementor-widget-woocommerce-cart .woocommerce table.cart td.actions {
      border-top-width: var(--order-summary-items-divider-weight, 1px);
      border-top-style: solid;
      border-top-color: var(--order-summary-items-divider-color, #d5d8dc);
    }
    @media (max-width: 768px) {
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td.product-remove,
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td.product-thumbnail,
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td.product-name,
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td.product-price,
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td.product-quantity,
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td.product-subtotal,
      .elementor-widget-woocommerce-cart .woocommerce table.cart td.actions {
        border-top-width: 0;
      }
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      table.cart
      td.product-remove {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: 767px) {
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        tbody
        tr:first-child
        td {
        border-top: none;
      }
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart.product-remove {
        position: relative;
      }
      .elementor-widget-woocommerce-cart .woocommerce table.cart.product-name {
        padding-right: 0;
      }
      .elementor-widget-woocommerce-cart .woocommerce table.cart tr {
        border-top-width: var(--order-summary-items-divider-weight, 1px);
        border-top-color: var(--order-summary-items-divider-color, #d5d8dc);
      }
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        tr:first-child {
        border-top: none;
      }
    }
    @media (max-width: 768px) {
      .elementor-widget-woocommerce-cart .woocommerce table.cart td {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media (max-width: 767px) {
      .elementor-widget-woocommerce-cart .woocommerce table.cart {
        padding: 0;
      }
      .elementor-widget-woocommerce-cart .woocommerce table.cart tr {
        padding: 16px 28px;
      }
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        tr:first-child {
        border-top: none;
      }
      .elementor-widget-woocommerce-cart .woocommerce table.cart td {
        padding-left: 0;
        padding-right: 0;
      }
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td:first-child {
        border-top: none;
      }
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.cart
        td.product-name {
        border-top: none;
      }
      .elementor-widget-woocommerce-cart .woocommerce table.cart td.actions {
        padding: 0;
      }
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      a:not(.add_to_cart_button):not(.restore-item):not(.wc-backward):not(.wc-forward) {
      color: var(--links-normal-color, #5bc0de);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      a:not(.add_to_cart_button):not(.restore-item):not(.wc-backward):not(.wc-forward):hover {
      color: var(--links-hover-color, #5bc0de);
    }
    .elementor-widget-woocommerce-cart .woocommerce .cart_totals h2,
    .elementor-widget-woocommerce-cart .woocommerce .cart th {
      font-size: 14px;
      font-weight: 700;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 8px;
      padding-left: 0;
      padding-right: 20px;
    }
    .elementor-widget-woocommerce-cart .woocommerce .cart_totals h2 {
      margin-bottom: var(--sections-title-spacing, 1rem);
      color: var(--sections-title-color, #000000);
    }
    .elementor-widget-woocommerce-cart .woocommerce .cart th {
      padding-bottom: var(--order-summary-title-spacing, 8px);
      color: var(--order-summary-title-color, #000000);
    }
    .elementor-widget-woocommerce-cart .woocommerce .cart td::before {
      color: var(--order-summary-title-color, #000000);
    }
    .elementor-widget-woocommerce-cart
      .woocommerce
      .shipping-calculator-button::after {
      display: none;
    }
    .elementor-widget-woocommerce-cart .woocommerce .product-name .variation {
      color: var(--order-summary-variations-color, #000000);
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-cart
        .woocommerce
        table.shop_table_responsive
        tr:nth-child(2n)
        td,
      .elementor-widget-woocommerce-cart
        .woocommerce-page
        table.shop_table_responsive
        tr:nth-child(2n)
        td {
        background-color: transparent;
      }
      .elementor-widget-woocommerce-cart
        .woocommerce
        .cart_totals
        .shop_table_responsive
        td {
        padding-left: 0;
      }
    }
    .e-preview--show-hidden-elements
      .elementor-widget-woocommerce-cart
      .woocommerce
      #shipping_method
      input,
    .e-preview--show-hidden-elements
      .elementor-widget-woocommerce-cart
      .woocommerce
      .shipping-calculator-form
      button[name='calc_shipping'] {
      pointer-events: none;
    }

    /* Select2 dropdown options are outside of the widget container */
    .e-woo-select2-wrapper .select2-results__option {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: var(--forms-fields-normal-color, #69727d);
    }
    .e-woo-select2-wrapper .select2-results__option:focus {
      color: var(--forms-fields-focus-color, #69727d);
      border-color: #69727d;
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }

    .elementor-widget-woocommerce-my-account {
      font-family: Roboto, sans-serif;
      color: #69727d;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce-MyAccount-content {
      float: right;
      width: 75%; /* 75% to match the design */
      padding: 0;
      padding-left: var(--tab-content-spacing, 6%); /* 6% to match the design */
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-my-account
        .e-my-account-tab
        .woocommerce-MyAccount-content {
        width: 100%;
        padding: var(--tab-content-spacing, 6%) 0 0 0;
      }
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__dashboard:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__orders
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__downloads
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-account
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-address
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .woocommerce-MyAccount-content-wrapper {
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-width: var(--sections-border-top-width, 1px)
        var(--sections-border-right-width, 1px)
        var(--sections-border-bottom-width, 1px)
        var(--sections-border-left-width, 1px);
      border-radius: var(--sections-border-radius, 3px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__dashboard:not(.e-my-account-tab__dashboard--custom)
      .shop_table,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__orders
      .shop_table,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__downloads
      .shop_table,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-account
      .shop_table,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-address
      .shop_table,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .shop_table {
      border: none;
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__dashboard:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__orders
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__downloads
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-account
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .woocommerce-MyAccount-content-wrapper {
      background-color: var(--sections-background-color, #ffffff);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__dashboard:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-account
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .woocommerce-MyAccount-content-wrapper {
      padding: var(--sections-padding, 16px 30px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__orders
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__downloads
      .woocommerce-MyAccount-content-wrapper {
      padding: var(--sections-padding, 16px 30px 3px 30px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-address-fields,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      address {
      background-color: var(--sections-background-color, #ffffff);
      padding: var(--sections-padding, 16px 30px);
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-width: var(--sections-border-top-width, 1px)
        var(--sections-border-right-width, 1px)
        var(--sections-border-bottom-width, 1px)
        var(--sections-border-left-width, 1px);
      border-radius: var(--sections-border-radius, 3px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__view-order
      .woocommerce-MyAccount-content,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-address
      .woocommerce-MyAccount-content {
      border: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__view-order
      .woocommerce-MyAccount-content-wrapper,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__edit-address
      .woocommerce-MyAccount-content-wrapper {
      padding: 0;
      border: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__view-order
      .order_details {
      margin-bottom: 40px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__view-order
      .woocommerce-table--order-downloads,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__view-order
      .order_details {
      background-color: var(--sections-background-color, #ffffff);
      padding: var(--sections-padding, 16px 30px 3px 30px);
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-width: var(--sections-border-top-width, 1px)
        var(--sections-border-right-width, 1px)
        var(--sections-border-bottom-width, 1px)
        var(--sections-border-left-width, 1px);
      border-radius: var(--sections-border-radius, 3px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .woocommerce
      .woocommerce-MyAccount-content-wrapper
      .button {
      background: transparent;
      border-style: var(--tables-buttons-border-type, solid);
      border-color: var(--tables-buttons-border-color, #5bc0de);
      border-radius: var(--tables-button-border-radius, 3px);
      border-width: 2px;
      vertical-align: middle;
      color: var(--tables-button-normal-text-color, #69727d);
      padding: var(--tables-button-padding, 5px 10px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .woocommerce
      .woocommerce-MyAccount-content-wrapper
      .button:hover {
      color: var(--tables-button-hover-text-color, #69727d);
      transition-duration: var(--tables-button-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .woocommerce
      input[type='text'] {
      color: var(--forms-fields-normal-color, #69727d);
      border-radius: var(--forms-fields-border-radius, 0px);
      padding: var(--forms-fields-padding, 16px);
      background: #f9fafa;
      border: none;
      font-size: 14px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__payment-methods
      .woocommerce
      input[type='text']:focus {
      color: var(--forms-fields-focus-color, #69727d);
      border-color: #69727d;
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__add-payment-method
      #add_payment_method
      #payment {
      background-color: var(--sections-background-color, #ffffff);
      padding: var(--sections-padding, 16px 30px);
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-width: var(--sections-border-top-width, 1px)
        var(--sections-border-right-width, 1px)
        var(--sections-border-bottom-width, 1px)
        var(--sections-border-left-width, 1px);
      border-radius: var(--sections-border-radius, 3px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__add-payment-method
      #add_payment_method
      #payment
      .payment_methods {
      padding: 0;
      border-bottom-style: var(--tables-divider-border-type, solid);
      border-bottom-width: var(--tables-divider-border-width, 1px);
      border-bottom-color: var(--tables-divider-border-color, #d4d4d4);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__add-payment-method
      #add_payment_method
      #payment
      #place_order {
      background: #5bc0de;
      border: none;
      color: var(--forms-buttons-normal-text-color, #ffffff);
      border-radius: var(--forms-buttons-border-radius, 3px);
      padding: var(--forms-buttons-padding, 12px 32px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__add-payment-method
      #add_payment_method
      #payment
      #place_order:hover {
      color: var(--forms-buttons-hover-text-color, #ffffff);
      transition-duration: var(--forms-buttons-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom) {
      /* Make select2 appear like other inputs start */
      /* Make select2 appear like other inputs end */
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content
      > div
      > p {
      color: var(--general-text-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .select2-container--default {
      border-radius: var(--forms-fields-border-radius, 0px);
      background-color: var(--forms-fields-normal-background-color, #f9fafa);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .select2-container--default
      .select2-selection--single {
      color: var(--forms-fields-normal-color, #69727d);
      background-color: var(--forms-fields-normal-background-color, #f9fafa);
      border-radius: var(--forms-fields-border-radius, 0px);
      border: none;
      height: 45px;
      margin: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .select2-container--default
      .select2-selection--single:focus {
      color: var(--forms-fields-focus-color, #69727d);
      background-color: var(--forms-fields-focus-background-color, #f9fafa);
      border-color: initial;
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .select2-container--default
      .select2-selection--single
      .select2-selection__placeholder {
      color: var(--forms-fields-normal-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .select2-container--default
      .select2-selection--single
      .select2-selection__rendered {
      color: var(--forms-fields-normal-color, #69727d);
      line-height: 45px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .select2-container--default
      .select2-selection--single
      .select2-selection__arrow {
      height: 45px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .select2-container--open
      .select2-dropdown--below {
      background-color: var(--forms-fields-normal-background-color, #f9fafa);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      ::-moz-placeholder {
      color: var(--forms-fields-normal-color, inherit);
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      ::placeholder {
      color: var(--forms-fields-normal-color, inherit);
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-message {
      font-size: 14px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      address {
      color: var(--general-text-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      a {
      color: var(--links-normal-color, #5bc0de);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      a:hover {
      color: var(--links-hover-color, #5bc0de);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      a.button.alt:hover {
      background-color: initial;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      a.button:hover {
      background-color: initial;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      em {
      font-size: 12px;
      color: var(--login-messages-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .form-row {
      padding-left: var(--forms-columns-gap-padding-left, 0px);
      padding-right: var(--forms-columns-gap-padding-right, 0px);
      margin-left: var(--forms-columns-gap-margin-left, 0px);
      margin-right: var(--forms-columns-gap-margin-right, 0px);
      margin-bottom: var(--forms-rows-gap, 6px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .form-row
      label {
      color: var(--forms-labels-color, #69727d);
      margin-bottom: var(--forms-label-spacing, 0px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .form-row
      .input-text,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .form-row
      select {
      color: var(--forms-fields-normal-color, #69727d);
      border-radius: var(--forms-fields-border-radius, 0px);
      padding: var(--forms-fields-padding, 16px);
      background: #f9fafa;
      border: none;
      font-size: 14px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .form-row
      .input-text:focus,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .form-row
      select:focus {
      color: var(--forms-fields-focus-color, #69727d);
      border-color: #69727d;
      transition-duration: var(--forms-fields-focus-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      #billing_address_1_field {
      margin-bottom: 5px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .login
      .woocommerce-privacy-policy-text,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .register
      .woocommerce-privacy-policy-text {
      margin-bottom: 15px;
      color: var(--login-messages-color, #69727d);
      font-size: 12px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .login
      p:not([class]),
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .register
      p:not([class]) {
      color: var(--login-messages-color, #69727d);
      font-size: 12px;
      margin-top: 10px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .login
      .woocommerce-LostPassword,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .register
      .woocommerce-LostPassword {
      font-size: 12px;
      margin-bottom: 0px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-ResetPassword {
      width: 50%; /* 50% to match the design - it would normally be full width */
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-my-account
        .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
        .woocommerce
        .woocommerce-ResetPassword {
        width: 100%;
      }
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-ResetPassword
      p {
      color: var(--general-text-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-ResetPassword
      .form-row-first {
      width: 100%;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .login,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .register,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-ResetPassword {
      background-color: var(--sections-background-color, #ffffff);
      padding: var(--sections-padding, 16px 30px);
      border-style: var(--sections-border-type, solid);
      border-color: var(--sections-border-color, #d5d8dc);
      border-width: var(--sections-border-top-width, 1px)
        var(--sections-border-right-width, 1px)
        var(--sections-border-bottom-width, 1px)
        var(--sections-border-left-width, 1px);
      border-radius: var(--sections-border-radius, 3px);
      font-size: 14px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .login
      .button,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .register
      .button,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-ResetPassword
      .button {
      background: #5bc0de;
      border: none;
      color: var(--forms-buttons-normal-text-color, #ffffff);
      border-radius: var(--forms-buttons-border-radius, 3px);
      padding: var(--forms-buttons-padding, 12px 32px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .login
      .button:hover,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .register
      .button:hover,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-ResetPassword
      .button:hover {
      color: var(--forms-buttons-hover-text-color, #ffffff);
      transition-duration: var(--forms-buttons-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .login
      p:nth-child(3) {
      margin-top: 20px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-form__input-checkbox {
      vertical-align: middle;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      p:last-of-type {
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-form__label-for-checkbox
      span {
      color: var(--checkboxes-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      tr,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      td,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      th {
      border: none;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      h2,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      h3 {
      color: var(--typography-section-titles-color, #000000);
      margin-top: 0;
      margin-bottom: var(--section-title-spacing, 45px);
      font-weight: 400;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      strong {
      color: var(--general-text-color, #000000);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      strong.wc-item-meta-label {
      color: var(--variations-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .wc-item-meta {
      color: var(--variations-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .product-quantity {
      font-weight: 400;
      color: var(--tables-items-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .woocommerce-order-downloads {
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content-wrapper {
      font-size: 14px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content
      p:last-of-type {
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content
      h2:first-of-type {
      margin-top: 30px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-MyAccount-content
      mark {
      background-color: transparent;
      font-weight: bold;
      color: var(--general-text-color, #000000);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      caption
      + thead
      tr:first-child
      td,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      caption
      + thead
      tr:first-child
      th,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      colgroup
      + thead
      tr:first-child
      td,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      colgroup
      + thead
      tr:first-child
      th {
      border-top: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      thead:first-child
      tr:first-child
      td,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      thead:first-child
      tr:first-child
      th {
      border-top: 0;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: var(--tables-titles-spacing, 9px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      tbody
      > tr:nth-child(odd)
      > td,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      tbody
      > tr:nth-child(odd)
      > th,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      tbody
      > tr:nth-child(even)
      > td,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      tbody
      > tr:nth-child(even)
      > th {
      background-color: transparent;
      padding-left: 0;
      padding-top: var(--order-summary-rows-gap-top, 9px);
      padding-bottom: var(--order-summary-rows-gap-bottom, 9px);
      color: var(--tables-items-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      tbody
      .woocommerce-orders-table__cell.woocommerce-orders-table__cell-order-number
      > a {
      color: var(--tables-links-normal-color, #5bc0de);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      tbody
      .woocommerce-orders-table__cell.woocommerce-orders-table__cell-order-number
      > a:hover {
      color: var(--tables-links-hover-color, #5bc0de);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .shop_table {
      font-size: 14px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .shop_table
      .button {
      background: transparent;
      border-style: var(--tables-buttons-border-type, solid);
      border-color: var(--tables-buttons-border-color, #5bc0de);
      border-radius: var(--tables-button-border-radius, 3px);
      border-width: 2px;
      vertical-align: middle;
      color: var(--tables-button-normal-text-color, #69727d);
      padding: var(--tables-button-padding, 5px 10px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .shop_table
      .button:hover {
      transition-duration: var(--tables-button-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .shop_table
      td,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .shop_table
      th {
      border-top-style: var(--tables-divider-border-type, solid);
      border-top-width: var(--tables-divider-border-width, 1px);
      border-top-color: var(--tables-divider-border-color, #d5d8dc);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .shop_table
      thead
      tr
      th {
      color: var(--tables-title-color, #000000);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .shop_table
      thead
      tr
      th
      span {
      color: var(--tables-title-color, #000000);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order_details
      tfoot
      th,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order_details
      tfoot
      td {
      padding-left: 0;
      color: var(--tables-title-color, #000000);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order_details
      .download-product
      a,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order_details
      .product-name
      a {
      color: var(--tables-links-normal-color, #5bc0de);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order_details
      .download-product
      a:hover,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order_details
      .product-name
      a:hover {
      color: var(--tables-links-hover-color, #5bc0de);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order_details
      .product-purchase-note
      td {
      border-top: none;
      padding-top: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order-again
      .button {
      background: transparent;
      border: 2px solid #5bc0de;
      border-style: var(--tables-buttons-border-type, solid);
      border-color: var(--tables-buttons-border-color, #5bc0de);
      border-width: 2px;
      border-radius: var(--tables-button-border-radius, 3px);
      vertical-align: middle;
      color: var(--tables-button-normal-text-color, #69727d);
      margin-top: 0;
      margin-bottom: 0;
      padding: var(--tables-button-padding, 12px 32px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .order-again
      .button:hover {
      transition-duration: var(--tables-button-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-Address
      .title
      h3,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-Addresses
      .title
      h3 {
      float: none;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-Address
      address,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-Addresses
      address {
      padding: var(--sections-padding, 45px 30px 16px 30px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-Address
      .edit,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-Addresses
      .edit {
      float: left;
      position: absolute;
      margin-left: var(--edit-link-margin-start, 30px);
      margin-top: var(--edit-link-margin-top, 10px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce
      .u-columns {
      margin-top: 20px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-EditAccountForm
      fieldset,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-address-fields
      fieldset {
      border: none;
      padding: 0;
      margin-inline-start: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-EditAccountForm
      fieldset
      legend,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-address-fields
      fieldset
      legend {
      font-weight: 700;
      padding: 20px 0;
      color: var(--general-text-color, #000000);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-EditAccountForm
      .button,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-address-fields
      .button {
      background: #5bc0de;
      border: none;
      color: var(--forms-buttons-normal-text-color, #ffffff);
      border-radius: var(--forms-buttons-border-radius, 3px);
      padding: var(--forms-buttons-padding, 12px 32px);
      margin-top: 20px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-EditAccountForm
      .button:hover,
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-address-fields
      .button:hover {
      color: var(--forms-buttons-hover-text-color, #ffffff);
      transition-duration: var(--forms-buttons-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-pagination {
      padding: 16px 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-pagination
      .button {
      background: transparent;
      border-style: var(--tables-buttons-border-type, solid);
      border-color: var(--tables-buttons-border-color, #5bc0de);
      border-radius: var(--tables-button-border-radius, 3px);
      border-width: 2px;
      vertical-align: middle;
      color: var(--tables-button-normal-text-color, #69727d);
      padding: var(--tables-button-padding, 5px 10px);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-pagination
      .button:hover {
      transition-duration: var(--tables-button-hover-transition-duration, 0.3s);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab:not(.e-my-account-tab__dashboard--custom)
      .woocommerce-OrderUpdates {
      color: var(--general-text-color, #69727d);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation {
      float: left;
      width: 25%; /* 25% to match the design */
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-my-account
        .e-my-account-tab
        .woocommerce
        .woocommerce-MyAccount-navigation {
        width: 100%;
      }
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul {
      padding-inline-start: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li {
      list-style-type: none;
      display: inline-block;
      width: var(--tab-width, 100%);
      /* add a Divider between the tabs that is outside of the margins */
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li::after {
      content: '';
      display: block;
      width: 100%;
      background-color: var(--tabs-divider-color, #69727d);
      height: var(--tabs-divider-weight, 0px);
      position: relative;
      top: calc(var(--tabs-spacing, 2px) / 2);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li.is-active
      a {
      color: var(--tabs-active-color, #5bc0de);
      background: #f1f2f3;
      border-color: var(--tabs-active-border-color, transparent);
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li
      a {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: var(--tabs-normal-color, #69727d);
      display: block;
      padding: var(--tabs-padding, 12px 20px);
      text-align: var(--tabs-alignment, start);
      background: #f9fafa;
      border-radius: var(--tabs-border-radius, 0px);
      border-style: var(--tabs-border-type, solid);
      border-color: var(--tabs-border-color, transparent);
      border-width: 0px;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li
      a:hover {
      color: var(--tabs-hover-color, #5bc0de);
      border-color: var(--tabs-hover-border-color, transparent);
    }
    @media (max-width: 1024px) {
      .elementor-widget-woocommerce-my-account
        .e-my-account-tab
        .woocommerce
        .woocommerce-MyAccount-navigation
        ul
        li
        a {
        padding: var(--tabs-padding, 10px);
      }
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li.woocommerce-MyAccount-navigation-link--customer-logout {
      margin-bottom: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li.woocommerce-MyAccount-navigation-link--customer-logout::after {
      display: none;
    }
    .elementor-widget-woocommerce-my-account
      .woocommerce-table__line-item.order_item
      .woocommerce-table__product-name.product-purchase-note-is-below {
      padding-bottom: 0;
    }
    .elementor-widget-woocommerce-my-account
      .woocommerce-table__line-item.order_item
      .woocommerce-table__product-total.product-total.product-purchase-note-is-below {
      padding-bottom: 0;
    }
    .elementor-widget-woocommerce-my-account
      .e-my-account-tab__view-order
      .shop_table
      td {
      vertical-align: top;
      line-height: unset;
    }
    .elementor-widget-woocommerce-my-account
      .woocommerce-MyAccount-paymentMethods
      .woocommerce-PaymentMethod--actions {
      text-align: right;
    }
    .elementor-widget-woocommerce-my-account
      .woocommerce-PaymentMethod
      .input-radio {
      vertical-align: middle;
    }
    .elementor-widget-woocommerce-my-account
      .woocommerce-PaymentMethod
      .input-radio
      + label {
      color: var(--payment-methods-radio-buttons-color, #69727d);
    }

    .e-wc-account-tabs-nav .woocommerce-MyAccount-navigation ul li {
      display: inline-block;
    }
    .e-my-account-tabs-vertical .woocommerce-MyAccount-navigation {
      display: block;
    }
    .e-my-account-tabs-vertical .woocommerce-MyAccount-navigation li {
      margin: calc(var(--tabs-spacing, 2px) / 2) 0
        calc(var(--tabs-spacing, 2px) / 2) 0;
    }
    .e-my-account-tabs-vertical
      .woocommerce-MyAccount-navigation
      li.woocommerce-MyAccount-navigation-link--dashboard {
      margin-top: 0;
    }
    .e-my-account-tabs-vertical
      .e-wc-account-tabs-nav
      .woocommerce-MyAccount-navigation {
      display: none;
    }
    .e-my-account-tabs-horizontal
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation {
      float: none;
      width: 100%;
    }
    .e-my-account-tabs-horizontal
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-navigation
      ul
      li::after {
      display: none;
    }
    .e-my-account-tabs-horizontal
      .e-my-account-tab
      .woocommerce
      .woocommerce-MyAccount-content {
      float: none;
      width: 100%;
      padding: var(--tab-content-spacing, 50px) 0 0 0;
    }
    @media (max-width: 1024px) {
      .e-my-account-tabs-horizontal
        .e-my-account-tab
        .woocommerce
        .woocommerce-MyAccount-content {
        width: 100%;
        padding: var(--tab-content-spacing, 6%) 0 0 0;
      }
    }
    .e-my-account-tabs-horizontal .woocommerce-MyAccount-navigation {
      display: none;
    }
    .e-my-account-tabs-horizontal
      .e-wc-account-tabs-nav
      .woocommerce-MyAccount-navigation {
      display: block;
    }
    .e-my-account-tabs-horizontal
      .e-wc-account-tabs-nav
      .woocommerce-MyAccount-navigation
      ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: var(--tabs-container-justify-content, space-between);
    }
    @media (max-width: 767px) {
      .e-my-account-tabs-horizontal
        .e-wc-account-tabs-nav
        .woocommerce-MyAccount-navigation
        ul {
        display: block;
      }
    }
    .e-my-account-tabs-horizontal
      .e-wc-account-tabs-nav
      .woocommerce-MyAccount-navigation
      ul
      li {
      display: inline-block;
      width: var(--tab-width, 100%);
      padding: 0 calc(var(--tabs-spacing, 2px) / 2) 0
        calc(var(--tabs-spacing, 2px) / 2);
      margin: 0;
      border-right-color: var(--tabs-divider-color, #69727d);
      border-right-width: var(--tabs-divider-weight, 0px);
      border-right-style: solid;
    }
    .e-my-account-tabs-horizontal
      .e-wc-account-tabs-nav
      .woocommerce-MyAccount-navigation
      ul
      li:first-of-type {
      padding-left: 0;
    }
    .e-my-account-tabs-horizontal
      .e-wc-account-tabs-nav
      .woocommerce-MyAccount-navigation
      ul
      li:last-of-type {
      padding-right: 0;
      border-right-width: 0;
    }
    @media (max-width: 767px) {
      .e-my-account-tabs-horizontal
        .e-wc-account-tabs-nav
        .woocommerce-MyAccount-navigation
        ul
        li {
        margin: calc(var(--tabs-spacing, 2px) / 2) 0
          calc(var(--tabs-spacing, 2px) / 2) 0;
        display: inline-block;
        width: 100%;
      }
    }

    /**
 * Specific styling in the Editor.
 */
    .elementor-editor-active,
    .elementor-editor-preview {
      /**
   * Disable all but the first 'View' order button as
   * this is the only order loaded for preview purposes.
   */
    }
    .elementor-editor-active
      tr:not(:first-child)
      .woocommerce-orders-table__cell-order-number
      a,
    .elementor-editor-preview
      tr:not(:first-child)
      .woocommerce-orders-table__cell-order-number
      a {
      pointer-events: none;
    }
    .elementor-editor-active
      tr:not(:first-child)
      .woocommerce-orders-table__cell-order-actions
      .button,
    .elementor-editor-preview
      tr:not(:first-child)
      .woocommerce-orders-table__cell-order-actions
      .button {
      pointer-events: none;
      opacity: 0.3;
    }

    /**
 * Hide all the other My Account pages' content in the Editor except the first one - dashboard page
 */
    .elementor-editor-active
      .elementor-widget-woocommerce-my-account
      .woocommerce-MyAccount-content:not(:first-of-type) {
      display: none;
    }

    .product .count {
      background-color: inherit;
    }

    .elementor-widget-woocommerce-product-additional-information:not(.elementor-show-heading-yes)
      h2 {
      display: none;
    }

    .elementor-widget-woocommerce-notices
      .e-woocommerce-notices-wrapper.e-woocommerce-notices-wrapper-loading
      * {
      display: none;
    }
    .elementor-widget-woocommerce-notices .woocommerce-notices-wrapper,
    .elementor-widget-woocommerce-notices .woocommerce-message,
    .elementor-widget-woocommerce-notices .woocommerce-error,
    .elementor-widget-woocommerce-notices .woocommerce-info {
      display: block;
    }
    .e-preview--show-hidden-elements:not(.elementor-editor-active)
      .elementor-widget-woocommerce-notices
      .e-notices-demo-notice {
      display: none;
    }

    .woocommerce div.product.elementor form.cart div.product-addon {
      flex-basis: 100%;
      flex-shrink: 0;
    }

    .e-sticky-right-column--active {
      position: sticky;
      top: 0;
    }

    .elementor-post-navigation-borders-yes
      .elementor-post-navigation.elementor-grid {
      color: #d5d8dc;
      border: none;
      border-top: 1px solid;
      border-bottom: 1px solid;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .elementor-post-navigation-borders-yes
      .elementor-post-navigation__separator {
      height: 100%;
      width: 1px;
      margin: 0 auto;
      background-color: #d5d8dc;
    }

    .elementor-post-navigation {
      overflow: hidden;
      display: flex;
    }
    .elementor-post-navigation .post-navigation__arrow-wrapper {
      color: #d5d8dc;
    }
    .elementor-post-navigation
      .post-navigation__arrow-wrapper.post-navigation__arrow-prev {
      font-size: 30px;
      padding-right: 15px;
    }
    .elementor-post-navigation
      .post-navigation__arrow-wrapper.post-navigation__arrow-next {
      font-size: 30px;
      padding-left: 15px;
    }
    .elementor-post-navigation .post-navigation__arrow-wrapper i {
      transform: translateY(-5%);
    }
    .elementor-post-navigation .elementor-post-navigation__link__prev,
    .elementor-post-navigation .elementor-post-navigation__link__next {
      overflow: hidden;
    }
    .elementor-post-navigation .elementor-post-navigation__link a {
      display: flex;
      align-items: center;
      max-width: 100%;
    }
    .elementor-post-navigation .post-navigation__prev--label,
    .elementor-post-navigation .post-navigation__next--label {
      text-transform: uppercase;
      font-size: 0.8em;
    }
    .elementor-post-navigation .post-navigation__prev--title,
    .elementor-post-navigation .post-navigation__next--title {
      font-size: 0.7em;
    }
    .elementor-post-navigation .post-navigation__prev--label,
    .elementor-post-navigation .post-navigation__prev--title,
    .elementor-post-navigation .post-navigation__next--label,
    .elementor-post-navigation .post-navigation__next--title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .elementor-post-navigation span.elementor-post-navigation__link__next {
      text-align: right;
    }
    .elementor-post-navigation span.elementor-post-navigation__link__next,
    .elementor-post-navigation span.elementor-post-navigation__link__prev {
      display: flex;
      flex-direction: column;
    }

    .elementor-post-navigation .elementor-grid {
      justify-content: space-between;
    }
    .elementor-post-navigation .elementor-post-navigation__link {
      width: calc(50% - 1px / 2);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .elementor-post-navigation .elementor-post-navigation__separator-wrapper {
      text-align: center;
    }
    .elementor-post-navigation .elementor-post-navigation__next {
      text-align: right;
    }
    .elementor-post-navigation .elementor-post-navigation__next a {
      justify-content: flex-end;
    }

    .elementor-search-form {
      display: block;
      transition: 0.2s;
      /*------ Reset ------*/
    }
    .elementor-search-form .e-font-icon-svg-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .elementor-search-form input[type='search'],
    .elementor-search-form button {
      margin: 0;
      border: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      background: none;
      line-height: 1;
      min-width: 0;
      font-size: 15px;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    .elementor-search-form input[type='search']:focus,
    .elementor-search-form button:focus {
      outline: 0;
      color: inherit;
    }
    .elementor-search-form button {
      background-color: #69727d;
      font-size: var(--e-search-form-submit-icon-size, 16px);
      color: #fff;
      border-radius: 0;
    }
    .elementor-search-form__container {
      display: flex;
      transition: 0.2s;
      overflow: hidden;
      border: 0 solid transparent;
      min-height: 50px;
    }
    .elementor-search-form__container:not(.elementor-search-form--full-screen) {
      background: #f1f2f3;
    }
    .elementor-search-form__input {
      flex-basis: 100%;
      color: #3f444b;
      transition: color 0.2s;
    }
    .elementor-search-form__input::-moz-placeholder {
      color: inherit;
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-search-form__input::placeholder {
      color: inherit;
      font-family: inherit;
      opacity: 0.6;
    }
    .elementor-search-form__submit {
      transition: color 0.2s, background 0.2s;
      font-size: var(--e-search-form-submit-icon-size, 16px);
    }
    .elementor-search-form__submit svg {
      fill: var(--e-search-form-submit-text-color, #fff);
      width: var(--e-search-form-submit-icon-size, 16px);
      height: var(--e-search-form-submit-icon-size, 16px);
    }
    .elementor-search-form .elementor-search-form__submit,
    .elementor-search-form .elementor-search-form__submit:hover {
      color: var(--e-search-form-submit-text-color, #fff);
      border: none;
      border-radius: 0;
    }
    .elementor-search-form--button-type-text .elementor-search-form__submit {
      flex-shrink: 0;
    }
    .elementor-search-form--skin-minimal .elementor-search-form__icon {
      opacity: 0.6;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #3f444b;
      fill: #3f444b;
      font-size: var(--e-search-form-icon-size-minimal, 15px);
    }
    .elementor-search-form--skin-minimal .elementor-search-form__icon svg {
      width: var(--e-search-form-icon-size-minimal, 15px);
      height: var(--e-search-form-icon-size-minimal, 15px);
    }
    .elementor-search-form--skin-minimal:focus-within {
      outline-width: 2px;
      outline-style: solid;
    }
    .elementor-search-form--skin-full_screen
      .elementor-search-form
      input[type='search'].elementor-search-form__input {
      font-size: 50px;
      text-align: center;
      border-style: solid;
      border-width: 0 0 1px 0;
      border-color: #fff;
      line-height: 1.5;
      color: #fff;
    }
    .elementor-search-form--skin-full_screen .elementor-search-form__toggle {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      color: var(--e-search-form-toggle-color, #33373d);
      font-size: var(--e-search-form-toggle-size, 33px);
    }
    .elementor-search-form--skin-full_screen .elementor-search-form__toggle i,
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      .e-font-icon-svg-container {
      position: relative;
      display: block;
      transition: 0.2s;
      width: var(--e-search-form-toggle-size, 33px);
      height: var(--e-search-form-toggle-size, 33px);
      background-color: var(
        --e-search-form-toggle-background-color,
        rgba(0, 0, 0, 0.05)
      );
      border-style: solid;
      border-color: var(--e-search-form-toggle-color, #33373d);
      border-width: var(--e-search-form-toggle-border-width, 0);
      border-radius: var(--e-search-form-toggle-border-radius, 3px);
    }
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      i:before,
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      i
      svg,
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      .e-font-icon-svg-container:before,
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      .e-font-icon-svg-container
      svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      i:before,
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      .e-font-icon-svg-container:before {
      font-size: var(--e-search-form-toggle-icon-size, 0.55em);
    }
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      i
      svg,
    .elementor-search-form--skin-full_screen
      .elementor-search-form__toggle
      .e-font-icon-svg-container
      svg {
      fill: var(--e-search-form-toggle-color, #33373d);
      width: var(--e-search-form-toggle-icon-size, 0.55em);
      height: var(--e-search-form-toggle-icon-size, 0.55em);
    }
    .elementor-search-form--skin-full_screen .elementor-search-form__container {
      transition: 0.3s;
      align-items: center;
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      padding: 0 15%;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .elementor-search-form--skin-full_screen
      .elementor-search-form__container:not(.elementor-search-form--full-screen) {
      overflow: hidden;
      opacity: 0;
      transform: scale(0);
    }
    .elementor-search-form--skin-full_screen
      .elementor-search-form__container:not(.elementor-search-form--full-screen)
      .dialog-lightbox-close-button {
      display: none;
    }

    .elementor-author-box {
      display: flex;
      align-items: center;
    }
    .elementor-author-box__avatar {
      flex-shrink: 0;
      margin-right: 25px;
    }
    .elementor-author-box__avatar img {
      width: 100px;
      border-radius: 500px;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .elementor-author-box__text {
      flex-grow: 1;
      font-size: 17px;
    }
    .elementor-author-box__name {
      font-size: 24px;
      margin-bottom: 5px;
    }
    .elementor-author-box__bio {
      margin-bottom: 0.8em;
    }
    .elementor-author-box__bio p {
      margin-bottom: 0.8em;
    }
    .elementor-author-box__bio p:last-of-type {
      margin-bottom: 0;
    }
    .elementor-author-box--layout-image-left .elementor-author-box {
      flex-direction: row;
    }
    .elementor-author-box--layout-image-left .elementor-author-box__avatar {
      margin-left: 0;
      margin-right: 25px;
    }
    .elementor-author-box--layout-image-right .elementor-author-box {
      flex-direction: row-reverse;
    }
    .elementor-author-box--layout-image-right .elementor-author-box__avatar {
      margin-right: 0;
      margin-left: 25px;
    }
    .elementor-author-box--layout-image-above .elementor-author-box {
      display: block;
    }
    .elementor-author-box--layout-image-above .elementor-author-box__avatar {
      margin: 0 auto 15px;
      display: inline-block;
    }
    .elementor-author-box--layout-image-above.elementor-author-box--align-left
      .elementor-author-box__avatar {
      margin-left: 0;
    }
    .elementor-author-box--layout-image-above.elementor-author-box--align-right
      .elementor-author-box__avatar {
      margin-right: 0;
    }
    .elementor-author-box--align-left .elementor-author-box {
      text-align: left;
    }
    .elementor-author-box--align-right .elementor-author-box {
      text-align: right;
    }
    .elementor-author-box--align-center .elementor-author-box {
      text-align: center;
    }

    .elementor-button.elementor-author-box__button {
      border: solid 2px #3f444b;
      font-size: 15px;
      font-weight: 100;
      padding: 10px 18px;
    }
    .elementor-button.elementor-author-box__button,
    .elementor-button.elementor-author-box__button:hover {
      background: transparent;
      color: #3f444b;
    }

    .elementor-widget-author-box:not(.elementor-author-box--layout-image-above)
      .elementor-author-box__avatar {
      align-self: flex-start;
    }
    .elementor-widget-author-box:not(.elementor-author-box--layout-image-above).elementor-author-box--image-valign-top
      .elementor-author-box__avatar {
      align-self: flex-start;
    }
    .elementor-widget-author-box:not(.elementor-author-box--layout-image-above).elementor-author-box--image-valign-middle
      .elementor-author-box__avatar {
      align-self: center;
    }

    .elementor-post-info__terms-list {
      display: inline-block;
    }
    .elementor-post-info .elementor-icon-list-icon .elementor-avatar {
      border-radius: 100%;
    }

    .elementor-widget-post-info.elementor-align-center
      .elementor-icon-list-item:after {
      margin: initial;
    }

    .elementor-icon-list-items
      .elementor-icon-list-item
      .elementor-icon-list-text {
      display: inline-block;
    }
    .elementor-icon-list-items
      .elementor-icon-list-item
      .elementor-icon-list-text
      a,
    .elementor-icon-list-items
      .elementor-icon-list-item
      .elementor-icon-list-text
      span {
      display: inline;
    }

    .elementor-widget-breadcrumbs {
      font-size: 0.85em;
    }
    .elementor-widget-breadcrumbs p {
      margin-bottom: 0;
    }

    .elementor-sitemap-wrap {
      display: flex;
      flex-flow: row wrap;
    }

    .elementor-widget-posts:after,
    .elementor-widget-archive-posts:after {
      display: none;
    }

    .elementor-posts-container.elementor-posts-masonry {
      align-items: flex-start;
    }
    .elementor-posts-container:not(.elementor-posts-masonry) {
      align-items: stretch;
    }
    .elementor-posts-container .elementor-post {
      padding: 0;
      margin: 0;
    }
    .elementor-posts-container .elementor-post__excerpt {
      flex-grow: var(--read-more-alignment, 0);
    }
    .elementor-posts-container .elementor-post__thumbnail {
      overflow: hidden;
    }
    .elementor-posts-container .elementor-post__thumbnail img {
      display: block;
      width: 100%;
      max-height: none;
      max-width: none;
      transition: filter 0.3s;
    }
    .elementor-posts-container .elementor-post__thumbnail__link {
      position: relative;
      display: block;
      width: 100%;
    }
    .elementor-posts-container.elementor-has-item-ratio
      .elementor-post__thumbnail {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .elementor-posts-container.elementor-has-item-ratio
      .elementor-post__thumbnail
      img {
      height: auto;
      position: absolute;
      top: calc(50% + 1px);
      left: calc(50% + 1px);
      transform: scale(1.01) translate(-50%, -50%);
    }
    .elementor-posts-container.elementor-has-item-ratio
      .elementor-post__thumbnail.elementor-fit-height
      img {
      height: 100%;
      width: auto;
    }

    .elementor-posts .elementor-post {
      display: flex;
      flex-direction: column;
      transition-property: background, border, box-shadow;
      transition-duration: 0.25s;
    }
    .elementor-posts .elementor-post__title {
      font-size: 18px;
      margin: 0;
    }
    .elementor-posts .elementor-post__excerpt {
      margin-bottom: 10px;
    }
    .elementor-posts .elementor-post__excerpt p {
      margin: 0;
      line-height: 1.5em;
      font-size: 14px;
      color: #777;
    }
    .elementor-posts .elementor-post__text {
      display: var(--item-display, block);
      flex-direction: column;
      flex-grow: 1;
    }
    .elementor-posts .elementor-post__meta-data {
      line-height: 1.3em;
      font-size: 12px;
      margin-bottom: 13px;
      color: #adadad;
    }
    .elementor-posts .elementor-post__read-more {
      font-size: 12px;
      font-weight: bold;
      align-self: flex-start;
    }
    .elementor-posts .elementor-post__thumbnail {
      position: relative;
    }
    .elementor-posts--skin-classic .elementor-post {
      overflow: hidden;
    }
    .elementor-posts--align-left .elementor-post {
      text-align: left;
    }
    .elementor-posts--align-right .elementor-post {
      text-align: right;
    }
    .elementor-posts--align-center .elementor-post {
      text-align: center;
    }
    .elementor-posts--thumbnail-top .elementor-post__thumbnail__link {
      margin-bottom: 20px;
    }
    .elementor-posts--thumbnail-top .elementor-post__text {
      width: 100%;
    }
    .elementor-posts--thumbnail-top.elementor-posts--align-left
      .elementor-post__thumbnail__link {
      margin-right: auto;
    }
    .elementor-posts--thumbnail-top.elementor-posts--align-right
      .elementor-post__thumbnail__link {
      margin-left: auto;
    }
    .elementor-posts--thumbnail-top.elementor-posts--align-center
      .elementor-post__thumbnail__link {
      margin-right: auto;
      margin-left: auto;
    }
    .elementor-posts--thumbnail-left .elementor-post,
    .elementor-posts--thumbnail-right .elementor-post {
      flex-direction: row;
    }
    .elementor-posts--thumbnail-left .elementor-post__thumbnail__link,
    .elementor-posts--thumbnail-right .elementor-post__thumbnail__link {
      flex-shrink: 0;
      width: 25%;
    }
    .elementor-posts--thumbnail-left .elementor-post__thumbnail__link {
      order: 0;
      margin-right: 20px;
    }
    .elementor-posts--thumbnail-right .elementor-post__thumbnail__link {
      order: 5;
      margin-left: 20px;
    }
    .elementor-posts--thumbnail-none
      .elementor-posts-container
      .elementor-post__thumbnail__link {
      display: none;
    }

    .elementor-posts .elementor-post {
      display: flex;
    }
    .elementor-posts .elementor-post__card {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      width: 100%;
      min-height: 100%;
      border-radius: 3px;
      border: 0 solid #69727d;
      transition: all 0.25s;
    }
    .elementor-posts .elementor-post__card .elementor-post__thumbnail {
      position: relative;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
    }
    .elementor-posts .elementor-post__card .elementor-post__thumbnail img {
      width: calc(100% + 1px);
    }
    .elementor-posts .elementor-post__card .elementor-post__text {
      padding: 0 30px;
      margin-bottom: 0;
      margin-top: 20px;
    }
    .elementor-posts .elementor-post__card .elementor-post__read-more {
      margin-bottom: 20px;
      display: inline-block;
    }
    .elementor-posts .elementor-post__card .elementor-post__meta-data {
      padding: 15px 30px;
      margin-bottom: 0;
      border-top: 1px solid #eaeaea;
    }
    .elementor-posts
      .elementor-post__card
      .elementor-post__meta-data
      span
      + span:before {
      margin: 0 5px;
    }
    .elementor-posts .elementor-post__card .elementor-post__title {
      font-size: 21px;
    }
    .elementor-posts .elementor-post__card .elementor-post__excerpt {
      line-height: 1.7;
    }
    .elementor-posts .elementor-post__card .elementor-post__excerpt,
    .elementor-posts .elementor-post__card .elementor-post__title {
      margin-bottom: 25px;
    }
    .elementor-posts .elementor-post__card .elementor-post__badge,
    .elementor-posts .elementor-post__card .elementor-post__read-more {
      text-transform: uppercase;
    }
    .elementor-posts .elementor-post__badge {
      position: absolute;
      top: 0;
      background: #69727d;
      color: #fff;
      font-size: 12px;
      padding: 0.6em 1.2em;
      line-height: 1;
      font-weight: 400;
      margin: 20px;
      border-radius: 999px;
    }
    .elementor-posts .elementor-post__avatar {
      position: relative;
      padding: 0 30px;
      width: 100%;
      top: -25px;
      height: 0;
      pointer-events: none;
    }
    .elementor-posts .elementor-post__avatar img {
      border-radius: 50%;
      width: 60px;
      pointer-events: all;
      transform: translateY(-50%);
    }
    .elementor-posts--thumbnail-top
      .elementor-posts--skin-cards:not(.elementor-posts-masonry)
      .elementor-post__meta-data,
    .elementor-posts
      .elementor-posts--skin-cards:not(.elementor-posts-masonry)
      .elementor-post__meta-data {
      margin-top: auto;
    }
    .elementor-posts--thumbnail-none
      .elementor-post__card
      .elementor-post__avatar {
      padding-top: 0;
      position: static;
      height: auto;
    }
    .elementor-posts--thumbnail-none
      .elementor-post__card
      .elementor-post__avatar
      img.avatar {
      transform: translateY(0);
      vertical-align: bottom;
    }
    .elementor-posts--show-avatar .elementor-post__thumbnail__link {
      margin-bottom: 25px;
    }

    .elementor-posts__hover-gradient
      .elementor-post__card
      .elementor-post__thumbnail__link:after {
      display: block;
      content: '';
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(0, 0, 0, 0) 75%
      );
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      opacity: 1;
      transition: all 0.3s ease-out;
    }
    .elementor-posts__hover-gradient
      .elementor-post__card:hover
      .elementor-post__thumbnail__link:after {
      opacity: 0.5;
    }
    .elementor-posts__hover-zoom-in
      .elementor-post__card
      .elementor-post__thumbnail.elementor-fit-height
      img {
      height: 100%;
    }
    .elementor-posts__hover-zoom-in
      .elementor-post__card
      .elementor-post__thumbnail:not(.elementor-fit-height)
      img {
      width: calc(100% + 1px);
    }
    .elementor-posts__hover-zoom-in
      .elementor-post__card:hover
      .elementor-post__thumbnail.elementor-fit-height
      img {
      height: 115%;
    }
    .elementor-posts__hover-zoom-in
      .elementor-post__card:hover
      .elementor-post__thumbnail:not(.elementor-fit-height)
      img {
      width: 115%;
    }
    .elementor-posts__hover-zoom-out
      .elementor-post__card
      .elementor-post__thumbnail.elementor-fit-height
      img {
      height: 115%;
    }
    .elementor-posts__hover-zoom-out
      .elementor-post__card
      .elementor-post__thumbnail:not(.elementor-fit-height)
      img {
      width: 115%;
    }
    .elementor-posts__hover-zoom-out
      .elementor-post__card:hover
      .elementor-post__thumbnail.elementor-fit-height
      img {
      height: 100%;
    }
    .elementor-posts__hover-zoom-out
      .elementor-post__card:hover
      .elementor-post__thumbnail:not(.elementor-fit-height)
      img {
      width: calc(100% + 1px);
    }
    .elementor-posts__hover-zoom-out .elementor-post__thumbnail img,
    .elementor-posts__hover-zoom-in .elementor-post__thumbnail img {
      transition: filter 0.3s, height 1s cubic-bezier(0, 0.25, 0.07, 1),
        width 1s cubic-bezier(0, 0.25, 0.07, 1);
    }

    .elementor-card-shadow-yes .elementor-post__card {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    }
    .elementor-card-shadow-yes .elementor-post__card:hover {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
    }

    .elementor-posts--skin-full_content article {
      flex-direction: column;
      overflow: hidden;
    }
    .elementor-posts--skin-full_content article .elementor-post__thumbnail {
      padding-bottom: 0;
    }

    body.elementor-editor-active .elementor-posts--skin-full_content,
    body.elementor-editor-active .elementor-posts--skin-archive_full_content {
      pointer-events: none;
    }
    body.elementor-editor-active
      .elementor-posts--skin-full_content
      .elementor-post__thumbnail__link,
    body.elementor-editor-active
      .elementor-posts--skin-archive_full_content
      .elementor-post__thumbnail__link {
      display: none;
    }
    body.elementor-editor-active
      .elementor-posts--show-thumbnail
      .elementor-post__thumbnail__link {
      display: block;
    }

    .elementor-portfolio__filters {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
    .elementor-portfolio__filter {
      margin: 0;
      cursor: pointer;
      padding: 8px;
    }
    .elementor-portfolio-item {
      align-self: flex-start;
      transition-property: transform, opacity;
      transition-timing-function: ease-in-out;
      transform-style: preserve-3d;
      overflow: hidden;
      margin: 0;
    }
    .elementor-portfolio-item__title {
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 18px;
      color: #fff;
    }
    .elementor-portfolio-item__tags__separator:before {
      content: ', ';
    }
    .elementor-portfolio-item__overlay {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 15px;
      transition: opacity 1s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      overflow: hidden;
      background-color: rgba(105, 114, 125, 0.8);
    }
    .elementor-portfolio-item__overlay:hover {
      opacity: 1;
      transition: opacity 0.5s;
    }
    .elementor-portfolio-item__img img[src=''] {
      background-color: rgba(0, 0, 0, 0.1);
      height: 100%;
    }
    .elementor-portfolio.elementor-has-item-ratio {
      transition: height 0.5s;
    }
    .elementor-portfolio.elementor-has-item-ratio .elementor-post__thumbnail {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .elementor-portfolio.elementor-has-item-ratio
      .elementor-post__thumbnail__link {
      padding-bottom: 56.25%;
    }
    .elementor ul li.elementor-portfolio__filter {
      list-style-type: none;
    }

    .e-con > .elementor-widget-portfolio,
    .e-con-inner > .elementor-widget-portfolio {
      width: var(--container-widget-width);
      --flex-grow: var(--container-widget-flex-grow);
    }

    .elementor-widget-posts.load-more-align-center .elementor-button-wrapper,
    .elementor-widget-archive-posts.load-more-align-center
      .elementor-button-wrapper {
      text-align: center;
    }
    .elementor-widget-posts.load-more-align-start .elementor-button-wrapper,
    .elementor-widget-archive-posts.load-more-align-start
      .elementor-button-wrapper {
      text-align: start;
    }
    .elementor-widget-posts.load-more-align-end .elementor-button-wrapper,
    .elementor-widget-archive-posts.load-more-align-end
      .elementor-button-wrapper {
      text-align: end;
    }
    .elementor-widget-posts.load-more-align-justify .elementor-button,
    .elementor-widget-archive-posts.load-more-align-justify .elementor-button {
      width: 100%;
    }
    .elementor-widget-posts.e-load-more-pagination-loading
      > .elementor-widget-container,
    .elementor-widget-archive-posts.e-load-more-pagination-loading
      > .elementor-widget-container {
      cursor: default;
    }
    .elementor-widget-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner,
    .elementor-widget-archive-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner {
      margin-top: var(--load-more—spacing, 30px);
    }
    .elementor-widget-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner
      i,
    .elementor-widget-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner
      svg,
    .elementor-widget-archive-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner
      i,
    .elementor-widget-archive-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner
      svg {
      display: flex;
    }
    .elementor-widget-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button-content-wrapper,
    .elementor-widget-archive-posts.e-load-more-pagination-loading
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button-content-wrapper {
      visibility: hidden;
    }
    .elementor-widget-posts.e-load-more-pagination-end
      > .elementor-widget-container
      > .elementor-button-wrapper,
    .elementor-widget-archive-posts.e-load-more-pagination-end
      > .elementor-widget-container
      > .elementor-button-wrapper {
      display: none;
    }
    .elementor-widget-posts.e-load-more-pagination-end
      > .elementor-widget-container
      > .e-load-more-message,
    .elementor-widget-archive-posts.e-load-more-pagination-end
      > .elementor-widget-container
      > .e-load-more-message {
      display: block;
    }
    .elementor-widget-posts.e-load-more-no-spinner
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button-content-wrapper,
    .elementor-widget-archive-posts.e-load-more-no-spinner
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button-content-wrapper {
      visibility: visible;
    }
    .elementor-widget-posts > .elementor-widget-container .e-load-more-spinner,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      .e-load-more-spinner {
      display: flex;
    }
    .elementor-widget-posts
      > .elementor-widget-container
      .e-load-more-spinner
      i,
    .elementor-widget-posts
      > .elementor-widget-container
      .e-load-more-spinner
      svg,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      .e-load-more-spinner
      i,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      .e-load-more-spinner
      svg {
      margin: 0 auto;
      display: none;
    }
    .elementor-widget-posts
      > .elementor-widget-container
      .e-load-more-spinner
      i,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      .e-load-more-spinner
      i {
      color: var(--load-more-spinner-color);
    }
    .elementor-widget-posts
      > .elementor-widget-container
      .e-load-more-spinner
      svg,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      .e-load-more-spinner
      svg {
      fill: var(--load-more-spinner-color);
      height: 1em;
      width: 1em;
    }
    .elementor-widget-posts > .elementor-widget-container .e-load-more-message,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      .e-load-more-message {
      color: var(--load-more-message-color);
      text-align: var(--load-more-message-alignment, center);
      display: none;
      margin-top: var(--load-more—spacing, 30px);
    }
    .elementor-widget-posts
      > .elementor-widget-container
      > .elementor-button-wrapper,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      > .elementor-button-wrapper {
      margin-top: var(--load-more—spacing, 30px);
    }
    .elementor-widget-posts
      > .elementor-widget-container
      > .elementor-button-wrapper
      .e-load-more-spinner,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      > .elementor-button-wrapper
      .e-load-more-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: inherit;
    }
    .elementor-widget-posts
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button,
    .elementor-widget-archive-posts
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button {
      cursor: pointer;
      position: relative;
    }

    /*
Multi Steps Form

Currently manually overwriting  - need to find a better solution,
the source property is coming from element/dev/scss...

*/
    /* TODO: check why elementor-hidden class is not working */
    .elementor-hidden,
    .elementor-button.elementor-hidden {
      display: none;
    }

    .e-form__step {
      width: 100%;
    }
    .e-form__step:not(.elementor-hidden) {
      display: flex;
      flex-wrap: wrap;
    }
    .e-form__buttons {
      flex-wrap: wrap;
    }
    .e-form__buttons,
    .e-form__buttons__wrapper {
      display: flex;
    }
    .e-form__indicators {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      font-size: 13px;
      margin-bottom: var(--e-form-steps-indicators-spacing);
    }
    .e-form__indicators__indicator {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-basis: 0;
      padding: 0 var(--e-form-steps-divider-gap);
    }
    .e-form__indicators__indicator__progress {
      width: 100%;
      position: relative;
      background-color: var(--e-form-steps-indicator-progress-background-color);
      border-radius: var(--e-form-steps-indicator-progress-border-radius);
      overflow: hidden;
    }
    .e-form__indicators__indicator__progress__meter {
      width: var(--e-form-steps-indicator-progress-meter-width, 0);
      height: var(--e-form-steps-indicator-progress-height);
      line-height: var(--e-form-steps-indicator-progress-height);
      padding-right: 15px;
      border-radius: var(--e-form-steps-indicator-progress-border-radius);
      background-color: var(--e-form-steps-indicator-progress-color);
      color: var(--e-form-steps-indicator-progress-meter-color);
      text-align: right;
      transition: width 0.1s linear;
    }
    .e-form__indicators__indicator:first-child {
      padding-left: 0;
    }
    .e-form__indicators__indicator:last-child {
      padding-right: 0;
    }
    .e-form__indicators__indicator--state-inactive {
      color: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2);
    }
    .e-form__indicators__indicator--state-inactive
      [class*='indicator--shape-']:not(.e-form__indicators__indicator--shape-none) {
      background-color: var(
        --e-form-steps-indicator-inactive-secondary-color,
        #fff
      );
    }
    .e-form__indicators__indicator--state-inactive svg,
    .e-form__indicators__indicator--state-inactive object {
      fill: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2);
    }
    .e-form__indicators__indicator--state-active {
      color: var(--e-form-steps-indicator-active-primary-color, #39b54a);
      border-color: var(--e-form-steps-indicator-active-secondary-color, #fff);
    }
    .e-form__indicators__indicator--state-active
      [class*='indicator--shape-']:not(.e-form__indicators__indicator--shape-none) {
      background-color: var(
        --e-form-steps-indicator-active-secondary-color,
        #fff
      );
    }
    .e-form__indicators__indicator--state-active svg,
    .e-form__indicators__indicator--state-active object {
      fill: var(--e-form-steps-indicator-active-primary-color, #39b54a);
    }
    .e-form__indicators__indicator--state-completed {
      color: var(--e-form-steps-indicator-completed-secondary-color, #fff);
    }
    .e-form__indicators__indicator--state-completed
      [class*='indicator--shape-']:not(.e-form__indicators__indicator--shape-none) {
      background-color: var(
        --e-form-steps-indicator-completed-primary-color,
        #39b54a
      );
    }
    .e-form__indicators__indicator--state-completed
      .e-form__indicators__indicator__label {
      color: var(--e-form-steps-indicator-completed-primary-color, #39b54a);
    }
    .e-form__indicators__indicator--state-completed
      .e-form__indicators__indicator--shape-none {
      color: var(--e-form-steps-indicator-completed-primary-color, #39b54a);
      background-color: initial;
    }
    .e-form__indicators__indicator--state-completed svg,
    .e-form__indicators__indicator--state-completed object {
      fill: var(--e-form-steps-indicator-completed-secondary-color, #fff);
    }
    .e-form__indicators__indicator__icon {
      width: var(--e-form-steps-indicator-padding, 30px);
      height: var(--e-form-steps-indicator-padding, 30px);
      font-size: var(--e-form-steps-indicator-icon-size);
      border-width: 1px;
      border-style: solid;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-bottom: 10px;
    }
    .e-form__indicators__indicator__icon img,
    .e-form__indicators__indicator__icon svg,
    .e-form__indicators__indicator__icon object {
      width: var(--e-form-steps-indicator-icon-size);
      height: auto;
    }
    .e-form__indicators__indicator__icon .e-font-icon-svg {
      height: 1em;
    }
    .e-form__indicators__indicator__number {
      width: var(--e-form-steps-indicator-padding, 30px);
      height: var(--e-form-steps-indicator-padding, 30px);
      border-width: 1px;
      border-style: solid;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .e-form__indicators__indicator--shape-circle {
      border-radius: 50%;
    }
    .e-form__indicators__indicator--shape-square {
      border-radius: 0;
    }
    .e-form__indicators__indicator--shape-rounded {
      border-radius: 5px;
    }
    .e-form__indicators__indicator--shape-none {
      border: 0;
    }
    .e-form__indicators__indicator__label {
      text-align: center;
    }
    .e-form__indicators__indicator__separator {
      width: 100%;
      height: var(--e-form-steps-divider-width);
      background-color: #babfc5;
    }
    .e-form__indicators--type-number,
    .e-form__indicators--type-number_text,
    .e-form__indicators--type-icon,
    .e-form__indicators--type-icon_text {
      align-items: flex-start;
    }
    .e-form__indicators--type-number .e-form__indicators__indicator__separator,
    .e-form__indicators--type-number_text
      .e-form__indicators__indicator__separator,
    .e-form__indicators--type-icon .e-form__indicators__indicator__separator,
    .e-form__indicators--type-icon_text
      .e-form__indicators__indicator__separator {
      margin-top: calc(
        var(--e-form-steps-indicator-padding, 30px) / 2 -
          var(--e-form-steps-divider-width, 1px) / 2
      );
    }

    /* End OF Multi Steps Form */
    .elementor-field-type-hidden {
      display: none;
    }

    .elementor-field-type-html {
      display: inline-block;
    }

    .elementor-field-type-tel input {
      direction: inherit;
    }

    .elementor-login .elementor-remember-me,
    .elementor-login .elementor-lost-password {
      font-size: 0.85em;
    }

    .elementor-field-type-recaptcha_v3 .elementor-field-label {
      display: none;
    }

    .elementor-field-type-recaptcha_v3 .grecaptcha-badge {
      z-index: 1;
    }

    .elementor-button .elementor-form-spinner {
      order: 3;
    }

    .elementor-form .elementor-button > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .elementor-form .elementor-button .elementor-button-text {
      white-space: normal;
      flex-grow: 0;
    }
    .elementor-form .elementor-button svg {
      height: auto;
    }
    .elementor-form .elementor-button .e-font-icon-svg {
      height: 1em;
    }

    .elementor-select-wrapper .select-caret-down-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      inset-inline-end: 10px;
      pointer-events: none;
      font-size: 11px;
    }
    .elementor-select-wrapper .select-caret-down-wrapper svg {
      display: unset;
      width: 1em;
      aspect-ratio: unset;
      fill: currentColor;
    }
    .elementor-select-wrapper .select-caret-down-wrapper i {
      font-size: 19px;
      line-height: 2;
    }

    .elementor-select-wrapper.remove-before::before {
      content: '' !important;
    }

    .elementor-widget-price-list .elementor-price-list {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .elementor-widget-price-list .elementor-price-list li {
      margin: 0;
    }

    .elementor-price-list li:not(:last-child) {
      margin-bottom: 20px;
    }
    .elementor-price-list .elementor-price-list-image {
      max-width: 50%;
      flex-shrink: 0;
      padding-right: 25px;
    }
    .elementor-price-list .elementor-price-list-image img {
      width: 100%;
    }
    .elementor-price-list .elementor-price-list-text,
    .elementor-price-list .elementor-price-list-item,
    .elementor-price-list .elementor-price-list-header {
      display: flex;
    }
    .elementor-price-list .elementor-price-list-item {
      align-items: flex-start;
    }
    .elementor-price-list
      .elementor-price-list-item
      .elementor-price-list-text {
      align-items: flex-start;
      flex-wrap: wrap;
      flex-grow: 1;
    }
    .elementor-price-list
      .elementor-price-list-item
      .elementor-price-list-header {
      align-items: center;
      flex-basis: 100%;
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 10px;
      justify-content: space-between;
    }
    .elementor-price-list
      .elementor-price-list-item
      .elementor-price-list-title {
      max-width: 80%;
    }
    .elementor-price-list
      .elementor-price-list-item
      .elementor-price-list-price {
      font-weight: 600;
    }
    .elementor-price-list
      .elementor-price-list-item
      p.elementor-price-list-description {
      flex-basis: 100%;
      font-size: 14px;
      margin: 0;
    }
    .elementor-price-list
      .elementor-price-list-item
      .elementor-price-list-separator {
      flex-grow: 1;
      margin-left: 10px;
      margin-right: 10px;
      border-bottom-style: dotted;
      border-bottom-width: 2px;
      height: 0;
    }

    .elementor-price-table {
      text-align: center;
    }
    .elementor-price-table .elementor-price-table__header {
      background: var(--e-price-table-header-background-color, #555);
      padding: 20px 0;
    }
    .elementor-price-table .elementor-price-table__heading {
      margin: 0;
      padding: 0;
      line-height: 1.2;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
    }
    .elementor-price-table .elementor-price-table__subheading {
      font-size: 13px;
      font-weight: 400;
      color: #fff;
    }
    .elementor-price-table .elementor-price-table__original-price {
      margin-right: 15px;
      text-decoration: line-through;
      font-size: 0.5em;
      line-height: 1;
      font-weight: 400;
      align-self: center;
    }
    .elementor-price-table
      .elementor-price-table__original-price
      .elementor-price-table__currency {
      font-size: 1em;
      margin: 0;
    }
    .elementor-price-table .elementor-price-table__price {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      color: #555;
      font-weight: 800;
      font-size: 65px;
      padding: 40px 0;
    }
    .elementor-price-table
      .elementor-price-table__price
      .elementor-typo-excluded {
      line-height: initial;
      letter-spacing: initial;
      text-transform: initial;
      font-weight: initial;
      font-size: initial;
      font-style: initial;
    }
    .elementor-price-table .elementor-price-table__after-price {
      display: flex;
      flex-wrap: wrap;
      text-align: start;
      align-self: stretch;
      align-items: flex-start;
      flex-direction: column;
    }
    .elementor-price-table .elementor-price-table__integer-part {
      line-height: 0.8;
    }
    .elementor-price-table .elementor-price-table__currency,
    .elementor-price-table .elementor-price-table__fractional-part {
      line-height: 1;
      font-size: 0.3em;
    }
    .elementor-price-table .elementor-price-table__currency {
      margin-right: 3px;
    }
    .elementor-price-table .elementor-price-table__period {
      width: 100%;
      font-size: 13px;
      font-weight: 400;
    }
    .elementor-price-table .elementor-price-table__features-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      line-height: 1;
      color: var(--e-price-table-features-list-color);
    }
    .elementor-price-table .elementor-price-table__features-list li {
      font-size: 14px;
      line-height: 1;
      margin: 0;
      padding: 0;
    }
    .elementor-price-table
      .elementor-price-table__features-list
      li
      .elementor-price-table__feature-inner {
      margin-left: 15px;
      margin-right: 15px;
    }
    .elementor-price-table
      .elementor-price-table__features-list
      li:not(:first-child):before {
      content: '';
      display: block;
      border: 0 solid rgba(122, 122, 122, 0.3);
      margin: 10px 12.5%;
    }
    .elementor-price-table .elementor-price-table__features-list i {
      margin-right: 10px;
      font-size: 1.3em;
    }
    .elementor-price-table .elementor-price-table__features-list svg {
      margin-right: 10px;
      fill: var(--e-price-table-features-list-color);
      height: 1.3em;
      width: 1.3em;
    }
    .elementor-price-table .elementor-price-table__features-list svg ~ * {
      vertical-align: text-top;
    }
    .elementor-price-table .elementor-price-table__footer {
      padding: 30px 0;
    }
    .elementor-price-table .elementor-price-table__additional_info {
      margin: 0;
      font-size: 13px;
      line-height: 1.4;
    }
    .elementor-price-table__ribbon {
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      transform: rotate(90deg);
      width: 150px;
      overflow: hidden;
      height: 150px;
    }
    .elementor-price-table__ribbon-inner {
      text-align: center;
      left: 0;
      width: 200%;
      transform: translateY(-50%) translateX(-50%) translateX(35px)
        rotate(-45deg);
      margin-top: 35px;
      font-size: 13px;
      line-height: 2;
      font-weight: 800;
      text-transform: uppercase;
      background: #000;
    }
    .elementor-price-table__ribbon.elementor-ribbon-left {
      transform: rotate(0);
      left: 0;
      right: auto;
    }
    .elementor-price-table__ribbon.elementor-ribbon-right {
      transform: rotate(90deg);
      left: auto;
      right: 0;
    }

    .elementor-widget-price-table .elementor-widget-container {
      overflow: hidden;
      background-color: #f9fafa;
    }

    .e-con > .elementor-widget-price-list,
    .e-con-inner > .elementor-widget-price-list {
      width: var(--container-widget-width);
      --flex-grow: var(--container-widget-flex-grow);
    }

    .elementor-flip-box {
      height: 280px;
      position: relative;
      perspective: 1000px;
      transform-style: preserve-3d;
    }
    @media (max-device-width: 1024px) {
      .elementor-flip-box {
        cursor: pointer;
      }
    }
    .elementor-flip-box__front {
      background-color: #1abc9c;
    }
    .elementor-flip-box__back {
      background-color: #4054b2;
      display: block;
    }
    .elementor-flip-box__layer {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all 0.6s ease-in-out;
    }
    .elementor-flip-box__layer__overlay {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      text-align: center;
      padding: 35px;
      color: white;
    }
    .elementor-flip-box__layer__title {
      font-size: 21px;
    }
    .elementor-flip-box__layer__description {
      font-size: 14px;
    }
    .elementor-flip-box__layer__description:not(:last-child),
    .elementor-flip-box__layer__title:not(:last-child) {
      margin: 0 0 20px 0;
      padding: 0;
    }
    .elementor-flip-box__layer__title {
      line-height: 1;
      font-weight: 600;
    }
    .elementor-flip-box,
    .elementor-flip-box .elementor-view-framed,
    .elementor-flip-box .elementor-view-stacked {
      --e-flip-box-view-framed-stacked-icon-color: #fff;
    }
    .elementor-flip-box .elementor-icon,
    .elementor-flip-box .elementor-view-framed .elementor-icon,
    .elementor-flip-box .elementor-view-stacked .elementor-icon {
      color: var(--e-flip-box-view-framed-stacked-icon-color);
    }
    .elementor-flip-box .elementor-icon svg,
    .elementor-flip-box .elementor-view-framed .elementor-icon svg,
    .elementor-flip-box .elementor-view-stacked .elementor-icon svg {
      fill: var(--e-flip-box-view-framed-stacked-icon-color);
    }
    .elementor-flip-box .elementor-view-framed .elementor-icon {
      border-color: #fff;
    }
    .elementor-flip-box .elementor-view-stacked .elementor-icon {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .elementor-flip-box__button.elementor-button {
      cursor: pointer;
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      border: solid 2px white;
      background: transparent;
    }
    .elementor-flip-box__button.elementor-button:hover,
    .elementor-flip-box__button.elementor-button:focus-visible {
      background: transparent;
      text-decoration: none;
    }
    .elementor-flip-box__image {
      margin: 0 0 20px 0;
      display: inline-block;
      width: 100%;
    }
    .elementor-flip-box__image img {
      width: 50%;
    }
    .elementor-flip-box .elementor-icon-wrapper {
      margin-bottom: 20px;
    }
    .elementor-flip-box--3d .elementor-flip-box__layer__inner {
      transform: translateZ(90px) scale(0.91);
    }
    .elementor-flip-box--3d .elementor-flip-box__layer__overlay {
      transform-style: preserve-3d;
      transform: translateZ(0.1px);
    }
    .elementor-flip-box--effect-flip .elementor-flip-box {
      perspective: 1000px;
      transform-style: preserve-3d;
    }
    .elementor-flip-box--effect-flip .elementor-flip-box__layer {
      transform-style: preserve-3d;
      backface-visibility: hidden;
    }
    .elementor-flip-box--effect-flip .elementor-flip-box__front {
      transform: none;
      z-index: 1;
    }
    .elementor-flip-box--effect-flip
      .elementor-flip-box:hover
      .elementor-flip-box__back,
    .elementor-flip-box--effect-flip
      .elementor-flip-box:focus-visible
      .elementor-flip-box__back {
      transform: none;
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-right
      .elementor-flip-box__back {
      transform: rotateX(0) rotateY(-180deg);
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-right
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-right
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: rotateX(0) rotateY(180deg);
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-left
      .elementor-flip-box__back {
      transform: rotateX(0) rotateY(180deg);
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-left
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-left
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: rotateX(0) rotateY(-180deg);
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-up
      .elementor-flip-box__back {
      transform: rotateX(-180deg) rotateY(0);
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-up
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-up
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: rotateX(180deg) rotateY(0);
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-down
      .elementor-flip-box__back {
      transform: rotateX(180deg) rotateY(0);
    }
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-down
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-flip.elementor-flip-box--direction-down
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: rotateX(-180deg) rotateY(0);
    }
    .elementor-flip-box--effect-push .elementor-flip-box__front {
      transform: none;
    }
    .elementor-flip-box--effect-push.elementor-flip-box--direction-right
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-right
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: translateX(100%) translateY(0);
    }
    .elementor-flip-box--effect-push.elementor-flip-box--direction-left
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-left
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: translateX(-100%) translateY(0);
    }
    .elementor-flip-box--effect-push.elementor-flip-box--direction-up
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-up
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: translateX(0) translateY(-100%);
    }
    .elementor-flip-box--effect-push.elementor-flip-box--direction-down
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-down
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      transform: translateX(0) translateY(100%);
    }
    .elementor-flip-box--effect-slide .elementor-flip-box,
    .elementor-flip-box--effect-push .elementor-flip-box {
      overflow: hidden;
    }
    .elementor-flip-box--effect-slide
      .elementor-flip-box:hover
      .elementor-flip-box__back,
    .elementor-flip-box--effect-slide
      .elementor-flip-box:focus-visible
      .elementor-flip-box__back,
    .elementor-flip-box--effect-push
      .elementor-flip-box:hover
      .elementor-flip-box__back,
    .elementor-flip-box--effect-push
      .elementor-flip-box:focus-visible
      .elementor-flip-box__back {
      transform: none;
    }
    .elementor-flip-box--effect-slide.elementor-flip-box--direction-right
      .elementor-flip-box__back,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-right
      .elementor-flip-box__back {
      transform: translateX(-100%) translateY(0);
    }
    .elementor-flip-box--effect-slide.elementor-flip-box--direction-left
      .elementor-flip-box__back,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-left
      .elementor-flip-box__back {
      transform: translateX(100%) translateY(0);
    }
    .elementor-flip-box--effect-slide.elementor-flip-box--direction-up
      .elementor-flip-box__back,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-up
      .elementor-flip-box__back {
      transform: translateX(0) translateY(100%);
    }
    .elementor-flip-box--effect-slide.elementor-flip-box--direction-down
      .elementor-flip-box__back,
    .elementor-flip-box--effect-push.elementor-flip-box--direction-down
      .elementor-flip-box__back {
      transform: translateX(0) translateY(-100%);
    }
    .elementor-flip-box--effect-zoom-out
      .elementor-flip-box
      .elementor-flip-box__front {
      transition: transform 0.7s, opacity 0.35s, width 0.1ms;
      opacity: 1;
      transform: scale(1);
      z-index: 1;
      width: 100%;
    }
    .elementor-flip-box--effect-zoom-out
      .elementor-flip-box:hover
      .elementor-flip-box__front,
    .elementor-flip-box--effect-zoom-out
      .elementor-flip-box:focus-visible
      .elementor-flip-box__front {
      width: 0;
      opacity: 0;
      transform: scale(0.7);
      transition: transform 0.8s, opacity 0.7s 0.1s, width 0.1ms 0.7s;
    }
    .elementor-flip-box--effect-zoom-in
      .elementor-flip-box
      .elementor-flip-box__back {
      transition: transform 0.7s, opacity 0.5s 0.2s;
      opacity: 0;
      transform: scale(0.7);
    }
    .elementor-flip-box--effect-zoom-in
      .elementor-flip-box:hover
      .elementor-flip-box__back,
    .elementor-flip-box--effect-zoom-in
      .elementor-flip-box:focus-visible
      .elementor-flip-box__back {
      transition: transform 0.7s, opacity 0.5s;
      opacity: 1;
      transform: scale(1);
    }
    .elementor-flip-box--effect-fade
      .elementor-flip-box
      .elementor-flip-box__back {
      opacity: 0;
    }
    .elementor-flip-box--effect-fade
      .elementor-flip-box:hover
      .elementor-flip-box__back,
    .elementor-flip-box--effect-fade
      .elementor-flip-box:focus-visible
      .elementor-flip-box__back {
      opacity: 1;
    }

    .elementor-widget-flip-box.elementor-flip-box--flipped
      .elementor-widget-container
      .elementor-flip-box__front {
      display: none;
    }
    .elementor-widget-flip-box.elementor-flip-box--flipped
      .elementor-widget-container
      .elementor-flip-box__back {
      transform: none;
      opacity: 1;
    }

    .e-con > .elementor-widget-flip-box,
    .e-con-inner > .elementor-widget-flip-box {
      width: var(--container-widget-width);
      --flex-grow: var(--container-widget-flex-grow);
    }

    .elementor-widget-call-to-action .elementor-widget-container {
      overflow: hidden;
    }

    .elementor-cta {
      position: relative;
      display: flex;
      overflow: hidden;
      transition: 0.5s;
    }
    .elementor-cta--skin-classic .elementor-cta {
      flex-wrap: wrap;
    }
    .elementor-cta--skin-classic .elementor-cta__bg-wrapper {
      position: relative;
      min-height: 200px;
      width: 100%;
    }
    .elementor-cta--skin-classic .elementor-cta__content {
      transition: all 0.4s;
      width: 100%;
      background-color: #f9fafa;
    }
    .elementor-cta--skin-classic .elementor-cta__content-item,
    .elementor-cta--skin-classic .elementor-cta__content-item .elementor-icon {
      color: #3f444b;
      border-color: #3f444b;
      fill: #3f444b;
    }
    .elementor-cta--skin-classic .elementor-cta__button.elementor-button {
      color: #3f444b;
      border-color: #3f444b;
    }
    .elementor-cta--skin-cover .elementor-cta {
      display: block;
    }
    .elementor-cta--skin-cover .elementor-cta__bg-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.4s;
      width: 100%;
    }
    .elementor-cta--skin-cover .elementor-cta__content {
      min-height: 280px;
    }
    .elementor-cta--skin-cover .elementor-cta__content-item,
    .elementor-cta--skin-cover .elementor-cta__content-item .elementor-icon {
      color: #fff;
      border-color: #fff;
    }
    .elementor-cta--skin-cover .elementor-cta__button.elementor-button {
      color: white;
      border-color: white;
    }
    .elementor-cta--layout-image-above .elementor-cta {
      flex-wrap: wrap;
    }
    .elementor-cta--layout-image-above .elementor-cta__bg-wrapper {
      width: 100%;
    }
    .elementor-cta--layout-image-left .elementor-cta,
    .elementor-cta--layout-image-right .elementor-cta {
      flex-wrap: nowrap;
    }
    .elementor-cta--layout-image-left .elementor-cta__bg-wrapper,
    .elementor-cta--layout-image-right .elementor-cta__bg-wrapper {
      width: auto;
      min-width: 50%;
    }
    .elementor-cta--layout-image-left .elementor-cta__content,
    .elementor-cta--layout-image-right .elementor-cta__content {
      flex-grow: 1;
    }
    .elementor-cta--layout-image-left .elementor-cta {
      flex-direction: row;
    }
    .elementor-cta--layout-image-right .elementor-cta {
      flex-direction: row-reverse;
    }
    .elementor-cta__bg,
    .elementor-cta__bg-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.4s;
    }
    .elementor-cta__bg-wrapper {
      z-index: 1;
      overflow: hidden;
    }
    .elementor-cta__bg {
      background-size: cover;
      background-position: center;
      z-index: 1;
    }
    .elementor-cta__bg-overlay {
      z-index: 2;
    }
    .elementor-cta__button.elementor-button {
      cursor: pointer;
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      border: solid 2px white;
      background: transparent;
    }
    .elementor-cta__button.elementor-button:hover {
      background: transparent;
      text-decoration: none;
    }
    .elementor-cta__title {
      font-size: 23px;
    }
    .elementor-cta__content {
      z-index: 1;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      padding: 35px;
      width: 100%;
    }
    .elementor-cta__content,
    .elementor-cta__content-item {
      position: relative;
      transition: 0.5s;
      color: #fff;
    }
    .elementor-cta__content-item {
      width: 100%;
      margin: 0;
    }
    .elementor-cta__content-item:not(:last-child) {
      margin-bottom: 15px;
    }
    .elementor-cta__content-item .elementor-icon {
      color: #fff;
    }
    .elementor-cta--valign-top .elementor-cta__content {
      align-content: flex-start;
      align-items: flex-start;
    }
    .elementor-cta--valign-middle .elementor-cta__content {
      align-content: center;
      align-items: center;
    }
    .elementor-cta--valign-bottom .elementor-cta__content {
      align-content: flex-end;
      align-items: flex-end;
    }
    .elementor-cta:hover .elementor-cta__bg-overlay {
      background-color: rgba(0, 0, 0, 0.3);
    }

    @media (max-device-width: 1024px) {
      .elementor-cta {
        cursor: pointer;
      }
    }
    @media (min-width: -1) {
      .elementor-cta--widescreen-layout-image-above .elementor-cta {
        flex-wrap: wrap;
      }
      .elementor-cta--widescreen-layout-image-above .elementor-cta__bg-wrapper {
        width: 100%;
      }
      .elementor-cta--widescreen-layout-image-left .elementor-cta,
      .elementor-cta--widescreen-layout-image-right .elementor-cta {
        flex-wrap: nowrap;
      }
      .elementor-cta--widescreen-layout-image-left .elementor-cta__bg-wrapper,
      .elementor-cta--widescreen-layout-image-right .elementor-cta__bg-wrapper {
        width: auto;
        min-width: 50%;
      }
      .elementor-cta--widescreen-layout-image-left .elementor-cta__content,
      .elementor-cta--widescreen-layout-image-right .elementor-cta__content {
        flex-grow: 1;
      }
      .elementor-cta--widescreen-layout-image-left .elementor-cta {
        flex-direction: row;
      }
      .elementor-cta--widescreen-layout-image-right .elementor-cta {
        flex-direction: row-reverse;
      }
    }
    @media (max-width: -1) {
      .elementor-cta--laptop-layout-image-above .elementor-cta {
        flex-wrap: wrap;
      }
      .elementor-cta--laptop-layout-image-above .elementor-cta__bg-wrapper {
        width: 100%;
      }
      .elementor-cta--laptop-layout-image-left .elementor-cta,
      .elementor-cta--laptop-layout-image-right .elementor-cta {
        flex-wrap: nowrap;
      }
      .elementor-cta--laptop-layout-image-left .elementor-cta__bg-wrapper,
      .elementor-cta--laptop-layout-image-right .elementor-cta__bg-wrapper {
        width: auto;
        min-width: 50%;
      }
      .elementor-cta--laptop-layout-image-left .elementor-cta__content,
      .elementor-cta--laptop-layout-image-right .elementor-cta__content {
        flex-grow: 1;
      }
      .elementor-cta--laptop-layout-image-left .elementor-cta {
        flex-direction: row;
      }
      .elementor-cta--laptop-layout-image-right .elementor-cta {
        flex-direction: row-reverse;
      }
    }
    @media (max-width: -1) {
      .elementor-cta--tablet_extra-layout-image-above .elementor-cta {
        flex-wrap: wrap;
      }
      .elementor-cta--tablet_extra-layout-image-above
        .elementor-cta__bg-wrapper {
        width: 100%;
      }
      .elementor-cta--tablet_extra-layout-image-left .elementor-cta,
      .elementor-cta--tablet_extra-layout-image-right .elementor-cta {
        flex-wrap: nowrap;
      }
      .elementor-cta--tablet_extra-layout-image-left .elementor-cta__bg-wrapper,
      .elementor-cta--tablet_extra-layout-image-right
        .elementor-cta__bg-wrapper {
        width: auto;
        min-width: 50%;
      }
      .elementor-cta--tablet_extra-layout-image-left .elementor-cta__content,
      .elementor-cta--tablet_extra-layout-image-right .elementor-cta__content {
        flex-grow: 1;
      }
      .elementor-cta--tablet_extra-layout-image-left .elementor-cta {
        flex-direction: row;
      }
      .elementor-cta--tablet_extra-layout-image-right .elementor-cta {
        flex-direction: row-reverse;
      }
    }
    @media (max-width: 1024px) {
      .elementor-cta--tablet-layout-image-above .elementor-cta {
        flex-wrap: wrap;
      }
      .elementor-cta--tablet-layout-image-above .elementor-cta__bg-wrapper {
        width: 100%;
      }
      .elementor-cta--tablet-layout-image-left .elementor-cta,
      .elementor-cta--tablet-layout-image-right .elementor-cta {
        flex-wrap: nowrap;
      }
      .elementor-cta--tablet-layout-image-left .elementor-cta__bg-wrapper,
      .elementor-cta--tablet-layout-image-right .elementor-cta__bg-wrapper {
        width: auto;
        min-width: 50%;
      }
      .elementor-cta--tablet-layout-image-left .elementor-cta__content,
      .elementor-cta--tablet-layout-image-right .elementor-cta__content {
        flex-grow: 1;
      }
      .elementor-cta--tablet-layout-image-left .elementor-cta {
        flex-direction: row;
      }
      .elementor-cta--tablet-layout-image-right .elementor-cta {
        flex-direction: row-reverse;
      }
    }
    @media (max-width: -1) {
      .elementor-cta--mobile_extra-layout-image-above .elementor-cta {
        flex-wrap: wrap;
      }
      .elementor-cta--mobile_extra-layout-image-above
        .elementor-cta__bg-wrapper {
        width: 100%;
      }
      .elementor-cta--mobile_extra-layout-image-left .elementor-cta,
      .elementor-cta--mobile_extra-layout-image-right .elementor-cta {
        flex-wrap: nowrap;
      }
      .elementor-cta--mobile_extra-layout-image-left .elementor-cta__bg-wrapper,
      .elementor-cta--mobile_extra-layout-image-right
        .elementor-cta__bg-wrapper {
        width: auto;
        min-width: 50%;
      }
      .elementor-cta--mobile_extra-layout-image-left .elementor-cta__content,
      .elementor-cta--mobile_extra-layout-image-right .elementor-cta__content {
        flex-grow: 1;
      }
      .elementor-cta--mobile_extra-layout-image-left .elementor-cta {
        flex-direction: row;
      }
      .elementor-cta--mobile_extra-layout-image-right .elementor-cta {
        flex-direction: row-reverse;
      }
    }
    @media (max-width: 767px) {
      .elementor-cta--mobile-layout-image-above .elementor-cta {
        flex-wrap: wrap;
      }
      .elementor-cta--mobile-layout-image-above .elementor-cta__bg-wrapper {
        width: 100%;
      }
      .elementor-cta--mobile-layout-image-left .elementor-cta,
      .elementor-cta--mobile-layout-image-right .elementor-cta {
        flex-wrap: nowrap;
      }
      .elementor-cta--mobile-layout-image-left .elementor-cta__bg-wrapper,
      .elementor-cta--mobile-layout-image-right .elementor-cta__bg-wrapper {
        width: auto;
        min-width: 50%;
      }
      .elementor-cta--mobile-layout-image-left .elementor-cta__content,
      .elementor-cta--mobile-layout-image-right .elementor-cta__content {
        flex-grow: 1;
      }
      .elementor-cta--mobile-layout-image-left .elementor-cta {
        flex-direction: row;
      }
      .elementor-cta--mobile-layout-image-right .elementor-cta {
        flex-direction: row-reverse;
      }
    }
    .elementor-ribbon {
      position: absolute;
      z-index: 1;
      top: 0;
      left: auto;
      right: 0;
      transform: rotate(90deg);
      width: 150px;
      overflow: hidden;
      height: 150px;
    }
    .elementor-ribbon-inner {
      text-align: center;
      left: 0;
      width: 200%;
      transform: translateY(-50%) translateX(-50%) translateX(35px)
        rotate(-45deg);
      margin-top: 35px;
      font-size: 13px;
      line-height: 2;
      font-weight: 800;
      text-transform: uppercase;
      background: #000;
      color: #fff;
    }
    .elementor-ribbon.elementor-ribbon-left {
      transform: rotate(0);
      left: 0;
      right: auto;
    }
    .elementor-ribbon.elementor-ribbon-right {
      transform: rotate(90deg);
      left: auto;
      right: 0;
    }

    .elementor-widget-countdown .elementor-countdown-expire--message {
      display: none;
      padding: 20px;
      text-align: center;
    }
    .elementor-widget-countdown .elementor-countdown-wrapper {
      flex-direction: row;
    }
    .elementor-widget-countdown .elementor-countdown-item {
      padding: 20px 0;
      text-align: center;
      color: #ffffff;
    }
    .elementor-widget-countdown .elementor-countdown-digits,
    .elementor-widget-countdown .elementor-countdown-label {
      line-height: 1;
    }
    .elementor-widget-countdown .elementor-countdown-digits {
      font-size: 69px;
    }
    .elementor-widget-countdown .elementor-countdown-label {
      font-size: 19px;
    }
    .elementor-widget-countdown.elementor-countdown--label-block
      .elementor-countdown-wrapper {
      display: flex;
      justify-content: center;
      margin-right: auto;
      margin-left: auto;
    }
    .elementor-widget-countdown.elementor-countdown--label-block
      .elementor-countdown-digits,
    .elementor-widget-countdown.elementor-countdown--label-block
      .elementor-countdown-label {
      display: block;
    }
    .elementor-widget-countdown.elementor-countdown--label-block
      .elementor-countdown-item {
      flex-basis: 0;
      flex-grow: 1;
    }
    .elementor-widget-countdown.elementor-countdown--label-inline {
      text-align: center;
    }
    .elementor-widget-countdown.elementor-countdown--label-inline
      .elementor-countdown-item {
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
    }

    /* Slides Widget */
    .elementor-slides .swiper-slide-bg {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-width: 100%;
      min-height: 100%;
    }
    .elementor-slides .swiper-slide-inner {
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 50px;
      margin: auto;
    }
    .elementor-slides .swiper-slide-inner,
    .elementor-slides .swiper-slide-inner:hover {
      color: white;
      display: flex;
    }
    .elementor-slides .swiper-slide-inner .elementor-background-overlay {
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .elementor-slides .swiper-slide-inner .elementor-slide-content {
      position: relative;
      z-index: 1;
      width: 100%;
    }
    .elementor-slides .swiper-slide-inner .elementor-slide-heading {
      font-size: 35px;
      font-weight: bold;
      line-height: 1;
    }
    .elementor-slides .swiper-slide-inner .elementor-slide-description {
      font-size: 17px;
      line-height: 1.4;
    }
    .elementor-slides
      .swiper-slide-inner
      .elementor-slide-heading:not(:last-child),
    .elementor-slides
      .swiper-slide-inner
      .elementor-slide-description:not(:last-child) {
      margin-bottom: 30px;
    }
    .elementor-slides .swiper-slide-inner .elementor-slide-button {
      border: solid 2px white;
      color: white;
      background: transparent;
      display: inline-block;
    }
    .elementor-slides .swiper-slide-inner .elementor-slide-button,
    .elementor-slides .swiper-slide-inner .elementor-slide-button:hover {
      background: transparent;
      color: inherit;
      text-decoration: none;
    }

    .elementor--v-position-top .swiper-slide-inner {
      align-items: flex-start;
    }
    .elementor--v-position-bottom .swiper-slide-inner {
      align-items: flex-end;
    }
    .elementor--v-position-middle .swiper-slide-inner {
      align-items: center;
    }
    .elementor--h-position-left .swiper-slide-inner {
      justify-content: flex-start;
    }
    .elementor--h-position-right .swiper-slide-inner {
      justify-content: flex-end;
    }
    .elementor--h-position-center .swiper-slide-inner {
      justify-content: center;
    }

    body.rtl .elementor-widget-slides .elementor-swiper-button-next {
      left: 10px;
      right: auto;
    }
    body.rtl .elementor-widget-slides .elementor-swiper-button-prev {
      right: 10px;
      left: auto;
    }

    .elementor-slides-wrapper div:not(.swiper-slide) > .swiper-slide-inner {
      display: none;
    }

    @media (max-width: 767px) {
      .elementor-slides .swiper-slide-inner {
        padding: 30px;
      }
      .elementor-slides .swiper-slide-inner .elementor-slide-heading {
        font-size: 23px;
        line-height: 1;
        margin-bottom: 15px;
      }
      .elementor-slides .swiper-slide-inner .elementor-slide-description {
        font-size: 13px;
        line-height: 1.4;
        margin-bottom: 15px;
      }
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-flat
      .elementor-share-btn,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-gradient
      .elementor-share-btn {
      background-color: var(--e-share-buttons-primary-color, #ea4335);
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-flat
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-flat
      .elementor-share-btn__text,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-gradient
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-gradient
      .elementor-share-btn__text {
      color: var(--e-share-buttons-secondary-color, #fff);
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-flat
      .elementor-share-btn__icon
      svg,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-flat
      .elementor-share-btn__text
      svg,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-gradient
      .elementor-share-btn__icon
      svg,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-gradient
      .elementor-share-btn__text
      svg {
      fill: var(--e-share-buttons-secondary-color, #fff);
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-framed
      .elementor-share-btn,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-minimal
      .elementor-share-btn,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-boxed
      .elementor-share-btn {
      color: var(--e-share-buttons-primary-color, #ea4335);
      border-color: var(--e-share-buttons-primary-color, #ea4335);
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-minimal
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-boxed
      .elementor-share-btn__icon {
      background-color: var(--e-share-buttons-primary-color, #ea4335);
      color: var(--e-share-buttons-secondary-color, #fff);
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-minimal
      .elementor-share-btn__icon
      svg,
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-boxed
      .elementor-share-btn__icon
      svg {
      fill: var(--e-share-buttons-secondary-color, #fff);
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-framed
      .elementor-share-btn__icon {
      color: var(--e-share-buttons-primary-color, #ea4335);
    }
    .elementor-share-buttons--color-custom.elementor-share-buttons--skin-framed
      .elementor-share-btn__icon
      svg {
      fill: var(--e-share-buttons-primary-color, #ea4335);
    }
    .elementor-share-buttons--color-official .elementor-share-btn:hover {
      filter: saturate(1.5) brightness(1.2);
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_digg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_digg {
      background-color: #005be2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_email,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_email {
      background-color: #ea4335;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_print,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_print {
      background-color: #aaaaaa;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_facebook,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_facebook {
      background-color: #3b5998;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_google,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_google {
      background-color: #dd4b39;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_pocket,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_pocket {
      background-color: #ef3f56;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_linkedin,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_linkedin {
      background-color: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_odnoklassniki,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_odnoklassniki {
      background-color: #f4731c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_pinterest,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_pinterest {
      background-color: #bd081c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_reddit,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_reddit {
      background-color: #ff4500;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_skype,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_skype {
      background-color: #00aff0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_slideshare,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_slideshare {
      background-color: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_snapchat,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_snapchat {
      background-color: #fffc00;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_stumbleupon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_stumbleupon {
      background-color: #eb4924;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_mix,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_mix {
      background-color: #f3782b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_tumblr,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_tumblr {
      background-color: #35465c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_twitch,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_twitch {
      background-color: #6441a5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_telegram,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_telegram {
      background-color: #2ca5e0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_twitter,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_twitter {
      background-color: #1da1f2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_vimeo,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_vimeo {
      background-color: #1ab7ea;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_vk,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_vk {
      background-color: #45668e;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_whatsapp,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_whatsapp {
      background-color: #25d366;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_wordpress,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_wordpress {
      background-color: #21759b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-flat
      .elementor-share-btn_xing,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-gradient
      .elementor-share-btn_xing {
      background-color: #026466;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_digg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_digg {
      color: #005be2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_digg
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_digg
      svg {
      fill: #005be2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_email,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_email {
      color: #ea4335;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_email
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_email
      svg {
      fill: #ea4335;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_print,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_print {
      color: #aaaaaa;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_print
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_print
      svg {
      fill: #aaaaaa;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_facebook,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_facebook {
      color: #3b5998;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_facebook
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_facebook
      svg {
      fill: #3b5998;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_google,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_google {
      color: #dd4b39;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_google
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_google
      svg {
      fill: #dd4b39;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_pocket,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_pocket {
      color: #ef3f56;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_pocket
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_pocket
      svg {
      fill: #ef3f56;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_linkedin,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_linkedin {
      color: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_linkedin
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_linkedin
      svg {
      fill: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_odnoklassniki,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_odnoklassniki {
      color: #f4731c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_odnoklassniki
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_odnoklassniki
      svg {
      fill: #f4731c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_pinterest,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_pinterest {
      color: #bd081c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_pinterest
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_pinterest
      svg {
      fill: #bd081c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_reddit,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_reddit {
      color: #ff4500;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_reddit
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_reddit
      svg {
      fill: #ff4500;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_skype,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_skype {
      color: #00aff0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_skype
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_skype
      svg {
      fill: #00aff0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_slideshare,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_slideshare {
      color: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_slideshare
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_slideshare
      svg {
      fill: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_snapchat,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_snapchat {
      color: #fffc00;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_snapchat
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_snapchat
      svg {
      fill: #fffc00;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_stumbleupon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_stumbleupon {
      color: #eb4924;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_stumbleupon
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_stumbleupon
      svg {
      fill: #eb4924;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_mix,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_mix {
      color: #f3782b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_mix
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_mix
      svg {
      fill: #f3782b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_tumblr,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_tumblr {
      color: #35465c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_tumblr
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_tumblr
      svg {
      fill: #35465c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_twitch,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_twitch {
      color: #6441a5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_twitch
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_twitch
      svg {
      fill: #6441a5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_telegram,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_telegram {
      color: #2ca5e0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_telegram
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_telegram
      svg {
      fill: #2ca5e0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_twitter,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_twitter {
      color: #1da1f2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_twitter
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_twitter
      svg {
      fill: #1da1f2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_vimeo,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_vimeo {
      color: #1ab7ea;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_vimeo
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_vimeo
      svg {
      fill: #1ab7ea;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_vk,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_vk {
      color: #45668e;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_vk
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_vk
      svg {
      fill: #45668e;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_whatsapp,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_whatsapp {
      color: #25d366;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_whatsapp
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_whatsapp
      svg {
      fill: #25d366;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_wordpress,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_wordpress {
      color: #21759b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_wordpress
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_wordpress
      svg {
      fill: #21759b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_xing,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_xing {
      color: #026466;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-framed
      .elementor-share-btn_xing
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_xing
      svg {
      fill: #026466;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_digg
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_digg
      .elementor-share-btn__icon {
      background-color: #005be2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_email
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_email
      .elementor-share-btn__icon {
      background-color: #ea4335;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_print
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_print
      .elementor-share-btn__icon {
      background-color: #aaaaaa;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_facebook
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_facebook
      .elementor-share-btn__icon {
      background-color: #3b5998;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_google
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_google
      .elementor-share-btn__icon {
      background-color: #dd4b39;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_pocket
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_pocket
      .elementor-share-btn__icon {
      background-color: #ef3f56;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_linkedin
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_linkedin
      .elementor-share-btn__icon {
      background-color: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_odnoklassniki
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_odnoklassniki
      .elementor-share-btn__icon {
      background-color: #f4731c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_pinterest
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_pinterest
      .elementor-share-btn__icon {
      background-color: #bd081c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_reddit
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_reddit
      .elementor-share-btn__icon {
      background-color: #ff4500;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_skype
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_skype
      .elementor-share-btn__icon {
      background-color: #00aff0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_slideshare
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_slideshare
      .elementor-share-btn__icon {
      background-color: #0077b5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_snapchat
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_snapchat
      .elementor-share-btn__icon {
      background-color: #fffc00;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_stumbleupon
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_stumbleupon
      .elementor-share-btn__icon {
      background-color: #eb4924;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_mix
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_mix
      .elementor-share-btn__icon {
      background-color: #f3782b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_tumblr
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_tumblr
      .elementor-share-btn__icon {
      background-color: #35465c;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_twitch
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_twitch
      .elementor-share-btn__icon {
      background-color: #6441a5;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_telegram
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_telegram
      .elementor-share-btn__icon {
      background-color: #2ca5e0;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_twitter
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_twitter
      .elementor-share-btn__icon {
      background-color: #1da1f2;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_vimeo
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_vimeo
      .elementor-share-btn__icon {
      background-color: #1ab7ea;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_vk
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_vk
      .elementor-share-btn__icon {
      background-color: #45668e;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_whatsapp
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_whatsapp
      .elementor-share-btn__icon {
      background-color: #25d366;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_wordpress
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_wordpress
      .elementor-share-btn__icon {
      background-color: #21759b;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn_xing
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn_xing
      .elementor-share-btn__icon {
      background-color: #026466;
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn
      .elementor-share-btn__icon,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn
      .elementor-share-btn__icon {
      color: var(--e-share-buttons-icon-color, #fff);
    }
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-boxed
      .elementor-share-btn
      .elementor-share-btn__icon
      svg,
    .elementor-share-buttons--color-official.elementor-share-buttons--skin-minimal
      .elementor-share-btn
      .elementor-share-btn__icon
      svg {
      fill: var(--e-share-buttons-icon-color, #fff);
    }
    .elementor-share-buttons--skin-framed .elementor-share-btn__icon,
    .elementor-share-buttons--skin-boxed .elementor-share-btn__icon {
      margin-left: -0.2em;
    }
    .elementor-share-buttons--skin-framed .elementor-share-btn,
    .elementor-share-buttons--skin-boxed .elementor-share-btn {
      border-style: solid;
    }
    .elementor-share-buttons--skin-gradient .elementor-share-btn__text {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.12),
        rgba(0, 0, 0, 0)
      );
    }
    .elementor-share-buttons--skin-gradient.elementor-share-buttons--view-icon
      .elementor-share-btn__icon {
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.12),
        rgba(0, 0, 0, 0)
      );
    }
    .elementor-share-buttons--skin-boxed .elementor-share-btn__text,
    .elementor-share-buttons--skin-minimal .elementor-share-btn__text,
    .elementor-share-buttons--skin-gradient .elementor-share-btn__text {
      padding-left: 0.9em;
    }
    .elementor-share-buttons--skin-flat .elementor-share-btn,
    .elementor-share-buttons--skin-gradient .elementor-share-btn {
      background-color: var(--e-share-buttons-primary-color, #ea4335);
    }
    .elementor-share-buttons--skin-flat .elementor-share-btn__icon,
    .elementor-share-buttons--skin-flat .elementor-share-btn__text,
    .elementor-share-buttons--skin-gradient .elementor-share-btn__icon,
    .elementor-share-buttons--skin-gradient .elementor-share-btn__text {
      color: var(--e-share-buttons-secondary-color, #fff);
    }
    .elementor-share-buttons--skin-flat .elementor-share-btn__icon svg,
    .elementor-share-buttons--skin-flat .elementor-share-btn__text svg,
    .elementor-share-buttons--skin-gradient .elementor-share-btn__icon svg,
    .elementor-share-buttons--skin-gradient .elementor-share-btn__text svg {
      fill: var(--e-share-buttons-secondary-color, #fff);
    }
    .elementor-share-buttons--skin-minimal.elementor-share-buttons--shape-rounded
      .elementor-share-btn__icon {
      border-radius: 0.5em;
    }
    .elementor-share-buttons--skin-minimal.elementor-share-buttons--shape-circle
      .elementor-share-btn__icon {
      border-radius: 99.9em;
    }
    .elementor-share-buttons--view-text .elementor-share-btn__text {
      padding-left: 1em;
    }
    .elementor-share-buttons--view-text .elementor-share-btn,
    .elementor-share-buttons--view-icon .elementor-share-btn {
      justify-content: center;
    }
    .elementor-share-buttons--view-text .elementor-share-btn__icon,
    .elementor-share-buttons--view-text .elementor-share-btn__text,
    .elementor-share-buttons--view-icon .elementor-share-btn__icon,
    .elementor-share-buttons--view-icon .elementor-share-btn__text {
      flex-grow: 1;
      justify-content: center;
    }
    .elementor-share-buttons--shape-rounded .elementor-share-btn {
      border-radius: 0.5em;
    }
    .elementor-share-buttons--shape-circle .elementor-share-btn {
      border-radius: 99.9em;
    }

    .elementor-share-btn {
      overflow: hidden;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition-duration: 0.2s;
      transition-property: filter, background-color, border-color;
      height: 4.5em;
      cursor: pointer;
    }
    .elementor-share-btn__icon,
    .elementor-share-btn__text {
      transition-property: color, background-color;
      transition-duration: 0.2s;
    }
    .elementor-share-btn__icon {
      display: flex;
      align-self: stretch;
      justify-content: center;
      align-items: center;
      width: 4.5em;
      position: relative;
    }
    .elementor-share-btn__icon i {
      font-size: var(--e-share-buttons-icon-size, 1.7em);
    }
    .elementor-share-btn__icon svg {
      width: var(--e-share-buttons-icon-size, 1.7em);
      height: var(--e-share-buttons-icon-size, 1.7em);
    }
    .elementor-share-btn__text {
      font-size: 1.5em;
      padding-right: 1em;
      align-self: stretch;
      flex-grow: 1;
      align-items: center;
      display: flex;
      font-weight: 100;
    }
    .elementor-share-btn__title {
      font-weight: bold;
      flex-shrink: 0;
    }

    .elementor-widget-share-buttons {
      text-align: var(--alignment, inherit);
      -moz-text-align-last: var(--alignment, inherit);
      text-align-last: var(--alignment, inherit);
    }
    .elementor-widget-share-buttons.elementor-grid-0
      .elementor-widget-container {
      font-size: 0;
    }

    .elementor-skin-carousel .elementor-main-swiper,
    .elementor-skin-coverflow .elementor-main-swiper {
      height: 230px;
    }
    .elementor-skin-slideshow .elementor-main-swiper {
      height: 450px;
      position: relative;
    }
    .elementor-skin-slideshow
      .elementor-thumbnails-swiper
      .elementor-custom-embed-play
      i {
      font-size: 50px;
    }
    .elementor-skin-slideshow .elementor-thumbnails-swiper .swiper-slide {
      cursor: pointer;
    }
    .elementor-skin-slideshow
      .elementor-thumbnails-swiper
      .swiper-slide:not(.swiper-slide-active):after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .elementor-carousel-image {
      background: no-repeat center;
      background-size: cover;
      height: 100%;
      position: relative;
    }

    .elementor-carousel-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 20px;
      color: var(--e-carousel-image-overlay-color, #fff);
      background-color: rgba(105, 114, 125, 0.8);
      transition: transform 0.5s, opacity 0.5s;
    }
    .elementor-carousel-image-overlay i {
      font-size: var(--e-carousel-image-overlay-icon-size, 21px);
    }
    .elementor-carousel-image-overlay svg {
      fill: var(--e-carousel-image-overlay-color, #fff);
      width: var(--e-carousel-image-overlay-icon-size, 21px);
      height: var(--e-carousel-image-overlay-icon-size, 21px);
    }

    /**
 * Responsive Alignment based on `prefix_class`
 */
    .elementor-testimonial__image {
      flex-shrink: 0;
    }
    .elementor-testimonial__image img {
      border-radius: 999px;
      box-sizing: content-box;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .elementor-testimonial__footer,
    .elementor-testimonial__header {
      display: flex;
    }
    .elementor-testimonial__footer cite,
    .elementor-testimonial__header cite {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-size: 14px;
      line-height: 1.5;
    }
    .elementor-testimonial__name {
      font-weight: bold;
    }
    .elementor-testimonial--layout-image_inline .elementor-testimonial__footer,
    .elementor-testimonial--layout-image_above .elementor-testimonial__footer {
      align-items: center;
    }
    .elementor-testimonial--layout-image_inline
      .elementor-testimonial__image
      + cite,
    .elementor-testimonial--layout-image_above
      .elementor-testimonial__image
      + cite {
      margin-left: 20px;
    }
    .elementor-testimonial--layout-image_inline.elementor-testimonial--align-right
      .elementor-testimonial__footer,
    .elementor-testimonial--layout-image_above.elementor-testimonial--align-right
      .elementor-testimonial__footer {
      flex-direction: row-reverse;
    }
    .elementor-testimonial--layout-image_inline.elementor-testimonial--align-right
      .elementor-testimonial__image
      + cite,
    .elementor-testimonial--layout-image_above.elementor-testimonial--align-right
      .elementor-testimonial__image
      + cite {
      margin-right: 20px;
      margin-left: 0;
    }
    .elementor-testimonial--layout-image_inline.elementor-testimonial--align-left
      .elementor-testimonial__footer,
    .elementor-testimonial--layout-image_above.elementor-testimonial--align-left
      .elementor-testimonial__footer {
      flex-direction: row;
    }
    .elementor-testimonial--layout-image_inline.elementor-testimonial--align-left
      .elementor-testimonial__image
      + cite,
    .elementor-testimonial--layout-image_above.elementor-testimonial--align-left
      .elementor-testimonial__image
      + cite {
      margin-right: 0;
      margin-left: 20px;
    }
    .elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
      margin-top: 25px;
    }
    .elementor-testimonial--layout-image_above .elementor-testimonial {
      display: flex;
      flex-direction: column-reverse;
    }
    .elementor-testimonial--layout-image_above .elementor-testimonial__footer {
      margin-bottom: 25px;
    }
    .elementor-testimonial--layout-image_stacked
      .elementor-testimonial__footer {
      margin-top: 25px;
      flex-wrap: wrap;
    }
    .elementor-testimonial--layout-image_stacked .elementor-testimonial__image,
    .elementor-testimonial--layout-image_stacked .elementor-testimonial cite {
      margin-top: 15px;
      width: 100%;
    }
    .elementor-testimonial--layout-image_left .elementor-testimonial,
    .elementor-testimonial--layout-image_right .elementor-testimonial {
      display: flex;
      align-items: stretch;
    }
    .elementor-testimonial--layout-image_left .elementor-testimonial__footer,
    .elementor-testimonial--layout-image_right .elementor-testimonial__footer {
      flex-shrink: 0;
      flex-direction: column;
      text-align: center;
    }
    .elementor-testimonial--layout-image_left
      .elementor-testimonial__image
      + cite,
    .elementor-testimonial--layout-image_right
      .elementor-testimonial__image
      + cite {
      margin-top: 15px;
    }
    .elementor-testimonial--layout-image_left.elementor-testimonial--align-center
      .elementor-testimonial__footer,
    .elementor-testimonial--layout-image_right.elementor-testimonial--align-center
      .elementor-testimonial__footer {
      justify-content: flex-start;
    }
    .elementor-testimonial--layout-image_left .elementor-testimonial {
      flex-direction: row-reverse;
    }
    .elementor-testimonial--layout-image_left .elementor-testimonial__footer {
      padding-right: 20px;
    }
    .elementor-testimonial--layout-image_right .elementor-testimonial {
      flex-direction: row;
    }
    .elementor-testimonial--layout-image_right .elementor-testimonial__footer {
      padding-left: 20px;
    }
    .elementor-testimonial--align-right .elementor-testimonial {
      text-align: right;
    }
    .elementor-testimonial--align-right.elementor-testimonial--layout-image_inline
      .elementor-testimonial__footer,
    .elementor-testimonial--align-right.elementor-testimonial--layout-image_above
      .elementor-testimonial__footer {
      flex-direction: row-reverse;
      justify-content: end;
    }
    .elementor-testimonial--align-right.elementor-testimonial--layout-image_inline
      .elementor-testimonial__image
      + cite,
    .elementor-testimonial--align-right.elementor-testimonial--layout-image_above
      .elementor-testimonial__image
      + cite {
      margin-right: 20px;
      margin-left: 0;
    }
    .elementor-testimonial--align-left .elementor-testimonial {
      text-align: left;
    }
    .elementor-testimonial--align-left.elementor-testimonial--layout-image_inline
      .elementor-testimonial__footer,
    .elementor-testimonial--align-left.elementor-testimonial--layout-image_above
      .elementor-testimonial__footer {
      flex-direction: row;
      justify-content: start;
    }
    .elementor-testimonial--align-left.elementor-testimonial--layout-image_inline
      .elementor-testimonial__image
      + cite,
    .elementor-testimonial--align-left.elementor-testimonial--layout-image_above
      .elementor-testimonial__image
      + cite {
      margin-right: 0;
      margin-left: 20px;
    }
    .elementor-testimonial--align-center .elementor-testimonial {
      text-align: center;
    }
    .elementor-testimonial--align-center.elementor-testimonial--layout-image_inline
      .elementor-testimonial__footer,
    .elementor-testimonial--align-center.elementor-testimonial--layout-image_above
      .elementor-testimonial__footer {
      justify-content: center;
    }
    .elementor-testimonial--align-center.elementor-testimonial--layout-image_inline
      .elementor-testimonial__image
      + cite {
      text-align: left;
    }
    .elementor-testimonial--skin-default.elementor-testimonial--layout-image_right
      .elementor-testimonial__cite,
    .elementor-testimonial--skin-default.elementor-testimonial--layout-image_left
      .elementor-testimonial__cite,
    .elementor-testimonial--skin-default.elementor-testimonial--layout-image_above
      .elementor-testimonial__cite {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
    }
    .elementor-testimonial--skin-bubble .elementor-testimonial__content {
      background-color: #f9fafa;
      padding: 20px;
      position: relative;
    }
    .elementor-testimonial--skin-bubble .elementor-testimonial__content:after {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background-color: #f9fafa;
      position: absolute;
      transform: scaleX(0.75) rotate(45deg);
      border-width: 0;
      box-sizing: content-box;
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above
      .elementor-testimonial__content:after {
      bottom: calc(100% - 7px);
      transform: scaleX(0.75) rotate(-135deg);
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above.elementor-testimonial--align-center
      .elementor-testimonial__content:after {
      left: 50%;
      transform: translateX(-50%) scaleX(0.75) rotate(-135deg);
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked
      .elementor-testimonial__content:after,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline
      .elementor-testimonial__content:after {
      top: calc(100% - 7px);
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-center
      .elementor-testimonial__content:after,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline.elementor-testimonial--align-center
      .elementor-testimonial__content:after {
      left: 50%;
      transform: translateX(-50%) scaleX(0.75) rotate(45deg);
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above.elementor-testimonial--align-left
      .elementor-testimonial__content:after,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-left
      .elementor-testimonial__content:after,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline.elementor-testimonial--align-left
      .elementor-testimonial__content:after {
      left: calc(20px + 50px / 2);
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above.elementor-testimonial--align-right
      .elementor-testimonial__content:after,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-right
      .elementor-testimonial__content:after,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline.elementor-testimonial--align-right
      .elementor-testimonial__content:after {
      right: calc(20px + 50px / 2);
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_left
      .elementor-testimonial__content:after {
      transform: scaleY(0.75) rotate(135deg);
      left: -8px;
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_right
      .elementor-testimonial__content:after {
      transform: scaleY(0.75) rotate(-45deg);
      right: -8px;
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_left
      .elementor-testimonial__content:after,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_right
      .elementor-testimonial__content:after {
      top: calc(20px + 50px / 2);
    }
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_left
      .elementor-testimonial__footer,
    .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_right
      .elementor-testimonial__footer {
      padding-top: 20px;
    }

    @media (min-width: -1) {
      .elementor-testimonial--widescreen-align-right .elementor-testimonial {
        text-align: right;
      }
      .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end;
      }
      .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 20px;
        margin-left: 0;
      }
      .elementor-testimonial--widescreen-align-left .elementor-testimonial {
        text-align: left;
      }
      .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start;
      }
      .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 0;
        margin-left: 20px;
      }
      .elementor-testimonial--widescreen-align-center .elementor-testimonial {
        text-align: center;
      }
      .elementor-testimonial--widescreen-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--widescreen-align-center.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        justify-content: center;
      }
      .elementor-testimonial--widescreen-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite {
        text-align: left;
      }
    }
    @media (max-width: -1) {
      .elementor-testimonial--laptop-align-right .elementor-testimonial {
        text-align: right;
      }
      .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end;
      }
      .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 20px;
        margin-left: 0;
      }
      .elementor-testimonial--laptop-align-left .elementor-testimonial {
        text-align: left;
      }
      .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start;
      }
      .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 0;
        margin-left: 20px;
      }
      .elementor-testimonial--laptop-align-center .elementor-testimonial {
        text-align: center;
      }
      .elementor-testimonial--laptop-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--laptop-align-center.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        justify-content: center;
      }
      .elementor-testimonial--laptop-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite {
        text-align: left;
      }
    }
    @media (max-width: -1) {
      .elementor-testimonial--tablet_extra-align-right .elementor-testimonial {
        text-align: right;
      }
      .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end;
      }
      .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 20px;
        margin-left: 0;
      }
      .elementor-testimonial--tablet_extra-align-left .elementor-testimonial {
        text-align: left;
      }
      .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start;
      }
      .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 0;
        margin-left: 20px;
      }
      .elementor-testimonial--tablet_extra-align-center .elementor-testimonial {
        text-align: center;
      }
      .elementor-testimonial--tablet_extra-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--tablet_extra-align-center.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        justify-content: center;
      }
      .elementor-testimonial--tablet_extra-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite {
        text-align: left;
      }
    }
    @media (max-width: 1024px) {
      .elementor-testimonial--tablet-align-right .elementor-testimonial {
        text-align: right;
      }
      .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end;
      }
      .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 20px;
        margin-left: 0;
      }
      .elementor-testimonial--tablet-align-left .elementor-testimonial {
        text-align: left;
      }
      .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start;
      }
      .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 0;
        margin-left: 20px;
      }
      .elementor-testimonial--tablet-align-center .elementor-testimonial {
        text-align: center;
      }
      .elementor-testimonial--tablet-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--tablet-align-center.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        justify-content: center;
      }
      .elementor-testimonial--tablet-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite {
        text-align: left;
      }
    }
    @media (max-width: -1) {
      .elementor-testimonial--mobile_extra-align-right .elementor-testimonial {
        text-align: right;
      }
      .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end;
      }
      .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 20px;
        margin-left: 0;
      }
      .elementor-testimonial--mobile_extra-align-left .elementor-testimonial {
        text-align: left;
      }
      .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start;
      }
      .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 0;
        margin-left: 20px;
      }
      .elementor-testimonial--mobile_extra-align-center .elementor-testimonial {
        text-align: center;
      }
      .elementor-testimonial--mobile_extra-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--mobile_extra-align-center.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        justify-content: center;
      }
      .elementor-testimonial--mobile_extra-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite {
        text-align: left;
      }
    }
    @media (max-width: 767px) {
      .elementor-testimonial--mobile-align-right .elementor-testimonial {
        text-align: right;
      }
      .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end;
      }
      .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 20px;
        margin-left: 0;
      }
      .elementor-testimonial--mobile-align-left .elementor-testimonial {
        text-align: left;
      }
      .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start;
      }
      .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite,
      .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_above
        .elementor-testimonial__image
        + cite {
        margin-right: 0;
        margin-left: 20px;
      }
      .elementor-testimonial--mobile-align-center .elementor-testimonial {
        text-align: center;
      }
      .elementor-testimonial--mobile-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__footer,
      .elementor-testimonial--mobile-align-center.elementor-testimonial--layout-image_above
        .elementor-testimonial__footer {
        justify-content: center;
      }
      .elementor-testimonial--mobile-align-center.elementor-testimonial--layout-image_inline
        .elementor-testimonial__image
        + cite {
        text-align: left;
      }
    }
    .elementor-widget-testimonial-carousel .swiper-slide {
      padding: 20px;
    }
    .elementor-widget-testimonial-carousel .elementor-testimonial__text {
      font-size: 1.3em;
      font-style: italic;
      line-height: 1.5;
    }
    .elementor-widget-testimonial-carousel .elementor-testimonial__image img {
      width: 50px;
      height: 50px;
    }

    .elementor-widget-reviews .swiper-slide {
      background-color: white;
      border: 1px solid #e1e8ed;
      border-radius: 5px;
      padding: 0;
    }
    .elementor-widget-reviews .elementor-testimonial__text {
      font-size: 14px;
      line-height: 20px;
    }
    .elementor-widget-reviews .elementor-testimonial__name {
      color: #1c2022;
    }
    .elementor-widget-reviews .elementor-testimonial__title {
      color: #697882;
      font-size: 12.5px;
      font-weight: normal;
      font-family: inherit;
    }
    .elementor-widget-reviews .elementor-testimonial__image {
      display: flex;
    }
    .elementor-widget-reviews .elementor-testimonial__image img {
      width: 36px;
      height: 36px;
    }
    .elementor-widget-reviews .elementor-testimonial__icon {
      margin-left: auto;
      font-size: 17px;
    }
    .elementor-widget-reviews .elementor-testimonial__icon .fa + .fa {
      margin-left: 0.15em;
    }
    .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon {
      color: var(--e-testimonial-icon-color);
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-android {
      --e-testimonial-icon-color: #a4c639;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-apple {
      --e-testimonial-icon-color: #999999;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-behance {
      --e-testimonial-icon-color: #1769ff;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-bitbucket {
      --e-testimonial-icon-color: #205081;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-codepen {
      --e-testimonial-icon-color: #000000;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-delicious {
      --e-testimonial-icon-color: #3399ff;
    }
    .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-digg {
      --e-testimonial-icon-color: #005be2;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-dribbble {
      --e-testimonial-icon-color: #ea4c89;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-envelope {
      --e-testimonial-icon-color: #ea4335;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-facebook {
      --e-testimonial-icon-color: #3b5998;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-flickr {
      --e-testimonial-icon-color: #0063dc;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-foursquare {
      --e-testimonial-icon-color: #2d5be3;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-github {
      --e-testimonial-icon-color: #333333;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-google-plus {
      --e-testimonial-icon-color: #dd4b39;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-houzz {
      --e-testimonial-icon-color: #7ac142;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-instagram {
      --e-testimonial-icon-color: #262626;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-jsfiddle {
      --e-testimonial-icon-color: #487aa2;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-linkedin {
      --e-testimonial-icon-color: #0077b5;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-medium {
      --e-testimonial-icon-color: #00ab6b;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-meetup {
      --e-testimonial-icon-color: #ec1c40;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-mixcloud {
      --e-testimonial-icon-color: #273a4b;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-odnoklassniki {
      --e-testimonial-icon-color: #f4731c;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-pinterest {
      --e-testimonial-icon-color: #bd081c;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-product-hunt {
      --e-testimonial-icon-color: #da552f;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-reddit {
      --e-testimonial-icon-color: #ff4500;
    }
    .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-rss {
      --e-testimonial-icon-color: #f26522;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-shopping-cart {
      --e-testimonial-icon-color: #4caf50;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-skype {
      --e-testimonial-icon-color: #00aff0;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-slideshare {
      --e-testimonial-icon-color: #0077b5;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-snapchat {
      --e-testimonial-icon-color: #fffc00;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-soundcloud {
      --e-testimonial-icon-color: #ff8800;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-spotify {
      --e-testimonial-icon-color: #2ebd59;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-stack-overflow {
      --e-testimonial-icon-color: #fe7a15;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-steam {
      --e-testimonial-icon-color: #00adee;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-stumbleupon {
      --e-testimonial-icon-color: #eb4924;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-telegram {
      --e-testimonial-icon-color: #2ca5e0;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-thumb-tack,
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-thumbtack {
      --e-testimonial-icon-color: #1aa1d8;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-tripadvisor {
      --e-testimonial-icon-color: #589442;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-tumblr {
      --e-testimonial-icon-color: #35465c;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-twitch {
      --e-testimonial-icon-color: #6441a5;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-twitter {
      --e-testimonial-icon-color: #1da1f2;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-vimeo {
      --e-testimonial-icon-color: #1ab7ea;
    }
    .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-vk {
      --e-testimonial-icon-color: #45668e;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-weibo {
      --e-testimonial-icon-color: #dd2430;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-weixin {
      --e-testimonial-icon-color: #31a918;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-whatsapp {
      --e-testimonial-icon-color: #25d366;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-wordpress {
      --e-testimonial-icon-color: #21759b;
    }
    .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-xing {
      --e-testimonial-icon-color: #026466;
    }
    .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-yelp {
      --e-testimonial-icon-color: #af0606;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-youtube {
      --e-testimonial-icon-color: #cd201f;
    }
    .elementor-widget-reviews
      .elementor-testimonial__icon.elementor-icon-500px {
      --e-testimonial-icon-color: #0099e5;
    }
    .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon svg {
      fill: var(--e-testimonial-icon-color);
    }
    .elementor-widget-reviews .elementor-testimonial__rating {
      background-image: linear-gradient(
        to right,
        #ffc82c var(--rating),
        #cccccc var(--rating)
      );
      font-family: eicons;
    }
    .elementor-widget-reviews .elementor-testimonial__header {
      display: flex;
      padding-top: 15px;
      padding-bottom: 6px;
    }
    .elementor-widget-reviews .elementor-testimonial__image + cite {
      margin-left: 9px;
    }
    .elementor-widget-reviews .elementor-testimonial__separator {
      margin: 0 auto;
      border-top: 0 none;
      border-bottom: 1px solid #e1e8ed;
      height: 0;
    }
    .elementor-widget-reviews .elementor-testimonial__content {
      padding-top: 6px;
      padding-bottom: 15px;
    }
    .elementor-widget-reviews .elementor-testimonial__content,
    .elementor-widget-reviews .elementor-testimonial__header {
      padding-left: 15px;
      padding-right: 15px;
    }
    .elementor-widget-reviews.elementor-review--has-separator
      .elementor-testimonial__header {
      border-bottom: 1px solid #e1e8ed;
    }

    .swiper-pagination-bullets {
      color: #fff;
      cursor: default;
    }

    .swiper-slide {
      will-change: transform;
      border-style: solid;
      border-width: 0;
      transition-duration: 0.5s;
      transition-property: border, background, transform;
      overflow: hidden;
    }
    .swiper-slide:not(:hover) .e-overlay-animation-fade {
      opacity: 0;
    }
    .swiper-slide:not(:hover) .e-overlay-animation-slide-up {
      transform: translateY(100%);
    }
    .swiper-slide:not(:hover) .e-overlay-animation-slide-down {
      transform: translateY(-100%);
    }
    .swiper-slide:not(:hover) .e-overlay-animation-slide-right {
      transform: translateX(-100%);
    }
    .swiper-slide:not(:hover) .e-overlay-animation-slide-left {
      transform: translateX(100%);
    }
    .swiper-slide:not(:hover) .e-overlay-animation-zoom-in {
      transform: scale(0.5);
      opacity: 0;
    }
    .swiper-slide a {
      display: inline;
    }
    .swiper-slide a.elementor-button {
      display: inline-block;
    }

    .elementor-widget-testimonial-carousel
      .elementor-arrows-yes
      .elementor-main-swiper,
    .elementor-widget-reviews .elementor-arrows-yes .elementor-main-swiper {
      width: calc(95% - 40px);
    }
    .elementor-widget-testimonial-carousel .elementor-main-swiper,
    .elementor-widget-reviews .elementor-main-swiper {
      width: 95%;
    }
    .elementor-widget-testimonial-carousel .swiper-wrapper,
    .elementor-widget-reviews .swiper-wrapper {
      display: flex;
      align-items: stretch;
    }
    .elementor-widget-testimonial-carousel .swiper-slide,
    .elementor-widget-reviews .swiper-slide {
      height: auto;
    }
    .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets
      .swiper-container,
    .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets
      .swiper,
    .elementor-widget-testimonial-carousel.elementor-pagination-type-fraction
      .swiper-container,
    .elementor-widget-testimonial-carousel.elementor-pagination-type-fraction
      .swiper,
    .elementor-widget-reviews.elementor-pagination-type-bullets
      .swiper-container,
    .elementor-widget-reviews.elementor-pagination-type-bullets .swiper,
    .elementor-widget-reviews.elementor-pagination-type-fraction
      .swiper-container,
    .elementor-widget-reviews.elementor-pagination-type-fraction .swiper {
      padding-bottom: 40px;
    }
    .elementor-widget-testimonial-carousel .elementor-swiper-button-prev,
    .elementor-widget-testimonial-carousel
      .swiper-container-rtl
      .elementor-swiper-button-next,
    .elementor-widget-testimonial-carousel
      .swiper-rtl
      .elementor-swiper-button-next,
    .elementor-widget-reviews .elementor-swiper-button-prev,
    .elementor-widget-reviews
      .swiper-container-rtl
      .elementor-swiper-button-next,
    .elementor-widget-reviews .swiper-rtl .elementor-swiper-button-next {
      left: 0;
      right: unset;
    }
    .elementor-widget-testimonial-carousel .elementor-swiper-button-next,
    .elementor-widget-testimonial-carousel
      .swiper-container-rtl
      .elementor-swiper-button-prev,
    .elementor-widget-testimonial-carousel
      .swiper-rtl
      .elementor-swiper-button-prev,
    .elementor-widget-reviews .elementor-swiper-button-next,
    .elementor-widget-reviews
      .swiper-container-rtl
      .elementor-swiper-button-prev,
    .elementor-widget-reviews .swiper-rtl .elementor-swiper-button-prev {
      right: 0;
      left: unset;
    }
    .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets
      .elementor-swiper-button-next,
    .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets
      .elementor-swiper-button-prev,
    .elementor-widget-reviews.elementor-pagination-type-bullets
      .elementor-swiper-button-next,
    .elementor-widget-reviews.elementor-pagination-type-bullets
      .elementor-swiper-button-prev {
      top: calc(50% - 40px / 2);
    }

    .elementor-widget-media-carousel .elementor-swiper-button-prev,
    .elementor-widget-media-carousel
      .swiper-container-rtl
      .elementor-swiper-button-next,
    .elementor-widget-media-carousel .swiper-rtl .elementor-swiper-button-next {
      left: 10px;
      right: unset;
    }
    .elementor-widget-media-carousel .elementor-swiper-button-next,
    .elementor-widget-media-carousel
      .swiper-container-rtl
      .elementor-swiper-button-prev,
    .elementor-widget-media-carousel .swiper-rtl .elementor-swiper-button-prev {
      right: 10px;
      left: unset;
    }

    .entry-content
      blockquote.elementor-blockquote:not(.alignright):not(.alignleft),
    .entry-summary blockquote.elementor-blockquote {
      margin-right: 0;
      margin-left: 0;
    }

    .elementor-widget-blockquote blockquote {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
      quotes: none;
      border-left: 0 none;
      border-right: 0 none;
      border-top: 0 none;
      border-bottom: 0 none;
      font-style: normal;
      color: #3f444b;
    }
    .elementor-widget-blockquote blockquote:before,
    .elementor-widget-blockquote blockquote:after,
    .elementor-widget-blockquote blockquote .e-q-footer:before,
    .elementor-widget-blockquote blockquote .e-q-footer:after,
    .elementor-widget-blockquote blockquote cite:before,
    .elementor-widget-blockquote blockquote cite:after {
      content: none;
    }

    .elementor-blockquote {
      transition: 0.3s;
    }
    .elementor-blockquote__author,
    .elementor-blockquote__content {
      margin-bottom: 0;
      font-style: normal;
    }
    .elementor-blockquote__author {
      font-weight: bold;
    }
    .elementor-blockquote .e-q-footer {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
    }
    .elementor-blockquote__tweet-button {
      display: flex;
      transition: 0.3s;
      color: #1da1f2;
      align-self: flex-end;
      line-height: 1;
      position: relative;
      width: -moz-max-content;
      width: max-content;
    }
    .elementor-blockquote__tweet-button:hover {
      color: #0967a0;
    }
    .elementor-blockquote__tweet-button span {
      font-weight: 600;
    }
    .elementor-blockquote__tweet-button i,
    .elementor-blockquote__tweet-button span {
      vertical-align: middle;
    }
    .elementor-blockquote__tweet-button i + span,
    .elementor-blockquote__tweet-button svg + span {
      margin-left: 0.5em;
    }
    .elementor-blockquote__tweet-button svg {
      fill: #1da1f2;
      height: 1em;
      width: 1em;
    }
    .elementor-blockquote__tweet-label {
      white-space: pre-wrap;
    }
    .elementor-blockquote--button-skin-classic
      .elementor-blockquote__tweet-button,
    .elementor-blockquote--button-skin-bubble
      .elementor-blockquote__tweet-button {
      padding: 0.7em 1.2em;
      border-radius: 100em;
      background-color: #1da1f2;
      color: #fff;
      font-size: 15px;
    }
    .elementor-blockquote--button-skin-classic
      .elementor-blockquote__tweet-button:hover,
    .elementor-blockquote--button-skin-bubble
      .elementor-blockquote__tweet-button:hover {
      background-color: #0967a0;
      color: #fff;
    }
    .elementor-blockquote--button-skin-classic
      .elementor-blockquote__tweet-button:hover:before,
    .elementor-blockquote--button-skin-bubble
      .elementor-blockquote__tweet-button:hover:before {
      border-right-color: #0967a0;
    }
    .elementor-blockquote--button-skin-classic
      .elementor-blockquote__tweet-button
      svg,
    .elementor-blockquote--button-skin-bubble
      .elementor-blockquote__tweet-button
      svg {
      fill: #fff;
      height: 1em;
      width: 1em;
    }
    .elementor-blockquote--button-skin-classic.elementor-blockquote--button-view-icon
      .elementor-blockquote__tweet-button,
    .elementor-blockquote--button-skin-bubble.elementor-blockquote--button-view-icon
      .elementor-blockquote__tweet-button {
      padding: 0;
      width: 2em;
      height: 2em;
    }
    .elementor-blockquote--button-skin-classic.elementor-blockquote--button-view-icon
      .elementor-blockquote__tweet-button
      i,
    .elementor-blockquote--button-skin-bubble.elementor-blockquote--button-view-icon
      .elementor-blockquote__tweet-button
      i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .elementor-blockquote--button-skin-bubble
      .elementor-blockquote__tweet-button:before {
      content: '';
      border: solid 0.5em transparent;
      border-right-color: #1da1f2;
      position: absolute;
      left: -0.8em;
      top: 50%;
      transform: translateY(-50%) scale(1, 0.65);
      transition: 0.3s;
    }
    .elementor-blockquote--button-skin-bubble.elementor-blockquote--align-left
      .elementor-blockquote__tweet-button:before {
      right: auto;
      left: -0.8em;
      border-right-color: #1da1f2;
      border-left-color: transparent;
    }
    .elementor-blockquote--button-skin-bubble.elementor-blockquote--align-left
      .elementor-blockquote__tweet-button:hover:before {
      border-right-color: #0967a0;
    }
    .elementor-blockquote--button-skin-bubble.elementor-blockquote--align-right
      .elementor-blockquote__tweet-button:before {
      left: auto;
      right: -0.8em;
      border-right-color: transparent;
      border-left-color: #1da1f2;
    }
    .elementor-blockquote--button-skin-bubble.elementor-blockquote--align-right
      .elementor-blockquote__tweet-button:hover:before {
      border-left-color: #0967a0;
    }
    .elementor-blockquote--skin-boxed .elementor-blockquote {
      background-color: #f9fafa;
      padding: 30px;
    }
    .elementor-blockquote--skin-border .elementor-blockquote {
      border-color: #f9fafa;
      border-style: solid;
      border-left-width: 7px;
      padding-left: 20px;
    }
    .elementor-blockquote--skin-quotation .elementor-blockquote:before {
      content: '“';
      font-size: 100px;
      color: #f9fafa;
      font-family: 'Times New Roman', Times, serif;
      font-weight: 900;
      line-height: 1;
      display: block;
      height: 0.6em;
    }
    .elementor-blockquote--skin-quotation .elementor-blockquote__content {
      margin-top: 15px;
    }
    .elementor-blockquote--align-left .elementor-blockquote__content {
      text-align: left;
    }
    .elementor-blockquote--align-left .elementor-blockquote .e-q-footer {
      flex-direction: row;
    }
    .elementor-blockquote--align-right .elementor-blockquote__content {
      text-align: right;
    }
    .elementor-blockquote--align-right .elementor-blockquote .e-q-footer {
      flex-direction: row-reverse;
    }
    .elementor-blockquote--align-center .elementor-blockquote {
      text-align: center;
    }
    .elementor-blockquote--align-center .elementor-blockquote .e-q-footer {
      display: block;
    }
    .elementor-blockquote--align-center .elementor-blockquote__author {
      display: block;
    }
    .elementor-blockquote--align-center .elementor-blockquote__tweet-button {
      margin-right: auto;
      margin-left: auto;
    }

    .elementor-headline {
      font-size: 43px;
      line-height: 1.2;
      display: block;
    }
    .elementor-headline-dynamic-text {
      color: var(--dynamic-text-color, #aebcb9);
    }
    .elementor-headline--style-rotate .elementor-headline-dynamic-wrapper {
      text-align: left;
    }
    .elementor-headline--style-highlight .elementor-headline-dynamic-wrapper {
      overflow: visible;
      text-align: inherit;
    }
    .elementor-headline--style-highlight .elementor-headline-dynamic-text {
      z-index: 1;
    }
    .elementor-headline--style-highlight .elementor-headline-plain-text {
      z-index: 1;
      position: relative;
    }
    .elementor-headline--style-highlight svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      transform: translate(-50%, -50%);
      overflow: visible;
    }
    .elementor-headline--style-highlight svg path {
      stroke: red;
      stroke-width: 9;
      fill: none;
      opacity: 0;
      stroke-dasharray: 0 1500;
      transition: 0.3s;
    }
    .elementor-headline.e-animated svg path {
      animation: elementor-headline-dash forwards;
      animation-duration: var(--animation-duration, 1.2s);
      animation-iteration-count: 1;
    }
    .elementor-headline.e-animated svg path:nth-of-type(2) {
      animation-delay: calc(var(--animation-duration, 1.2s) / 2);
    }
    .elementor-headline.e-hide-highlight svg path {
      opacity: 1;
      stroke-dasharray: 1500 1500;
      animation: hide-highlight 0.4s forwards;
      animation-iteration-count: 1;
    }

    @keyframes elementor-headline-dash {
      from {
        stroke-dasharray: 0 1500;
        opacity: 1;
      }
      to {
        stroke-dasharray: 1500 1500;
        opacity: 1;
      }
    }
    @keyframes hide-highlight {
      from {
        opacity: 1;
        stroke-dasharray: 1500 1500;
      }
      to {
        opacity: 0;
        filter: blur(10px);
      }
    }
    .elementor-headline-text-wrapper {
      vertical-align: bottom;
    }

    .elementor-headline-dynamic-wrapper {
      display: inline-block;
      position: relative;
    }
    .elementor-headline-dynamic-wrapper .elementor-headline-dynamic-text {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
    .elementor-headline-dynamic-wrapper
      .elementor-headline-dynamic-text.elementor-headline-text-active {
      position: relative;
    }

    .elementor-headline-animation-type-flip
      .elementor-headline-dynamic-wrapper {
      perspective: 300px;
    }
    .elementor-headline-animation-type-flip .elementor-headline-dynamic-text {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: rotateX(180deg);
    }
    .elementor-headline-animation-type-flip
      .elementor-headline-dynamic-text.elementor-headline-text-active {
      opacity: 1;
      transform: rotateX(0deg);
      animation: elementor-headline-flip-in 1.2s;
    }
    .elementor-headline-animation-type-flip
      .elementor-headline-dynamic-text.elementor-headline-text-inactive {
      transform: rotateX(180deg);
      animation: elementor-headline-flip-out 1.2s;
    }

    @keyframes elementor-headline-flip-in {
      0% {
        transform: rotateX(180deg);
        opacity: 0;
      }
      35% {
        transform: rotateX(120deg);
        opacity: 0;
      }
      65% {
        opacity: 0;
      }
      100% {
        transform: rotateX(360deg);
        opacity: 1;
      }
    }
    @keyframes elementor-headline-flip-out {
      0% {
        transform: rotateX(0deg);
        opacity: 1;
      }
      35% {
        transform: rotateX(-40deg);
        opacity: 1;
      }
      65% {
        opacity: 0;
      }
      100% {
        transform: rotateX(180deg);
        opacity: 0;
      }
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-wrapper {
      vertical-align: top;
      overflow: hidden;
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-wrapper::after {
      /* vertical bar */
      content: '';
      position: absolute;
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 90%;
      width: 1px;
      background-color: #0d0d0d;
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-wrapper:not(.elementor-headline-typing-selected):after {
      animation: elementor-headline-pulse 1s infinite;
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-wrapper.elementor-headline-typing-selected {
      background-color: var(--typing-selected-bg-color, #0d0d0d);
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-wrapper.elementor-headline-typing-selected::after {
      visibility: hidden;
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-wrapper.elementor-headline-typing-selected
      .elementor-headline-dynamic-text {
      color: var(--typing-selected-color, var(--dynamic-text-color), #aebcb9);
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-text:not(.elementor-headline-text-active) {
      visibility: hidden;
    }
    .elementor-headline-animation-type-typing
      .elementor-headline-dynamic-letter:not(.elementor-headline-animation-in) {
      position: absolute;
      visibility: hidden;
    }

    @keyframes elementor-headline-pulse {
      0% {
        transform: translateY(-50%) scale(1);
        opacity: 1;
      }
      40% {
        transform: translateY(-50%) scale(0.9);
        opacity: 0;
      }
      100% {
        transform: translateY(-50%) scale(0);
        opacity: 0;
      }
    }
    .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-wrapper {
      perspective: 300px;
    }
    .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-letter {
      display: inline-block;
      backface-visibility: hidden;
    }
    .elementor-headline-animation-type-swirl .elementor-headline-dynamic-text {
      transform: scaleX(1.0005);
      opacity: 0;
    }
    .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-letter {
      transform-style: preserve-3d;
      transform: translateZ(-20px) rotateX(90deg);
      opacity: 0;
    }
    .elementor-headline-text-active
      .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-letter {
      opacity: 1;
    }
    .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-letter.elementor-headline-animation-in {
      animation: elementor-headline-swirl-in 0.4s forwards;
    }
    .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-letter:not(.elementor-headline-animation-in) {
      animation: elementor-headline-swirl-out 0.4s forwards;
    }

    @keyframes elementor-headline-swirl-in {
      0% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(90deg);
      }
      60% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(-10deg);
      }
      100% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(0deg);
      }
    }
    @keyframes elementor-headline-swirl-out {
      0% {
        opacity: 1;
        transform: translateZ(-20px) rotateX(0);
      }
      60% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(-100deg);
      }
      100% {
        opacity: 0;
        transform: translateZ(-20px) rotateX(-90deg);
      }
    }
    .elementor-headline-animation-type-slide-down
      .elementor-headline-text-wrapper {
      display: inline-block;
      padding: 0.2em 0;
    }
    .elementor-headline-animation-type-slide-down
      .elementor-headline-dynamic-wrapper {
      overflow: hidden;
      vertical-align: top;
    }
    .elementor-headline-animation-type-slide-down
      .elementor-headline-dynamic-text {
      opacity: 0;
      top: 0.2em;
    }
    .elementor-headline-animation-type-slide-down
      .elementor-headline-dynamic-text.elementor-headline-text-active {
      top: 0;
      opacity: 1;
      animation: elementor-headline-slide-down-in 0.6s;
    }
    .elementor-headline-animation-type-slide-down
      .elementor-headline-dynamic-text.elementor-headline-text-inactive {
      animation: elementor-headline-slide-down-out 0.6s;
    }

    @keyframes elementor-headline-slide-down-in {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      60% {
        opacity: 1;
        transform: translateY(20%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @keyframes elementor-headline-slide-down-out {
      0% {
        opacity: 1;
        transform: translateY(0);
      }
      60% {
        opacity: 0;
        transform: translateY(120%);
      }
      100% {
        opacity: 0;
        transform: translateY(100%);
      }
    }
    .elementor-headline-animation-type-clip .elementor-headline-text-wrapper {
      display: inline-block;
      padding: 0.2em 0;
    }
    .elementor-headline-animation-type-clip
      .elementor-headline-dynamic-wrapper {
      overflow: hidden;
      vertical-align: top;
    }
    .elementor-headline-animation-type-clip
      .elementor-headline-dynamic-wrapper::after {
      /* line */
      content: '';
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      width: 2px;
      height: 100%;
      background-color: #0d0d0d;
    }
    .elementor-headline-animation-type-clip .elementor-headline-dynamic-text {
      opacity: 0;
    }
    .elementor-headline-animation-type-clip
      .elementor-headline-dynamic-text.elementor-headline-text-active {
      opacity: 1;
    }

    .elementor-headline-animation-type-drop-in
      .elementor-headline-dynamic-wrapper {
      perspective: 300px;
    }
    .elementor-headline-animation-type-drop-in
      .elementor-headline-dynamic-text {
      opacity: 0;
    }
    .elementor-headline-animation-type-drop-in
      .elementor-headline-dynamic-text.elementor-headline-text-active {
      opacity: 1;
      animation: elementor-headline-drop-in-in 0.8s;
    }
    .elementor-headline-animation-type-drop-in
      .elementor-headline-dynamic-text.elementor-headline-text-inactive {
      animation: elementor-headline-drop-in-out 0.8s;
    }

    @keyframes elementor-headline-drop-in-in {
      0% {
        opacity: 0;
        transform: translateZ(100px);
      }
      100% {
        opacity: 1;
        transform: translateZ(0);
      }
    }
    @keyframes elementor-headline-drop-in-out {
      0% {
        opacity: 1;
        transform: translateZ(0);
      }
      100% {
        opacity: 0;
        transform: translateZ(-100px);
      }
    }
    .elementor-headline-animation-type-blinds
      .elementor-headline-dynamic-wrapper {
      perspective: 300px;
    }
    .elementor-headline-animation-type-blinds .elementor-headline-dynamic-text {
      opacity: 0;
    }
    .elementor-headline-animation-type-blinds
      .elementor-headline-dynamic-letter {
      display: inline-block;
      transform: rotateY(180deg);
      backface-visibility: hidden;
    }
    .elementor-headline-text-active
      .elementor-headline-animation-type-blinds
      .elementor-headline-dynamic-letter {
      transform: rotateY(0deg);
    }
    .elementor-headline-animation-type-blinds
      .elementor-headline-dynamic-letter.elementor-headline-animation-in {
      animation: elementor-headline-blinds-in 0.6s forwards;
    }
    .elementor-headline-animation-type-blinds
      .elementor-headline-dynamic-letter:not(.elementor-headline-animation-in) {
      animation: elementor-headline-blinds-out 0.6s forwards;
    }

    @keyframes elementor-headline-blinds-in {
      0% {
        transform: rotateY(180deg);
      }
      100% {
        transform: rotateY(0deg);
      }
    }
    @keyframes elementor-headline-blinds-out {
      0% {
        transform: rotateY(0);
      }
      100% {
        transform: rotateY(-180deg);
      }
    }
    .elementor-headline-animation-type-wave .elementor-headline-dynamic-text {
      opacity: 0;
    }
    .elementor-headline-animation-type-wave .elementor-headline-dynamic-letter {
      display: inline-block;
      opacity: 0;
      transform: scale(0);
    }
    .elementor-headline-text-active
      .elementor-headline-animation-type-wave
      .elementor-headline-dynamic-letter {
      opacity: 1;
    }
    .elementor-headline-animation-type-wave
      .elementor-headline-dynamic-letter.elementor-headline-animation-in {
      animation: elementor-headline-wave-up 0.6s forwards;
    }
    .elementor-headline-animation-type-wave
      .elementor-headline-dynamic-letter:not(.elementor-headline-animation-in) {
      animation: elementor-headline-wave-down 0.6s forwards;
    }

    @keyframes elementor-headline-wave-up {
      0% {
        transform: scale(0);
        opacity: 0;
      }
      60% {
        transform: scale(1.2);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    @keyframes elementor-headline-wave-down {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      60% {
        transform: scale(0);
        opacity: 0;
      }
    }
    .elementor-headline-animation-type-slide .elementor-headline-dynamic-text {
      opacity: 0;
    }
    .elementor-headline-animation-type-slide
      .elementor-headline-dynamic-text.elementor-headline-text-active {
      opacity: 1;
      animation: elementor-headline-slide-in 0.6s;
    }
    .elementor-headline-animation-type-slide
      .elementor-headline-dynamic-text.elementor-headline-text-inactive {
      animation: elementor-headline-slide-out 0.6s;
    }

    @keyframes elementor-headline-slide-in {
      0% {
        opacity: 0;
        transform: translateX(-100%);
      }
      60% {
        opacity: 1;
        transform: translateX(10%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
    @keyframes elementor-headline-slide-out {
      0% {
        opacity: 1;
        transform: translateX(0);
      }
      60% {
        opacity: 0;
        transform: translateX(110%);
      }
      100% {
        opacity: 0;
        transform: translateX(100%);
      }
    }
    .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-blinds
      .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-wave .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-slide
      .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-drop-in
      .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-slide-down
      .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-flip
      .elementor-headline-dynamic-wrapper {
      transition: width 0.5s;
    }

    .elementor-headline-animation-type-swirl
      .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-blinds
      .elementor-headline-dynamic-wrapper,
    .elementor-headline-animation-type-wave
      .elementor-headline-dynamic-wrapper {
      overflow: hidden;
    }
    .elementor-headline-animation-type-swirl .elementor-headline-dynamic-text,
    .elementor-headline-animation-type-blinds .elementor-headline-dynamic-text,
    .elementor-headline-animation-type-wave .elementor-headline-dynamic-text {
      white-space: nowrap;
    }

    .elementor-msie .elementor-headline--style-highlight svg {
      top: auto;
      left: auto;
      transform: translate(-100%);
    }

    /* Animations */
    @keyframes e-hotspot-soft-beat {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.1);
      }
    }
    @keyframes e-hotspot-expand {
      from {
        transform: scale(0.5);
        opacity: 1;
      }
      to {
        transform: scale(1.5);
        opacity: 0;
      }
    }
    /* Hotspot Wrapper */
    .elementor-widget-hotspot {
      display: flex;
      justify-content: var(--background-align);
    }
    .elementor-widget-hotspot .elementor-widget-container {
      position: relative;
      display: inline-block;
      width: var(--container-width);
      max-width: var(--container-max-width);
      height: var(--container-height);
    }
    .elementor-widget-hotspot .elementor-widget-container > img {
      display: block;
      width: var(--image-width, auto);
      height: var(--container-height);
      opacity: var(--opacity, 1);
    }

    /* Each Hotspot */
    .e-hotspot {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      /* Hotspot Button Animations */
      /* End: Hotspot Button Animations */
    }
    .e-hotspot--position-left.e-hotspot--position-top {
      transform: translate(
        calc(-1 * var(--hotspot-translate-x)),
        calc(-1 * var(--hotspot-translate-y))
      );
    }
    .e-hotspot--position-left.e-hotspot--position-bottom {
      transform: translate(
        calc(-1 * var(--hotspot-translate-x)),
        var(--hotspot-translate-y)
      );
    }
    .e-hotspot--position-right.e-hotspot--position-top {
      transform: translate(
        var(--hotspot-translate-x),
        calc(-1 * var(--hotspot-translate-y))
      );
    }
    .e-hotspot--position-right.e-hotspot--position-bottom {
      transform: translate(
        var(--hotspot-translate-x),
        var(--hotspot-translate-y)
      );
    }
    .e-hotspot--active {
      z-index: 2;
    }
    .e-hotspot__outer-circle,
    .e-hotspot__inner-circle {
      content: '';
      display: inline-block;
      box-sizing: content-box;
      border-radius: 50%;
    }
    .e-hotspot__inner-circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--hotspot-color);
      padding: calc(var(--hotspot-size, 18px) / 2);
    }
    .e-hotspot__outer-circle {
      background-color: var(--hotspot-color);
      padding: var(--hotspot-padding, 4px);
      width: var(--hotspot-size, 18px);
      height: var(--hotspot-size, 18px);
    }
    .e-hotspot--icon .e-hotspot__button {
      line-height: 0;
      box-sizing: content-box;
      font-size: var(--hotspot-size, 18px);
      width: var(--hotspot-size, 18px);
      height: var(--hotspot-size, 18px);
    }
    .e-hotspot__icon,
    .e-hotspot__label {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .e-hotspot__icon svg,
    .e-hotspot__label svg {
      height: var(--hotspot-size, 18px);
      width: auto;
    }
    .e-hotspot__button {
      cursor: pointer;
      position: relative;
      display: grid;
      grid-auto-flow: column;
      justify-content: center;
      color: var(--hotspot-color);
      font-size: var(--hotspot-size, 18px);
      min-width: var(--hotspot-button-width);
      min-height: var(--hotspot-button-height);
    }
    .e-hotspot__button:before {
      z-index: -2;
      content: '';
      width: 100%;
      height: 100%;
      left: -5px;
      top: -5px;
      position: absolute;
      padding: 5px;
      box-sizing: content-box;
    }
    .e-hotspot__button svg {
      fill: var(--hotspot-color);
    }
    .e-hotspot:not(.e-hotspot--circle) .e-hotspot__button {
      min-width: var(--hotspot-min-width);
      min-height: var(--hotspot-min-height);
      background-color: var(--hotspot-box-color);
      padding: var(--hotspot-padding, 4px);
      border-radius: var(--hotspot-border-radius, 3px);
    }
    .e-hotspot:not(.e-hotspot--circle) .e-hotspot__button:before {
      border-radius: var(--hotspot-border-radius, 3px);
    }
    .e-hotspot:not(.e-hotspot--circle) .e-hotspot--expand::before {
      background-color: var(--hotspot-box-color);
    }
    .e-hotspot--overlay {
      opacity: 0.7;
      transition: opacity 0.2s;
    }
    .e-hotspot--overlay:hover,
    .e-hotspot--active .e-hotspot--overlay {
      opacity: 1;
    }
    .e-hotspot--soft-beat {
      animation: e-hotspot-soft-beat 0.7s infinite alternate;
      animation-timing-function: ease;
    }
    .e-hotspot--expand .e-hotspot__outer-circle {
      animation: e-hotspot-expand 2s infinite;
    }
    .e-hotspot:not(.e-hotspot--circle) .e-hotspot--expand::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      opacity: 0;
      animation: e-hotspot-expand 2s infinite;
    }
    .e-hotspot--sequenced {
      animation: fadeIn 0.3s;
      animation-iteration-count: 1;
      animation-fill-mode: both;
    }
    .e-hotspot__direction-mask {
      overflow: hidden;
      position: absolute;
      pointer-events: none;
    }
    .e-hotspot__tooltip {
      white-space: var(--white-space, nowrap);
      transition-property: transform, opacity;
      transition-duration: var(--tooltip-transition-duration, 500ms);
      transform-origin: center center;
      position: absolute;
      pointer-events: none;
      color: var(--tooltip-text-color, #fff);
      text-align: var(--tooltip-align);
      min-width: var(--tooltip-min-width);
      padding: var(--tooltip-padding, 8px);
      border-radius: var(--tooltip-border-radius);
      background-color: var(--tooltip-color);
    }
    .e-hotspot__tooltip p:last-of-type {
      margin-bottom: 0;
    }
    .e-hotspot__direction-mask .e-hotspot__tooltip {
      box-shadow: none !important;
      position: static;
    }
    .e-hotspot--active .e-hotspot__tooltip {
      pointer-events: visible;
    }
    .e-hotspot--fade-in-out {
      opacity: 0;
    }
    .e-hotspot--active .e-hotspot--fade-in-out {
      opacity: 1;
    }
    .e-hotspot--fade-grow {
      opacity: 0;
      transform: scale(0);
    }
    .e-hotspot--active .e-hotspot--fade-grow {
      opacity: 1;
      transform: scale(1);
    }
    .e-hotspot--tooltip-animation-from-top,
    .e-hotspot--override-tooltip-animation-from-top {
      transform: translate(0, calc(-100% - 1px));
    }
    .e-hotspot--tooltip-animation-from-left,
    .e-hotspot--override-tooltip-animation-from-left {
      transform: translate(calc(-100% - 1px), 0);
    }
    .e-hotspot--tooltip-animation-from-right,
    .e-hotspot--override-tooltip-animation-from-right {
      transform: translate(calc(100% + 1px), 0);
    }
    .e-hotspot--tooltip-animation-from-bottom,
    .e-hotspot--override-tooltip-animation-from-bottom {
      transform: translate(0, calc(100% + 1px));
    }
    .e-hotspot--fade-direction {
      opacity: 0;
    }
    .e-hotspot--active .e-hotspot--slide-direction,
    .e-hotspot--active .e-hotspot--fade-direction {
      transform: translate(0, 0) scale(1);
      opacity: 1;
    }
    .e-hotspot--show-tooltip {
      transition: none;
      opacity: 1;
      pointer-events: visible;
      transform: translate(0, 0);
    }

    .elementor-facebook-widget.fb_iframe_widget {
      width: 100% !important;
    }
    .elementor-facebook-widget.fb_iframe_widget span {
      width: 100% !important;
    }
    .elementor-facebook-widget.fb_iframe_widget iframe {
      position: relative;
      width: 100% !important;
    }
    .elementor-facebook-widget.fb-like {
      height: 1px;
    }

    .elementor-widget-facebook-comments iframe {
      width: 100% !important;
    }

    .site-main .menu-navigation-container {
      overflow: visible;
    }

    .elementor-item:before,
    .elementor-item:after {
      display: block;
      position: absolute;
      transition: 0.3s;
      transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
    }

    .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after {
      opacity: 0;
    }

    .elementor-item:hover:before,
    .elementor-item:hover:after,
    .elementor-item:focus:before,
    .elementor-item:focus:after,
    .elementor-item.highlighted:before,
    .elementor-item.highlighted:after,
    .elementor-item-active:before,
    .elementor-item-active:after {
      transform: scale(1);
    }

    .e--pointer-double-line .elementor-item:before,
    .e--pointer-double-line .elementor-item:after,
    .e--pointer-underline .elementor-item:before,
    .e--pointer-underline .elementor-item:after,
    .e--pointer-overline .elementor-item:before,
    .e--pointer-overline .elementor-item:after {
      height: 3px;
      width: 100%;
      left: 0;
      background-color: #3f444b;
      z-index: 2;
    }
    .e--pointer-double-line.e--animation-grow
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-double-line.e--animation-grow
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after,
    .e--pointer-underline.e--animation-grow
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-underline.e--animation-grow
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after,
    .e--pointer-overline.e--animation-grow
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-overline.e--animation-grow
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after {
      height: 0;
      width: 0;
      left: 50%;
    }
    .e--pointer-double-line.e--animation-drop-out
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-underline.e--animation-drop-out
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-overline.e--animation-drop-out
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      top: 10px;
    }
    .e--pointer-double-line.e--animation-drop-out
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after,
    .e--pointer-underline.e--animation-drop-out
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after,
    .e--pointer-overline.e--animation-drop-out
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after {
      bottom: 10px;
    }
    .e--pointer-double-line.e--animation-drop-in
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-underline.e--animation-drop-in
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-overline.e--animation-drop-in
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      top: -10px;
    }
    .e--pointer-double-line.e--animation-drop-in
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after,
    .e--pointer-underline.e--animation-drop-in
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after,
    .e--pointer-overline.e--animation-drop-in
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after {
      bottom: -10px;
    }
    .e--pointer-double-line.e--animation-none,
    .e--pointer-double-line.e--animation-none .elementor-item,
    .e--pointer-underline.e--animation-none,
    .e--pointer-underline.e--animation-none .elementor-item,
    .e--pointer-overline.e--animation-none,
    .e--pointer-overline.e--animation-none .elementor-item {
      transition-duration: 0s;
    }
    .e--pointer-double-line.e--animation-none:before,
    .e--pointer-double-line.e--animation-none:after,
    .e--pointer-double-line.e--animation-none:hover,
    .e--pointer-double-line.e--animation-none:focus,
    .e--pointer-double-line.e--animation-none .elementor-item:before,
    .e--pointer-double-line.e--animation-none .elementor-item:after,
    .e--pointer-double-line.e--animation-none .elementor-item:hover,
    .e--pointer-double-line.e--animation-none .elementor-item:focus,
    .e--pointer-underline.e--animation-none:before,
    .e--pointer-underline.e--animation-none:after,
    .e--pointer-underline.e--animation-none:hover,
    .e--pointer-underline.e--animation-none:focus,
    .e--pointer-underline.e--animation-none .elementor-item:before,
    .e--pointer-underline.e--animation-none .elementor-item:after,
    .e--pointer-underline.e--animation-none .elementor-item:hover,
    .e--pointer-underline.e--animation-none .elementor-item:focus,
    .e--pointer-overline.e--animation-none:before,
    .e--pointer-overline.e--animation-none:after,
    .e--pointer-overline.e--animation-none:hover,
    .e--pointer-overline.e--animation-none:focus,
    .e--pointer-overline.e--animation-none .elementor-item:before,
    .e--pointer-overline.e--animation-none .elementor-item:after,
    .e--pointer-overline.e--animation-none .elementor-item:hover,
    .e--pointer-overline.e--animation-none .elementor-item:focus {
      transition-duration: 0s;
    }
    .e--pointer-double-line .elementor-item:before,
    .e--pointer-overline .elementor-item:before {
      content: '';
      top: 0;
    }
    .e--pointer-double-line.e--animation-slide
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before,
    .e--pointer-overline.e--animation-slide
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      width: 10px;
      left: -20px;
    }
    .e--pointer-double-line .elementor-item:after,
    .e--pointer-underline .elementor-item:after {
      content: '';
      bottom: 0;
    }
    .e--pointer-double-line.e--animation-slide
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after,
    .e--pointer-underline.e--animation-slide
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):after {
      width: 10px;
      inset-inline-start: 100%;
    }
    .e--pointer-framed .elementor-item:before,
    .e--pointer-framed .elementor-item:after {
      background: transparent;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border: 3px solid #3f444b;
    }
    .e--pointer-framed .elementor-item:before {
      content: '';
    }
    .e--pointer-framed.e--animation-grow
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      transform: scale(0.75);
    }
    .e--pointer-framed.e--animation-shrink
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      transform: scale(1.25);
    }
    .e--pointer-framed.e--animation-grow .elementor-item:before,
    .e--pointer-framed.e--animation-shrink .elementor-item:before {
      transition: opacity 0.2s, transform 0.4s;
    }
    .e--pointer-framed.e--animation-draw .elementor-item:before,
    .e--pointer-framed.e--animation-draw .elementor-item:after {
      width: 3px;
      height: 3px;
    }
    .e--pointer-framed.e--animation-draw .elementor-item:before {
      border-width: 0 0 3px 3px;
      transition: width 0.1s 0.2s, height 0.1s 0.3s, opacity 0.12s 0.22s;
    }
    .e--pointer-framed.e--animation-draw .elementor-item:after {
      content: '';
      top: initial;
      bottom: 0;
      left: initial;
      right: 0;
      border-width: 3px 3px 0 0;
      transition: width 0.1s, height 0.1s 0.1s, opacity 0.02s 0.18s;
    }
    .e--pointer-framed.e--animation-draw .elementor-item:hover:before,
    .e--pointer-framed.e--animation-draw .elementor-item:hover:after,
    .e--pointer-framed.e--animation-draw .elementor-item:focus:before,
    .e--pointer-framed.e--animation-draw .elementor-item:focus:after,
    .e--pointer-framed.e--animation-draw .elementor-item.highlighted:before,
    .e--pointer-framed.e--animation-draw .elementor-item.highlighted:after,
    .e--pointer-framed.e--animation-draw .elementor-item-active:before,
    .e--pointer-framed.e--animation-draw .elementor-item-active:after {
      width: 100%;
      height: 100%;
    }
    .e--pointer-framed.e--animation-draw .elementor-item:hover:before,
    .e--pointer-framed.e--animation-draw .elementor-item:focus:before,
    .e--pointer-framed.e--animation-draw .elementor-item.highlighted:before,
    .e--pointer-framed.e--animation-draw .elementor-item-active:before {
      transition: opacity 0.02s, height 0.1s, width 0.1s 0.1s;
    }
    .e--pointer-framed.e--animation-draw .elementor-item:hover:after,
    .e--pointer-framed.e--animation-draw .elementor-item:focus:after,
    .e--pointer-framed.e--animation-draw .elementor-item.highlighted:after,
    .e--pointer-framed.e--animation-draw .elementor-item-active:after {
      transition: opacity 0.02s 0.2s, height 0.1s 0.2s, width 0.1s 0.3s;
    }
    .e--pointer-framed.e--animation-corners .elementor-item:before,
    .e--pointer-framed.e--animation-corners .elementor-item:after {
      width: 3px;
      height: 3px;
    }
    .e--pointer-framed.e--animation-corners .elementor-item:before {
      border-width: 3px 0 0 3px;
    }
    .e--pointer-framed.e--animation-corners .elementor-item:after {
      content: '';
      top: initial;
      bottom: 0;
      left: initial;
      right: 0;
      border-width: 0 3px 3px 0;
    }
    .e--pointer-framed.e--animation-corners .elementor-item:hover:before,
    .e--pointer-framed.e--animation-corners .elementor-item:hover:after,
    .e--pointer-framed.e--animation-corners .elementor-item:focus:before,
    .e--pointer-framed.e--animation-corners .elementor-item:focus:after,
    .e--pointer-framed.e--animation-corners .elementor-item.highlighted:before,
    .e--pointer-framed.e--animation-corners .elementor-item.highlighted:after,
    .e--pointer-framed.e--animation-corners .elementor-item-active:before,
    .e--pointer-framed.e--animation-corners .elementor-item-active:after {
      width: 100%;
      height: 100%;
      transition: opacity 0.002s, width 0.4s, height 0.4s;
    }
    .e--pointer-framed.e--animation-none,
    .e--pointer-framed.e--animation-none .elementor-item {
      transition-duration: 0s;
    }
    .e--pointer-framed.e--animation-none:before,
    .e--pointer-framed.e--animation-none:after,
    .e--pointer-framed.e--animation-none:hover,
    .e--pointer-framed.e--animation-none:focus,
    .e--pointer-framed.e--animation-none .elementor-item:before,
    .e--pointer-framed.e--animation-none .elementor-item:after,
    .e--pointer-framed.e--animation-none .elementor-item:hover,
    .e--pointer-framed.e--animation-none .elementor-item:focus {
      transition-duration: 0s;
    }
    .e--pointer-background .elementor-item:before,
    .e--pointer-background .elementor-item:after {
      content: '';
      transition: 0.3s;
    }
    .e--pointer-background .elementor-item:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #3f444b;
      z-index: -1;
    }
    .e--pointer-background .elementor-item:hover,
    .e--pointer-background .elementor-item:focus,
    .e--pointer-background .elementor-item.highlighted,
    .e--pointer-background .elementor-item-active {
      color: #fff;
    }
    .e--pointer-background.e--animation-grow .elementor-item:before {
      transform: scale(0.5);
    }
    .e--pointer-background.e--animation-grow .elementor-item:hover:before,
    .e--pointer-background.e--animation-grow .elementor-item:focus:before,
    .e--pointer-background.e--animation-grow .elementor-item.highlighted:before,
    .e--pointer-background.e--animation-grow .elementor-item-active:before {
      transform: scale(1);
      opacity: 1;
    }
    .e--pointer-background.e--animation-shrink
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      transform: scale(1.2);
      transition: 0.3s;
    }
    .e--pointer-background.e--animation-shrink .elementor-item:hover:before,
    .e--pointer-background.e--animation-shrink .elementor-item:focus:before,
    .e--pointer-background.e--animation-shrink
      .elementor-item.highlighted:before,
    .e--pointer-background.e--animation-shrink .elementor-item-active:before {
      transition: opacity 0.15s, transform 0.4s;
    }
    .e--pointer-background.e--animation-sweep-left
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      left: 100%;
    }
    .e--pointer-background.e--animation-sweep-right
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      right: 100%;
    }
    .e--pointer-background.e--animation-sweep-up
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      top: 100%;
    }
    .e--pointer-background.e--animation-sweep-down
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      bottom: 100%;
    }
    .e--pointer-background.e--animation-shutter-out-vertical
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      bottom: 50%;
      top: 50%;
    }
    .e--pointer-background.e--animation-shutter-out-horizontal
      .elementor-item:not(:hover):not(:focus):not(.elementor-item-active):not(.highlighted):before {
      right: 50%;
      left: 50%;
    }
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item:after,
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #3f444b;
      z-index: -1;
    }
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item:before {
      top: 0;
      bottom: 100%;
    }
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item:after {
      top: 100%;
      bottom: 0;
    }
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item:hover:before,
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item:focus:before,
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item.highlighted:before,
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item-active:before {
      bottom: 50%;
    }
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item:hover:after,
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item:focus:after,
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item.highlighted:after,
    .e--pointer-background.e--animation-shutter-in-vertical
      .elementor-item-active:after {
      top: 50%;
    }
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item:before {
      right: 0;
      left: 100%;
    }
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item:after {
      right: 100%;
      left: 0;
    }
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item:hover:before,
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item:focus:before,
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item.highlighted:before,
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item-active:before {
      left: 50%;
    }
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item:hover:after,
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item:focus:after,
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item.highlighted:after,
    .e--pointer-background.e--animation-shutter-in-horizontal
      .elementor-item-active:after {
      right: 50%;
    }
    .e--pointer-background.e--animation-none,
    .e--pointer-background.e--animation-none .elementor-item {
      transition-duration: 0s;
    }
    .e--pointer-background.e--animation-none:before,
    .e--pointer-background.e--animation-none:after,
    .e--pointer-background.e--animation-none:hover,
    .e--pointer-background.e--animation-none:focus,
    .e--pointer-background.e--animation-none .elementor-item:before,
    .e--pointer-background.e--animation-none .elementor-item:after,
    .e--pointer-background.e--animation-none .elementor-item:hover,
    .e--pointer-background.e--animation-none .elementor-item:focus {
      transition-duration: 0s;
    }
    .e--pointer-text.e--animation-skew .elementor-item:hover,
    .e--pointer-text.e--animation-skew .elementor-item:focus {
      transform: skew(-8deg);
    }
    .e--pointer-text.e--animation-grow .elementor-item:hover,
    .e--pointer-text.e--animation-grow .elementor-item:focus {
      transform: scale(1.2);
    }
    .e--pointer-text.e--animation-shrink .elementor-item:hover,
    .e--pointer-text.e--animation-shrink .elementor-item:focus {
      transform: scale(0.8);
    }
    .e--pointer-text.e--animation-float .elementor-item:hover,
    .e--pointer-text.e--animation-float .elementor-item:focus {
      transform: translateY(-8px);
    }
    .e--pointer-text.e--animation-sink .elementor-item:hover,
    .e--pointer-text.e--animation-sink .elementor-item:focus {
      transform: translateY(8px);
    }
    .e--pointer-text.e--animation-rotate .elementor-item:hover,
    .e--pointer-text.e--animation-rotate .elementor-item:focus {
      transform: rotate(6deg);
    }
    .e--pointer-text.e--animation-none,
    .e--pointer-text.e--animation-none .elementor-item {
      transition-duration: 0s;
    }
    .e--pointer-text.e--animation-none:before,
    .e--pointer-text.e--animation-none:after,
    .e--pointer-text.e--animation-none:hover,
    .e--pointer-text.e--animation-none:focus,
    .e--pointer-text.e--animation-none .elementor-item:before,
    .e--pointer-text.e--animation-none .elementor-item:after,
    .e--pointer-text.e--animation-none .elementor-item:hover,
    .e--pointer-text.e--animation-none .elementor-item:focus {
      transition-duration: 0s;
    }

    .elementor-nav-menu--main .elementor-nav-menu a {
      transition: 0.4s;
    }
    .elementor-nav-menu--main .elementor-nav-menu a,
    .elementor-nav-menu--main .elementor-nav-menu a:hover,
    .elementor-nav-menu--main .elementor-nav-menu a:focus,
    .elementor-nav-menu--main .elementor-nav-menu a.highlighted {
      padding: 13px 20px;
    }
    .elementor-nav-menu--main .elementor-nav-menu a.current {
      background: #1f2124;
      color: #fff;
    }
    .elementor-nav-menu--main .elementor-nav-menu a.disabled {
      background: #3f444b;
      color: #88909b;
    }
    .elementor-nav-menu--main .elementor-nav-menu ul {
      position: absolute;
      width: 12em;
      border-width: 0;
      border-style: solid;
      padding: 0;
    }
    .elementor-nav-menu--main .elementor-nav-menu span.scroll-up,
    .elementor-nav-menu--main .elementor-nav-menu span.scroll-down {
      position: absolute;
      display: none;
      visibility: hidden;
      overflow: hidden;
      background: #fff;
      height: 20px;
    }
    .elementor-nav-menu--main .elementor-nav-menu span.scroll-up-arrow,
    .elementor-nav-menu--main .elementor-nav-menu span.scroll-down-arrow {
      position: absolute;
      top: -2px;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      overflow: hidden;
      border-width: 8px;
      border-style: dashed dashed solid dashed;
      border-color: transparent transparent #33373d transparent;
    }
    .elementor-nav-menu--main .elementor-nav-menu span.scroll-down-arrow {
      top: 6px;
      border-style: solid dashed dashed dashed;
      border-color: #33373d transparent transparent transparent;
    }
    .elementor-nav-menu--main .elementor-nav-menu--dropdown .sub-arrow i,
    .elementor-nav-menu--main
      .elementor-nav-menu--dropdown
      .sub-arrow
      .e-font-icon-svg {
      transform: rotate(-90deg);
    }
    .elementor-nav-menu--main
      .elementor-nav-menu--dropdown
      .sub-arrow
      .e-font-icon-svg {
      fill: currentColor;
      height: 1em;
      width: 1em;
    }

    .elementor-nav-menu--layout-horizontal {
      display: flex;
    }
    .elementor-nav-menu--layout-horizontal .elementor-nav-menu {
      display: flex;
      flex-wrap: wrap;
    }
    .elementor-nav-menu--layout-horizontal .elementor-nav-menu a {
      white-space: nowrap;
      flex-grow: 1;
    }
    .elementor-nav-menu--layout-horizontal .elementor-nav-menu > li {
      display: flex;
    }
    .elementor-nav-menu--layout-horizontal .elementor-nav-menu > li ul,
    .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li
      > .scroll-down {
      top: 100% !important;
    }
    .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li:not(:first-child)
      > a {
      margin-inline-start: var(--e-nav-menu-horizontal-menu-item-margin);
    }
    .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li:not(:first-child)
      > ul,
    .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li:not(:first-child)
      > .scroll-down,
    .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li:not(:first-child)
      > .scroll-up {
      left: var(--e-nav-menu-horizontal-menu-item-margin) !important;
    }
    .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li:not(:last-child)
      > a {
      margin-inline-end: var(--e-nav-menu-horizontal-menu-item-margin);
    }
    .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li:not(:last-child):after {
      content: var(--e-nav-menu-divider-content, none);
      border-left-width: var(--e-nav-menu-divider-width, 2px);
      border-left-style: var(--e-nav-menu-divider-style, solid);
      height: var(--e-nav-menu-divider-height, 35%);
      border-color: var(--e-nav-menu-divider-color, #000);
      align-self: center;
    }

    .elementor-nav-menu__align-right .elementor-nav-menu {
      margin-left: auto;
      justify-content: flex-end;
    }
    .elementor-nav-menu__align-right
      .elementor-nav-menu--layout-vertical
      > ul
      > li
      > a {
      justify-content: flex-end;
    }
    .elementor-nav-menu__align-left .elementor-nav-menu {
      margin-right: auto;
      justify-content: flex-start;
    }
    .elementor-nav-menu__align-left
      .elementor-nav-menu--layout-vertical
      > ul
      > li
      > a {
      justify-content: flex-start;
    }
    .elementor-nav-menu__align-center .elementor-nav-menu {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
    .elementor-nav-menu__align-center
      .elementor-nav-menu--layout-vertical
      > ul
      > li
      > a {
      justify-content: center;
    }
    .elementor-nav-menu__align-justify
      .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu {
      width: 100%;
    }
    .elementor-nav-menu__align-justify
      .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li {
      flex-grow: 1;
    }
    .elementor-nav-menu__align-justify
      .elementor-nav-menu--layout-horizontal
      .elementor-nav-menu
      > li
      > a {
      justify-content: center;
    }

    .elementor-widget-nav-menu:not(.elementor-nav-menu--toggle)
      .elementor-menu-toggle {
      display: none;
    }
    .elementor-widget-nav-menu .elementor-widget-container {
      display: flex;
      flex-direction: column;
    }

    .elementor-nav-menu {
      position: relative;
      z-index: 2;
    }
    .elementor-nav-menu:after {
      content: ' ';
      display: block;
      height: 0;
      font: 0px/0 serif;
      clear: both;
      visibility: hidden;
      overflow: hidden;
    }
    .elementor-nav-menu,
    .elementor-nav-menu ul,
    .elementor-nav-menu li {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: normal;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .elementor-nav-menu ul {
      display: none;
    }
    .elementor-nav-menu ul ul a,
    .elementor-nav-menu ul ul a:hover,
    .elementor-nav-menu ul ul a:focus,
    .elementor-nav-menu ul ul a:active {
      border-left: 16px solid transparent;
    }
    .elementor-nav-menu ul ul ul a,
    .elementor-nav-menu ul ul ul a:hover,
    .elementor-nav-menu ul ul ul a:focus,
    .elementor-nav-menu ul ul ul a:active {
      border-left: 24px solid transparent;
    }
    .elementor-nav-menu ul ul ul ul a,
    .elementor-nav-menu ul ul ul ul a:hover,
    .elementor-nav-menu ul ul ul ul a:focus,
    .elementor-nav-menu ul ul ul ul a:active {
      border-left: 32px solid transparent;
    }
    .elementor-nav-menu ul ul ul ul ul a,
    .elementor-nav-menu ul ul ul ul ul a:hover,
    .elementor-nav-menu ul ul ul ul ul a:focus,
    .elementor-nav-menu ul ul ul ul ul a:active {
      border-left: 40px solid transparent;
    }
    .elementor-nav-menu li,
    .elementor-nav-menu a {
      position: relative;
    }
    .elementor-nav-menu li {
      border-width: 0;
    }
    .elementor-nav-menu a {
      display: flex;
      align-items: center;
    }
    .elementor-nav-menu a,
    .elementor-nav-menu a:hover,
    .elementor-nav-menu a:focus {
      padding: 10px 20px;
      line-height: 20px;
    }
    .elementor-nav-menu a.current {
      background: #1f2124;
      color: #fff;
    }
    .elementor-nav-menu a.disabled {
      cursor: not-allowed;
      color: #88909b;
    }
    .elementor-nav-menu .e-plus-icon:before {
      content: '+';
    }
    .elementor-nav-menu .sub-arrow {
      line-height: 1;
      padding: 10px 0 10px 10px;
      margin-top: -10px;
      margin-bottom: -10px;
      display: flex;
      align-items: center;
    }
    .elementor-nav-menu .sub-arrow i {
      pointer-events: none;
    }
    .elementor-nav-menu .sub-arrow .fa.fa-chevron-down,
    .elementor-nav-menu .sub-arrow .fas.fa-chevron-down {
      font-size: 0.7em;
    }
    .elementor-nav-menu .sub-arrow .e-font-icon-svg {
      height: 1em;
      width: 1em;
    }
    .elementor-nav-menu .sub-arrow .e-font-icon-svg.fa-svg-chevron-down {
      height: 0.7em;
      width: 0.7em;
    }

    .elementor-nav-menu--dropdown .elementor-item:hover,
    .elementor-nav-menu--dropdown .elementor-item:focus,
    .elementor-nav-menu--dropdown .elementor-item.highlighted,
    .elementor-nav-menu--dropdown .elementor-item.elementor-item-active,
    .elementor-sub-item:hover,
    .elementor-sub-item:focus,
    .elementor-sub-item.highlighted,
    .elementor-sub-item.elementor-item-active {
      background-color: #3f444b;
      color: #fff;
    }

    .elementor-menu-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--nav-menu-icon-size, 22px);
      padding: 0.25em;
      cursor: pointer;
      border: 0 solid;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.05);
      color: #33373d;
    }
    .elementor-menu-toggle.elementor-active .elementor-menu-toggle__icon--open {
      display: none;
    }
    .elementor-menu-toggle:not(.elementor-active)
      .elementor-menu-toggle__icon--close {
      display: none;
    }
    .elementor-menu-toggle .e-font-icon-svg {
      fill: #33373d;
      height: 1em;
      width: 1em;
    }
    .elementor-menu-toggle svg {
      width: 1em;
      height: auto;
      fill: var(--nav-menu-icon-color, currentColor);
    }

    span.elementor-menu-toggle__icon--open,
    span.elementor-menu-toggle__icon--close {
      line-height: 1;
    }

    .elementor-nav-menu--dropdown {
      background-color: #fff;
      font-size: 13px;
    }
    .elementor-nav-menu--dropdown-none .elementor-nav-menu--dropdown,
    .elementor-nav-menu--dropdown-none .elementor-menu-toggle {
      display: none;
    }
    .elementor-nav-menu--dropdown.elementor-nav-menu__container {
      margin-top: 10px;
      transition: max-height 0.3s, transform 0.3s;
      transform-origin: top;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .elementor-nav-menu--dropdown.elementor-nav-menu__container
      .elementor-sub-item {
      font-size: 0.85em;
    }
    .elementor-nav-menu--dropdown a {
      color: #33373d;
    }
    .elementor-nav-menu--dropdown a.current {
      background: #1f2124;
      color: #fff;
    }
    .elementor-nav-menu--dropdown a.disabled {
      color: #b3b3b3;
    }

    ul.elementor-nav-menu--dropdown a,
    ul.elementor-nav-menu--dropdown a:hover,
    ul.elementor-nav-menu--dropdown a:focus {
      text-shadow: none;
      border-left: 8px solid transparent;
    }

    .elementor-nav-menu__text-align-center
      .elementor-nav-menu--dropdown
      .elementor-nav-menu
      a {
      justify-content: center;
    }

    .elementor-nav-menu--toggle {
      --menu-height: 100vh;
    }
    .elementor-nav-menu--toggle
      .elementor-menu-toggle:not(.elementor-active)
      + .elementor-nav-menu__container {
      transform: scaleY(0);
      max-height: 0;
      overflow: hidden;
    }
    .elementor-nav-menu--toggle
      .elementor-menu-toggle.elementor-active
      + .elementor-nav-menu__container {
      transform: scaleY(1);
      max-height: var(--menu-height);
      animation: hide-scroll 0.3s backwards;
    }

    .elementor-nav-menu--stretch
      .elementor-nav-menu__container.elementor-nav-menu--dropdown {
      position: absolute;
      z-index: 9997;
    }

    /**
 * Mobile Hamburger Menu Breakpoint
 */
    @media (max-width: 767px) {
      .elementor-nav-menu--dropdown-mobile .elementor-nav-menu--main {
        display: none;
      }
    }
    @media (min-width: 768px) {
      .elementor-nav-menu--dropdown-mobile .elementor-nav-menu--dropdown,
      .elementor-nav-menu--dropdown-mobile .elementor-menu-toggle {
        display: none;
      }
      .elementor-nav-menu--dropdown-mobile
        nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
        overflow-y: hidden;
      }
    }
    /**
 * Mobile Extra Hamburger Menu Breakpoint
 */
    @media (max-width: -1) {
      .elementor-nav-menu--dropdown-mobile_extra .elementor-nav-menu--main {
        display: none;
      }
    }
    @media (min-width: -1) {
      .elementor-nav-menu--dropdown-mobile_extra .elementor-nav-menu--dropdown,
      .elementor-nav-menu--dropdown-mobile_extra .elementor-menu-toggle {
        display: none;
      }
      .elementor-nav-menu--dropdown-mobile_extra
        nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
        overflow-y: hidden;
      }
    }
    /**
 * Tablet Hamburger Menu Breakpoint
 */
    @media (max-width: 1024px) {
      .elementor-nav-menu--dropdown-tablet .elementor-nav-menu--main {
        display: none;
      }
    }
    @media (min-width: 1025px) {
      .elementor-nav-menu--dropdown-tablet .elementor-nav-menu--dropdown,
      .elementor-nav-menu--dropdown-tablet .elementor-menu-toggle {
        display: none;
      }
      .elementor-nav-menu--dropdown-tablet
        nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
        overflow-y: hidden;
      }
    }
    /**
 * Tablet Extra Hamburger Menu Breakpoint
 */
    @media (max-width: -1) {
      .elementor-nav-menu--dropdown-tablet_extra .elementor-nav-menu--main {
        display: none;
      }
    }
    @media (min-width: -1) {
      .elementor-nav-menu--dropdown-tablet_extra .elementor-nav-menu--dropdown,
      .elementor-nav-menu--dropdown-tablet_extra .elementor-menu-toggle {
        display: none;
      }
      .elementor-nav-menu--dropdown-tablet_extra
        nav.elementor-nav-menu--dropdown.elementor-nav-menu__container {
        overflow-y: hidden;
      }
    }
    @keyframes hide-scroll {
      from,
      to {
        overflow: hidden;
      }
    }
    .elementor-gallery__container {
      min-height: 1px;
    }
    .elementor-gallery-item {
      position: relative;
      overflow: hidden;
      display: block;
      text-decoration: none;
      border: solid var(--image-border-width) var(--image-border-color);
      border-radius: var(--image-border-radius);
    }
    .elementor-gallery-item__overlay,
    .elementor-gallery-item__content {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .elementor-gallery-item__overlay {
      mix-blend-mode: var(--overlay-mix-blend-mode);
      transition-duration: var(--overlay-transition-duration);
      transition-property: mix-blend-mode, transform, opacity, background-color;
    }
    .elementor-gallery-item__image.e-gallery-image {
      transition-duration: var(--image-transition-duration);
      transition-property: filter, transform;
    }
    .elementor-gallery-item__content {
      display: flex;
      flex-direction: column;
      justify-content: var(--content-justify-content, center);
      align-items: center;
      text-align: var(--content-text-align);
      padding: var(--content-padding);
    }
    .elementor-gallery-item__content > div {
      transition-duration: var(--content-transition-duration);
    }
    .elementor-gallery-item__content.elementor-gallery--sequenced-animation
      > div:nth-child(2) {
      transition-delay: calc(var(--content-transition-delay) / 3);
    }
    .elementor-gallery-item__content.elementor-gallery--sequenced-animation
      > div:nth-child(3) {
      transition-delay: calc(var(--content-transition-delay) / 3 * 2);
    }
    .elementor-gallery-item__content.elementor-gallery--sequenced-animation
      > div:nth-child(4) {
      transition-delay: calc(var(--content-transition-delay) / 3 * 3);
    }
    .elementor-gallery-item__description {
      color: var(--description-text-color, #ffffff);
      width: 100%;
    }
    .elementor-gallery-item__title {
      color: var(--title-text-color, #ffffff);
      font-weight: bold;
      width: 100%;
    }
    .elementor-gallery__titles-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: var(--titles-container-justify-content, center);
      margin-bottom: 20px;
    }
    .elementor-gallery__titles-container:not(.e--pointer-framed)
      .elementor-item:before {
      background-color: var(--galleries-pointer-bg-color-hover);
    }
    .elementor-gallery__titles-container:not(.e--pointer-framed)
      .elementor-item:after {
      background-color: var(--galleries-pointer-bg-color-hover);
    }
    .elementor-gallery__titles-container:not(.e--pointer-framed)
      .elementor-item.elementor-item-active:before {
      background-color: var(--galleries-pointer-bg-color-active);
    }
    .elementor-gallery__titles-container:not(.e--pointer-framed)
      .elementor-item.elementor-item-active:after {
      background-color: var(--galleries-pointer-bg-color-active);
    }
    .elementor-gallery__titles-container.e--pointer-framed
      .elementor-item:before {
      border-color: var(--galleries-pointer-bg-color-hover);
      border-width: var(--galleries-pointer-border-width);
    }
    .elementor-gallery__titles-container.e--pointer-framed
      .elementor-item:after {
      border-color: var(--galleries-pointer-bg-color-hover);
    }
    .elementor-gallery__titles-container.e--pointer-framed
      .elementor-item.elementor-item-active:before {
      border-color: var(--galleries-pointer-bg-color-active);
    }
    .elementor-gallery__titles-container.e--pointer-framed
      .elementor-item.elementor-item-active:after {
      border-color: var(--galleries-pointer-bg-color-active);
    }
    .elementor-gallery__titles-container.e--pointer-framed.e--animation-draw
      .elementor-item:before {
      border-width: 0 0 var(--galleries-pointer-border-width)
        var(--galleries-pointer-border-width);
    }
    .elementor-gallery__titles-container.e--pointer-framed.e--animation-draw
      .elementor-item:after {
      border-width: var(--galleries-pointer-border-width)
        var(--galleries-pointer-border-width) 0 0;
    }
    .elementor-gallery__titles-container.e--pointer-framed.e--animation-corners
      .elementor-item:before {
      border-width: var(--galleries-pointer-border-width) 0 0
        var(--galleries-pointer-border-width);
    }
    .elementor-gallery__titles-container.e--pointer-framed.e--animation-corners
      .elementor-item:after {
      border-width: 0 var(--galleries-pointer-border-width)
        var(--galleries-pointer-border-width) 0;
    }
    .elementor-gallery__titles-container
      .e--pointer-underline
      .elementor-item:after,
    .elementor-gallery__titles-container
      .e--pointer-overline
      .elementor-item:before,
    .elementor-gallery__titles-container
      .e--pointer-double-line
      .elementor-item:before,
    .elementor-gallery__titles-container
      .e--pointer-double-line
      .elementor-item:after {
      height: var(--galleries-pointer-border-width);
    }
    .elementor-gallery-title {
      --space-between: 10px;
      cursor: pointer;
      color: #6d7882;
      font-weight: 500;
      position: relative;
      padding: 7px 14px;
      transition: all 0.3s;
    }
    .elementor-gallery-title--active {
      color: #495157;
    }
    .elementor-gallery-title:not(:last-child) {
      margin-right: var(--space-between);
    }

    .elementor-gallery-item__title + .elementor-gallery-item__description {
      margin-top: var(--description-margin-top);
    }

    .e-gallery-item.elementor-gallery-item {
      transition-property: all;
    }
    .e-gallery-item:hover .elementor-gallery__item-overlay-bg,
    .e-gallery-item:focus .elementor-gallery__item-overlay-bg {
      opacity: 1;
    }
    .e-gallery-item:hover .elementor-gallery__item-overlay-content,
    .e-gallery-item:focus .elementor-gallery__item-overlay-content {
      opacity: 1;
    }
    .e-gallery-item:hover .elementor-gallery__item-overlay-content__title,
    .e-gallery-item:hover .elementor-gallery__item-overlay-content__description,
    .e-gallery-item:focus .elementor-gallery__item-overlay-content__title,
    .e-gallery-item:focus
      .elementor-gallery__item-overlay-content__description {
      opacity: 1;
    }
    .e-gallery-item.elementor-animated-content
      .elementor-animated-item--enter-from-right,
    .e-gallery-item.elementor-animated-content
      .elementor-animated-item--enter-from-left,
    .e-gallery-item.elementor-animated-content
      .elementor-animated-item--enter-from-top,
    .e-gallery-item.elementor-animated-content
      .elementor-animated-item--enter-from-bottom {
      opacity: 1;
    }

    a.elementor-item.elementor-gallery-title {
      color: var(--galleries-title-color-normal);
    }
    a.elementor-item.elementor-gallery-title:hover,
    a.elementor-item.elementor-gallery-title.elementor-item-active,
    a.elementor-item.elementor-gallery-title.highlighted,
    a.elementor-item.elementor-gallery-title:focus {
      color: var(--galleries-title-color-hover);
    }
    a.elementor-item.elementor-gallery-title.elementor-item-active {
      color: var(--gallery-title-color-active);
    }

    .e-con > .elementor-widget-gallery,
    .e-con-inner > .elementor-widget-gallery {
      width: var(--container-widget-width);
      --flex-grow: var(--container-widget-flex-grow);
    }

    .elementor-widget-table-of-contents .elementor-toc__header-title {
      color: var(--header-color);
    }
    .elementor-widget-table-of-contents.elementor-toc--collapsed
      .elementor-toc__toggle-button--collapse {
      display: none;
    }
    .elementor-widget-table-of-contents:not(.elementor-toc--collapsed)
      .elementor-toc__toggle-button--expand {
      display: none;
    }
    .elementor-widget-table-of-contents .elementor-widget-container {
      min-height: var(--box-min-height);
      border: var(--box-border-width, 1px) solid
        var(--box-border-color, #9da5ae);
      border-radius: var(--box-border-radius, 3px);
      background-color: var(--box-background-color);
      transition: min-height 0.4s;
      overflow: hidden;
    }

    .elementor-toc__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--box-padding, 20px);
      background-color: var(--header-background-color);
      border-bottom: var(--separator-width, 1px) solid
        var(--box-border-color, #9da5ae);
    }
    .elementor-toc__header-title {
      font-size: 18px;
      margin: 0;
      color: var(--header-color);
    }
    .elementor-toc__toggle-button {
      cursor: pointer;
      display: inline-flex;
    }
    .elementor-toc__toggle-button i {
      color: var(--toggle-button-color);
    }
    .elementor-toc__toggle-button svg {
      fill: var(--toggle-button-color);
      height: 1em;
      width: 1em;
      fill: var(--toggle-button-color);
    }
    .elementor-toc__spinner-container {
      text-align: center;
    }
    .elementor-toc__spinner {
      font-size: 2em;
    }
    .elementor-toc__spinner.e-font-icon-svg {
      height: 1em;
      width: 1em;
    }
    .elementor-toc__body {
      padding: var(--box-padding, 20px);
      max-height: var(--toc-body-max-height);
      overflow-y: auto;
    }
    .elementor-toc__body::-webkit-scrollbar {
      width: 7px;
    }
    .elementor-toc__body::-webkit-scrollbar-thumb {
      background-color: #babfc5;
      border-radius: 10px;
    }
    .elementor-toc__list-wrapper {
      list-style: none;
      padding: 0;
    }
    .elementor-toc__list-item {
      margin-bottom: 0.5em;
    }
    .elementor-toc__list-item.elementor-item-active {
      font-weight: bold;
    }
    .elementor-toc__list-item .elementor-toc__list-wrapper {
      margin-top: 0.5em;
      margin-left: var(--nested-list-indent, 1em);
    }
    .elementor-toc__list-item-text:hover {
      color: var(--item-text-hover-color);
      -webkit-text-decoration: var(--item-text-hover-decoration);
      text-decoration: var(--item-text-hover-decoration);
    }
    .elementor-toc__list-item-text.elementor-item-active {
      color: var(--item-text-active-color);
      -webkit-text-decoration: var(--item-text-active-decoration);
      text-decoration: var(--item-text-active-decoration);
    }
    .elementor-toc__list-item-text-wrapper {
      display: flex;
      align-items: center;
    }
    .elementor-toc__list-item-text-wrapper i,
    .elementor-toc__list-item-text-wrapper:before {
      margin-right: 8px;
      color: var(--marker-color);
    }
    .elementor-toc__list-item-text-wrapper svg {
      margin-right: 8px;
      fill: var(--marker-color);
      height: var(--marker-size, 0.5em);
      width: var(--marker-size, 0.5em);
    }
    .elementor-toc__list-item-text-wrapper i {
      font-size: var(--marker-size, 0.5em);
    }
    .elementor-toc__list-item-text-wrapper:before {
      font-size: var(--marker-size, 1em);
    }
    .elementor-toc--content-ellipsis .elementor-toc__list-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .elementor-toc__list-items--collapsible
      > .elementor-toc__list-wrapper
      > .elementor-toc__list-item
      > .elementor-toc__list-wrapper {
      display: none;
    }
    .elementor-toc__heading-anchor {
      position: absolute;
    }

    .elementor-toc__body .elementor-toc__list-item-text {
      color: var(--item-text-color);
      -webkit-text-decoration: var(--item-text-decoration);
      text-decoration: var(--item-text-decoration);
    }
    .elementor-toc__body .elementor-toc__list-item-text:hover {
      color: var(--item-text-hover-color);
      -webkit-text-decoration: var(--item-text-hover-decoration);
      text-decoration: var(--item-text-hover-decoration);
    }
    .elementor-toc__body .elementor-toc__list-item-text.elementor-item-active {
      color: var(--item-text-active-color);
      -webkit-text-decoration: var(--item-text-active-decoration);
      text-decoration: var(--item-text-active-decoration);
    }

    ol.elementor-toc__list-wrapper {
      counter-reset: item;
    }
    ol.elementor-toc__list-wrapper .elementor-toc__list-item {
      counter-increment: item;
    }
    ol.elementor-toc__list-wrapper
      .elementor-toc__list-item-text-wrapper:before {
      content: counters(item, '.') '. ';
    }

    .e-lottie__container {
      display: inline-block;
      max-width: var(--lottie-container-max-width);
      width: var(--lottie-container-width);
      opacity: var(--lottie-container-opacity);
    }
    .e-lottie__container:hover {
      opacity: var(--lottie-container-opacity-hover);
      transition-duration: var(--lottie-container-transition-duration-hover);
    }
    .e-lottie__container svg,
    .e-lottie__container svg * {
      transition: none !important;
    }
    .e-lottie__caption {
      color: var(--caption-color);
      margin-top: var(--caption-margin-top);
      text-align: var(--caption-text-align);
    }

    /* Video_Playlist Widget */
    .e-tabs-inner-tabs {
      text-align: left;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 16px;
    }
    .e-tabs-inner-tabs .e-tab-content {
      padding-top: 32px;
    }
    .e-tabs-inner-tabs .e-inner-tabs-wrapper {
      display: flex;
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
    .e-tabs-inner-tabs .e-inner-tabs-wrapper :after {
      content: '';
      width: 100%;
      height: var(--inner-tabs-border-height, 1px);
      background-color: var(--inner-tabs-border-color, #d5d8dc);
      position: absolute;
      bottom: 0;
      z-index: -1;
    }
    .e-tabs-inner-tabs .e-inner-tabs-wrapper .e-inner-tab-title {
      font-weight: 700;
      min-width: 140px;
      line-height: 38px;
      height: 38px;
      box-sizing: content-box;
    }
    .e-tabs-inner-tabs .e-inner-tabs-wrapper .e-inner-tab-title a {
      color: #3f444b;
      padding: 0 36px;
      display: block;
      text-align: center;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-wrapper
      .e-inner-tab-title.e-inner-tab-active {
      border-bottom: 0 solid transparent;
      border-color: #0c0d0e;
      border-width: 0 0 1px 0;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-wrapper
      .e-inner-tab-title.e-inner-tab-active
      a {
      color: #0c0d0e;
    }
    .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-tab-mobile-title {
      display: none;
    }
    .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-inner-tab-content {
      display: none;
      padding: 20px;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content
      .e-inner-tab-text {
      color: #1f2124;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content.e-inner-tab-active {
      display: block;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content
      .e-inner-tab-buttons {
      margin-top: 24px;
      display: none;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content
      .e-inner-tab-buttons
      button {
      display: none;
      font-weight: 500;
      line-height: 24px;
      text-decoration: underline;
      border: none;
      background: transparent;
      padding: 0;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content.collapsible
      .e-inner-tab-text {
      overflow: hidden;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content.collapsible
      button {
      display: none;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content.collapsible
      button.show-button {
      display: block;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content.collapsible.show-inner-tab-buttons
      .e-inner-tab-buttons {
      display: block;
    }
    .e-tabs-inner-tabs
      .e-inner-tabs-content-wrapper
      .e-inner-tab-content.show-full-height
      div.e-inner-tab-text {
      height: auto;
    }

    @media (max-width: 767px) {
      .e-tabs-inner-tabs > .e-tab-content:first-child {
        padding-top: 24px;
      }
      .e-tabs-inner-tabs .e-inner-tabs-wrapper {
        display: none;
      }
      .e-tabs-inner-tabs .e-inner-tabs-content-wrapper {
        border-width: 1px 1px 0;
        border-style: solid;
        border-color: #d5d8dc;
      }
      .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-tab-mobile-title {
        border-width: 0 0 1px;
        border-style: solid;
        border-color: #d5d8dc;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        height: 38px;
        line-height: 38px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 10px;
        cursor: pointer;
        display: block;
      }
      .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-inner-tab-content {
        border-width: 0 0 1px;
        border-style: solid;
        border-color: #d5d8dc;
        padding: 20px;
      }
    }
    .elementor-widget-video-playlist.elementor-layout-end .e-tabs-main-area {
      flex-direction: row;
    }

    @media (max-width: 767px) {
      .elementor-widget-video-playlist.elementor-layout-end .e-tabs-main-area {
        flex-direction: column-reverse;
      }
    }
    .elementor-widget-video-playlist {
      font-family: Roboto, Arial, Helvetica, sans-serif;
    }
    .elementor-widget-video-playlist .e-tabs {
      display: flex;
      flex-direction: column;
    }
    .elementor-widget-video-playlist .e-tabs-main-area {
      display: flex;
      flex-direction: row-reverse;
      height: 434px;
    }
    .elementor-widget-video-playlist .e-tabs-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 33%;
    }
    .elementor-widget-video-playlist .e-tabs-header {
      background-color: var(--e-a-bg-default);
      display: flex;
      flex: 0 0 auto;
      justify-content: space-between;
      padding: 0 16px;
      min-height: 56px;
      border-bottom: 1px solid #d5d8dc;
    }
    .elementor-widget-video-playlist .e-tabs-header .e-tabs-header-right-side {
      display: inline-flex;
      flex-shrink: 0;
    }
    .elementor-widget-video-playlist .e-tabs-header .e-tabs-title {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      text-overflow: ellipsis;
      font-weight: 700;
      font-size: 16px;
      padding: 10px 0;
      overflow-wrap: anywhere;
      -webkit-hyphens: auto;
      hyphens: auto;
      margin: auto 0;
    }
    .elementor-widget-video-playlist .e-tabs-header .e-tabs-videos-count {
      font-size: 14px;
      flex-shrink: 0;
      font-weight: 400;
      line-height: 20px;
      align-self: flex-start;
      padding: 18px 0 0;
    }
    .elementor-widget-video-playlist
      .e-tabs-header
      .e-tabs-toggle-videos-display-button {
      display: none;
      margin: auto 0 auto 8px;
      cursor: pointer;
      padding: 0 4px;
    }
    .elementor-widget-video-playlist
      .e-tabs-header
      .e-tabs-toggle-videos-display-button.e-font-icon-svg {
      height: 1em;
      width: 1em;
    }
    .elementor-widget-video-playlist .e-tabs-header .rotate-up {
      transform: rotate(-180deg);
      transition: 0.3s;
    }
    .elementor-widget-video-playlist .e-tabs-header .rotate-down {
      transform: rotate(0deg);
      transition: 0.3s;
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper {
      background-color: var(--e-a-bg-default);
      overflow: hidden;
      position: relative;
      height: 100%;
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .shadow-bottom {
      background: linear-gradient(180deg, transparent 0%, #ffffff 100%);
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .shadow-top {
      background: linear-gradient(0deg, transparent 0%, #ffffff 100%);
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tabs-items {
      overflow: auto;
      height: 100%;
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-section-title {
      border-color: #d5d8dc;
      border-width: 1px 0 0 0;
      font-size: 14px;
      height: 36px;
      padding: 0 16px;
      line-height: 36px;
      margin: 0;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-width: 0;
      box-sizing: content-box;
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title {
      background-color: var(--e-a-bg-default);
      border-color: #d5d8dc;
      border-width: 0 0 1px 0;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      height: 55px;
      line-height: 55px;
      outline: none;
      padding: 0 16px;
      box-sizing: content-box;
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title i {
      text-shadow: 0 0 3px #000000;
      color: #ffffff;
      font-size: var(--playlist-item-icon-size, 16px);
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title svg {
      text-shadow: 0 0 3px #000000;
      color: #ffffff;
      fill: #ffffff;
      width: var(--playlist-item-icon-size, 16px);
      height: var(--playlist-item-icon-size, 16px);
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      svg
      path {
      fill: #ffffff;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title.e-active,
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title:hover {
      background-color: #f1f2f3;
      border-color: #d5d8dc;
      border-width: 0 0 1px 0;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title.e-active
      i,
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title:hover
      i {
      text-shadow: 0 0 3px #000000;
      color: #ffffff;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title.e-active
      svg,
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title:hover
      svg {
      text-shadow: 0 0 3px #000000;
      color: #ffffff;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title.e-active
      svg
      path,
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title:hover
      svg
      path {
      fill: #ffffff;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title.e-active
      .e-tab-title-text,
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title:hover
      .e-tab-title-text {
      font-size: 14px;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .icon-play,
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .icon-watched {
      display: inline-flex;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .e-tab-thumbnail {
      background-color: #d5d8dc;
      position: relative;
      margin: auto 0;
      flex-shrink: 0;
      width: 75px;
      height: 42px;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .e-tab-thumbnail
      img {
      -o-object-fit: fill;
      object-fit: fill;
      width: 75px;
      height: 42px;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .e-tab-thumbnail
      i,
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .e-tab-thumbnail
      svg {
      position: absolute;
      bottom: 4px;
      right: 4px;
      font-weight: 900;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .e-tab-title-text {
      font-size: 14px;
      margin: auto 8px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      flex-grow: 1;
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title i,
    .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title svg {
      margin: auto 0;
      vertical-align: middle;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .e-tab-duration {
      font-size: 14px;
      margin: auto 0;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title
      .icon-watched {
      display: none;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title.watched-video
      .icon-play {
      display: none;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper
      .e-tab-title.watched-video
      .icon-watched {
      display: block;
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .shadow-top {
      display: none;
      height: 98px;
      position: absolute;
      top: 0;
      width: 100%;
      pointer-events: none;
      background: linear-gradient(0deg, transparent 0%, #ffffff 100%);
    }
    .elementor-widget-video-playlist .e-tabs-items-wrapper .shadow-bottom {
      display: none;
      height: 98px;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(180deg, transparent 0%, #ffffff 100%);
      pointer-events: none;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper.top-shadow
      .shadow-top {
      display: block;
    }
    .elementor-widget-video-playlist
      .e-tabs-items-wrapper.bottom-shadow
      .shadow-bottom {
      display: block;
    }
    .elementor-widget-video-playlist .e-tabs-content-wrapper {
      flex-grow: 1;
      height: 100%;
      position: relative;
    }
    .elementor-widget-video-playlist
      .e-tabs-content-wrapper
      .elementor-custom-embed-image-overlay {
      cursor: pointer;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 50%;
    }
    .elementor-widget-video-playlist
      .e-tabs-content-wrapper
      .e-tab-mobile-title {
      display: none;
    }
    .elementor-widget-video-playlist .e-tabs-content-wrapper .e-tab-content {
      background-color: #000000;
      display: none;
      height: 100%;
      outline: none;
    }
    .elementor-widget-video-playlist
      .e-tabs-content-wrapper
      .e-tab-content
      > div {
      height: 100%;
    }
    .elementor-widget-video-playlist
      .e-tabs-content-wrapper
      .e-tab-content
      iframe {
      height: 100%;
      max-height: 100% !important;
      display: flex;
    }
    .elementor-widget-video-playlist
      .e-tabs-content-wrapper
      .e-tab-content
      video {
      height: 100%;
      background-color: #000000;
    }
    .elementor-widget-video-playlist .e-tabs-inner-tabs .e-tab-content {
      display: none;
      outline: none;
    }

    @media (max-width: 767px) {
      .elementor-widget-video-playlist .e-tabs-main-area {
        flex-direction: column-reverse;
        height: auto;
      }
      .elementor-widget-video-playlist
        .e-tabs-main-area
        .e-tabs-content-wrapper {
        border: none;
        height: 38%;
      }
      .elementor-widget-video-playlist .e-tabs-wrapper {
        width: auto;
        height: 62%;
      }
      .elementor-widget-video-playlist .e-tabs-items-wrapper .e-tabs-items {
        max-height: 248px;
      }
      .elementor-widget-video-playlist
        .e-tabs-items-wrapper
        .e-tabs-items
        .e-tab-title {
        border: none;
      }
      .elementor-widget-video-playlist .e-tabs-header {
        min-height: 48px;
      }
      .elementor-widget-video-playlist
        .e-tabs-header
        .e-tabs-toggle-videos-display-button {
        display: inline-flex;
      }
      .elementor-widget-video-playlist .e-tabs-header .e-tabs-videos-count {
        margin: 0 6px;
        padding: 14px 0 0;
      }
      .elementor-widget-video-playlist .e-tab-content {
        border: none;
        padding: 0;
        display: flex;
      }
    }
    .elementor-payment-button {
      border: none;
    }

    .elementor-scrolling-tracker {
      --scrolling-track-default-progress-color: #61ce70;
      --scrolling-track-default-color: #eeeeee;
      --scrolling-track-precentage-default-color: $black;
    }

    .elementor-scrolling-tracker-horizontal {
      display: flex;
      width: 100%;
      height: var(--horizontal-height, 20px);
      padding: var(--tracker-padding);
      align-items: center;
      border-top-width: var(--horizontal-border-top-width, 2px);
      border-right-width: var(--horizontal-border-right-width, 2px);
      border-bottom-width: var(--horizontal-border-bottom-width, 2px);
      border-left-width: var(--horizontal-border-left-width, 2px);
      border-style: var(--horizontal-border-style);
      border-color: var(--horizontal-border-color);
      border-radius: var(--border-radius);
      background-color: var(--scrolling-track-default-color);
      direction: var(--direction);
    }
    .elementor-scrolling-tracker-horizontal .current-progress {
      display: none;
      height: 100%;
      align-items: center;
      position: relative;
      border-radius: var(--progress-border-radius);
      border-top-width: var(--horizontal-progress-border-top-width, 2px);
      border-right-width: var(--horizontal-progress-border-right-width, 2px);
      border-bottom-width: var(--horizontal-progress-border-bottom-width, 2px);
      border-left-width: var(--horizontal-progress-border-left-width, 2px);
      border-style: var(--horizontal-progress-border);
      border-color: var(--horizontal-progress-border-color);
      background-color: var(--scrolling-track-default-progress-color);
    }
    .elementor-scrolling-tracker-horizontal
      .current-progress
      .current-progress-percentage {
      inset-inline-end: 0.5em;
      inset-inline-start: auto;
      position: absolute;
      direction: var(--text-direction);
      color: var(
        --percentage-color,
        var(--scrolling-track-precentage-default-color)
      );
    }

    .elementor-scrolling-tracker-circular {
      display: flex;
      width: var(--circular-width, 100px);
      height: var(--circular-height, 100px);
      align-items: center;
      justify-content: center;
      position: relative;
      margin-inline-start: 0;
      margin-inline-end: auto;
    }
    .elementor-scrolling-tracker-circular .current-progress-percentage {
      position: absolute;
      color: var(
        --percentage-color,
        var(--scrolling-track-precentage-default-color)
      );
    }
    .elementor-scrolling-tracker-circular svg {
      position: absolute;
    }
    .elementor-scrolling-tracker-circular .current-progress {
      display: none;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      stroke: var(
        --circular-color,
        var(--scrolling-track-default-progress-color)
      );
      fill: transparent;
      stroke-width: var(--circular-progress-width, 4px);
    }
    .elementor-scrolling-tracker-circular .circle {
      stroke: var(
        --circular-background-color,
        var(--scrolling-track-default-color)
      );
      fill: transparent;
      stroke-width: var(--circular-background-width, 4px);
    }

    .elementor-scrolling-tracker-alignment-left {
      margin-left: 0;
      margin-right: auto;
    }

    .elementor-scrolling-tracker-alignment-center {
      margin-left: auto;
      margin-right: auto;
    }

    .elementor-scrolling-tracker-alignment-right {
      margin-left: auto;
      margin-right: 0;
    }

    .e-con > .elementor-widget-progress-tracker,
    .e-con-inner > .elementor-widget-progress-tracker {
      width: var(--container-widget-width);
      --flex-grow: var(--container-widget-flex-grow);
    }

    .elementor-widget-loop-grid {
      scroll-margin-top: var(--auto-scroll-offset, initial);
    }
    .elementor-widget-loop-grid-1 .elementor-grid {
      grid-template-columns: repeat(1, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-2 .elementor-grid {
      grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-3 .elementor-grid {
      grid-template-columns: repeat(3, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-4 .elementor-grid {
      grid-template-columns: repeat(4, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-5 .elementor-grid {
      grid-template-columns: repeat(5, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-6 .elementor-grid {
      grid-template-columns: repeat(6, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-7 .elementor-grid {
      grid-template-columns: repeat(7, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-8 .elementor-grid {
      grid-template-columns: repeat(8, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-9 .elementor-grid {
      grid-template-columns: repeat(9, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-10 .elementor-grid {
      grid-template-columns: repeat(10, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-11 .elementor-grid {
      grid-template-columns: repeat(11, minmax(0px, 1fr));
    }
    .elementor-widget-loop-grid-12 .elementor-grid {
      grid-template-columns: repeat(12, minmax(0px, 1fr));
    }
    @media (min-width: -1) {
      .elementor-widget-loop-grid-widescreen-1 .elementor-grid {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-2 .elementor-grid {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-3 .elementor-grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-4 .elementor-grid {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-5 .elementor-grid {
        grid-template-columns: repeat(5, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-6 .elementor-grid {
        grid-template-columns: repeat(6, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-7 .elementor-grid {
        grid-template-columns: repeat(7, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-8 .elementor-grid {
        grid-template-columns: repeat(8, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-9 .elementor-grid {
        grid-template-columns: repeat(9, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-10 .elementor-grid {
        grid-template-columns: repeat(10, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-11 .elementor-grid {
        grid-template-columns: repeat(11, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-widescreen-12 .elementor-grid {
        grid-template-columns: repeat(12, minmax(0px, 1fr));
      }
    }
    @media (max-width: -1) {
      .elementor-widget-loop-grid-laptop-1 .elementor-grid {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-2 .elementor-grid {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-3 .elementor-grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-4 .elementor-grid {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-5 .elementor-grid {
        grid-template-columns: repeat(5, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-6 .elementor-grid {
        grid-template-columns: repeat(6, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-7 .elementor-grid {
        grid-template-columns: repeat(7, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-8 .elementor-grid {
        grid-template-columns: repeat(8, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-9 .elementor-grid {
        grid-template-columns: repeat(9, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-10 .elementor-grid {
        grid-template-columns: repeat(10, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-11 .elementor-grid {
        grid-template-columns: repeat(11, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-laptop-12 .elementor-grid {
        grid-template-columns: repeat(12, minmax(0px, 1fr));
      }
    }
    @media (max-width: -1) {
      .elementor-widget-loop-grid-tablet_extra-1 .elementor-grid {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-2 .elementor-grid {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-3 .elementor-grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-4 .elementor-grid {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-5 .elementor-grid {
        grid-template-columns: repeat(5, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-6 .elementor-grid {
        grid-template-columns: repeat(6, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-7 .elementor-grid {
        grid-template-columns: repeat(7, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-8 .elementor-grid {
        grid-template-columns: repeat(8, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-9 .elementor-grid {
        grid-template-columns: repeat(9, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-10 .elementor-grid {
        grid-template-columns: repeat(10, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-11 .elementor-grid {
        grid-template-columns: repeat(11, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet_extra-12 .elementor-grid {
        grid-template-columns: repeat(12, minmax(0px, 1fr));
      }
    }
    @media (max-width: 1024px) {
      .elementor-widget-loop-grid-tablet-1 .elementor-grid {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-2 .elementor-grid {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-3 .elementor-grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-4 .elementor-grid {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-5 .elementor-grid {
        grid-template-columns: repeat(5, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-6 .elementor-grid {
        grid-template-columns: repeat(6, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-7 .elementor-grid {
        grid-template-columns: repeat(7, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-8 .elementor-grid {
        grid-template-columns: repeat(8, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-9 .elementor-grid {
        grid-template-columns: repeat(9, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-10 .elementor-grid {
        grid-template-columns: repeat(10, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-11 .elementor-grid {
        grid-template-columns: repeat(11, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-tablet-12 .elementor-grid {
        grid-template-columns: repeat(12, minmax(0px, 1fr));
      }
    }
    @media (max-width: -1) {
      .elementor-widget-loop-grid-mobile_extra-1 .elementor-grid {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-2 .elementor-grid {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-3 .elementor-grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-4 .elementor-grid {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-5 .elementor-grid {
        grid-template-columns: repeat(5, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-6 .elementor-grid {
        grid-template-columns: repeat(6, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-7 .elementor-grid {
        grid-template-columns: repeat(7, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-8 .elementor-grid {
        grid-template-columns: repeat(8, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-9 .elementor-grid {
        grid-template-columns: repeat(9, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-10 .elementor-grid {
        grid-template-columns: repeat(10, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-11 .elementor-grid {
        grid-template-columns: repeat(11, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile_extra-12 .elementor-grid {
        grid-template-columns: repeat(12, minmax(0px, 1fr));
      }
    }
    @media (max-width: 767px) {
      .elementor-widget-loop-grid-mobile-1 .elementor-grid {
        grid-template-columns: repeat(1, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-2 .elementor-grid {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-3 .elementor-grid {
        grid-template-columns: repeat(3, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-4 .elementor-grid {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-5 .elementor-grid {
        grid-template-columns: repeat(5, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-6 .elementor-grid {
        grid-template-columns: repeat(6, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-7 .elementor-grid {
        grid-template-columns: repeat(7, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-8 .elementor-grid {
        grid-template-columns: repeat(8, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-9 .elementor-grid {
        grid-template-columns: repeat(9, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-10 .elementor-grid {
        grid-template-columns: repeat(10, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-11 .elementor-grid {
        grid-template-columns: repeat(11, minmax(0px, 1fr));
      }
      .elementor-widget-loop-grid-mobile-12 .elementor-grid {
        grid-template-columns: repeat(12, minmax(0px, 1fr));
      }
    }
    .elementor-widget-loop-grid .elementor-grid {
      grid-column-gap: var(--grid-column-gap, 30px);
      grid-row-gap: var(--grid-row-gap, 30px);
    }
    .elementor-widget-loop-grid.e-loading-overlay {
      animation: loadingOpacityAnimation 1s infinite alternate;
    }
    .elementor-widget-loop-grid .e-loop__load-more {
      text-align: var(--load-more-button-align);
    }
    .elementor-widget-loop-grid .e-loop__load-more .elementor-button {
      width: var(--load-more-button-width);
    }
    .elementor-widget-loop-grid.e-load-more-pagination-loading
      > .elementor-widget-container {
      cursor: default;
    }
    .elementor-widget-loop-grid.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner {
      margin-top: var(--load-more—spacing, 30px);
    }
    .elementor-widget-loop-grid.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner
      i,
    .elementor-widget-loop-grid.e-load-more-pagination-loading
      > .elementor-widget-container
      .e-load-more-spinner
      svg {
      display: flex;
    }
    .elementor-widget-loop-grid.e-load-more-pagination-loading
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button-content-wrapper {
      visibility: hidden;
    }
    .elementor-widget-loop-grid.e-load-more-pagination-end
      > .elementor-widget-container
      > .elementor-button-wrapper {
      display: none;
    }
    .elementor-widget-loop-grid.e-load-more-pagination-end
      > .elementor-widget-container
      > .e-load-more-message {
      display: block;
    }
    .elementor-widget-loop-grid.e-load-more-no-spinner
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button-content-wrapper {
      visibility: visible;
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      .e-load-more-spinner {
      display: flex;
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      .e-load-more-spinner
      i,
    .elementor-widget-loop-grid
      > .elementor-widget-container
      .e-load-more-spinner
      svg {
      margin: 0 auto;
      display: none;
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      .e-load-more-spinner
      i {
      color: var(--load-more-spinner-color);
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      .e-load-more-spinner
      svg {
      fill: var(--load-more-spinner-color);
      height: 1em;
      width: 1em;
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      .e-load-more-message {
      color: var(--load-more-message-color);
      text-align: var(--load-more-message-alignment, center);
      display: none;
      margin-top: var(--load-more—spacing, 30px);
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      > .elementor-button-wrapper {
      margin-top: var(--load-more—spacing, 30px);
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      > .elementor-button-wrapper
      .e-load-more-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: inherit;
    }
    .elementor-widget-loop-grid
      > .elementor-widget-container
      > .elementor-button-wrapper
      .elementor-button {
      cursor: pointer;
      position: relative;
    }
    .elementor-widget-loop-grid .e-loop-nothing-found-message {
      text-align: var(--e-loop-nothing-found-message-align, center);
      padding-block-start: var(
        --e-loop-nothing-found-message-space-from-top,
        30px
      );
      padding-block-end: var(
        --e-loop-nothing-found-message-space-from-bottom,
        30px
      );
      color: var(--e-loop-nothing-found-message-color, #1f2124);
    }

    .elementor-loop-container.elementor-posts-masonry {
      align-items: flex-start;
    }
    .elementor-loop-container:not(.elementor-posts-masonry) {
      align-items: stretch;
    }

    @keyframes loadingOpacityAnimation {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.6;
      }
    }
    .elementor-widget-loop-carousel {
      --swiper-pagination-size: 0;
      --swiper-pagination-spacing: 10px;
      --swiper-slides-gap: 10px;
      --swiper-offset-size: 0;
      height: -moz-fit-content;
      height: fit-content;
      --swiper-padding-bottom: calc(
        var(--swiper-pagination-size) + var(--swiper-pagination-spacing)
      );
      --arrow-prev-top-align: 50%;
      --arrow-prev-top-position: 0px;
      --arrow-prev-caption-spacing: 15px;
      --arrow-next-top-align: 50%;
      --arrow-next-top-position: 0px;
      --arrow-next-caption-spacing: 15px;
      --arrow-prev-left-align: 0px;
      --arrow-prev-left-position: 0px;
      --arrow-next-right-align: 0px;
      --arrow-next-right-position: 0px;
      --arrow-next-translate-x: 0px;
      --arrow-next-translate-y: 0px;
      --arrow-prev-translate-x: 0px;
      --arrow-prev-translate-y: 0px;
      --dots-vertical-position: 100%;
      --dots-vertical-offset: 0px;
      --dots-horizontal-position: 50%;
      --dots-horizontal-offset: 0px;
      --dots-horizontal-transform: -50%;
      --dots-vertical-transform: -100%;
      --fraction-vertical-position: 100%;
      --fraction-vertical-offset: 0px;
      --fraction-horizontal-position: 50%;
      --fraction-horizontal-offset: 0px;
      --fraction-horizontal-transform: -50%;
      --fraction-vertical-transform: -100%;
      --horizontal-transform-modifier: 1;
    }
    .elementor-widget-loop-carousel.elementor-pagination-type-bullets {
      --swiper-pagination-size: 6px;
    }
    .elementor-widget-loop-carousel.elementor-pagination-type-fraction {
      --swiper-pagination-size: 16px;
    }
    .elementor-widget-loop-carousel.elementor-pagination-type-progressbar {
      --swiper-pagination-size: 4px;
    }
    .elementor-widget-loop-carousel
      .elementor-loop-container
      > .swiper-wrapper
      > .swiper-slide-active.elementor-edit-area-active {
      overflow: initial;
    }
    .elementor-widget-loop-carousel .elementor-loop-container.offset-left {
      padding-inline-start: var(--swiper-offset-size, 0);
    }
    .elementor-widget-loop-carousel .elementor-loop-container.offset-right {
      padding-inline-end: var(--swiper-offset-size, 0);
    }
    .elementor-widget-loop-carousel .elementor-loop-container.offset-both {
      padding-inline-start: var(--swiper-offset-size, 0);
      padding-inline-end: var(--swiper-offset-size, 0);
    }
    .elementor-widget-loop-carousel
      .swiper-container:not(.swiper-container-initialized)
      > .swiper-wrapper,
    .elementor-widget-loop-carousel
      .swiper:not(.swiper-initialized)
      > .swiper-wrapper {
      gap: var(--swiper-slides-gap);
      overflow: hidden;
    }
    .elementor-widget-loop-carousel
      .swiper-container:not(.swiper-container-initialized)
      > .swiper-wrapper
      > .swiper--slide,
    .elementor-widget-loop-carousel
      .swiper:not(.swiper-initialized)
      > .swiper-wrapper
      > .swiper--slide {
      --number-of-gaps: max(calc(var(--swiper-slides-to-display) - 1), 0);
      --gaps-width-total: calc(
        var(--number-of-gaps) * var(--swiper-slides-gap)
      );
      max-width: calc(
        (100% - var(--gaps-width-total)) / var(--swiper-slides-to-display, 1)
      );
    }
    .elementor-widget-loop-carousel .e-loop-first-edit {
      min-width: 33%;
      margin-block-start: 23px;
    }
    .elementor-widget-loop-carousel .swiper-wrapper .swiper-slide a.e-con {
      display: var(--display);
    }
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-prev,
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-next {
      font-size: var(--arrow-size, 25px);
      color: var(--arrow-normal-color, rgba(238, 238, 238, 0.9));
      border-style: var(--arrow-normal-border-type);
      transition-duration: 0.25s;
      z-index: 2;
    }
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-prev
      svg,
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-next
      svg {
      fill: var(--arrow-normal-color, rgba(238, 238, 238, 0.9));
    }
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-prev:hover,
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-next:hover {
      color: var(--arrow-hover-color, rgba(238, 238, 238, 0.9));
      border-style: var(--arrow-hover-border-type);
    }
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-prev:hover
      svg,
    .elementor-widget-loop-carousel
      .elementor-swiper-button.elementor-swiper-button-next:hover
      svg {
      fill: var(--arrow-hover-color, rgba(238, 238, 238, 0.9));
    }
    .elementor-widget-loop-carousel.elementor-element
      :is(.swiper, .swiper-container)
      ~ .elementor-swiper-button-next {
      top: calc(
        var(--arrow-next-top-align) + var(--arrow-next-top-position) -
          var(--arrow-next-caption-spacing)
      );
      right: calc(
        var(--arrow-next-right-align) + var(--arrow-next-right-position)
      );
      transform: translate(
        var(--arrow-next-translate-x),
        var(--arrow-next-translate-y)
      );
    }
    .elementor-widget-loop-carousel.elementor-element
      :is(.swiper, .swiper-container)
      ~ .elementor-swiper-button-prev {
      top: calc(
        var(--arrow-prev-top-align) + var(--arrow-prev-top-position) -
          var(--arrow-prev-caption-spacing)
      );
      left: calc(
        var(--arrow-prev-left-align) + var(--arrow-prev-left-position)
      );
      transform: translate(
        var(--arrow-prev-translate-x),
        var(--arrow-prev-translate-y)
      );
    }
    .elementor-widget-loop-carousel
      .swiper-container-horizontal
      ~ .swiper-pagination-progressbar,
    .elementor-widget-loop-carousel
      .swiper-horizontal
      ~ .swiper-pagination-progressbar {
      height: var(--swiper-pagination-size);
    }
    .elementor-widget-loop-carousel
      .swiper-pagination-progressbar
      .swiper-pagination-progressbar-fill {
      background: var(--progressbar-normal-color, #000000);
    }
    .elementor-widget-loop-carousel
      .swiper-pagination-progressbar
      .swiper-pagination-progressbar-fill:hover {
      background: var(--progressbar-hover-color, #000000);
    }
    .elementor-widget-loop-carousel .swiper-pagination-fraction {
      color: var(--fraction-color, #000000);
    }
    .elementor-widget-loop-carousel .swiper-pagination-bullet {
      width: var(--swiper-pagination-size);
      height: var(--swiper-pagination-size);
      background: var(--dots-normal-color, #000000);
    }
    .elementor-widget-loop-carousel .swiper-pagination-bullet:hover {
      background: var(--dots-hover-color, #000000);
      opacity: 1;
    }
    .elementor-widget-loop-carousel.elementor-in-place-template-editable
      .elementor-loop-container {
      overflow: visible;
      overflow-x: clip;
    }
    .elementor-widget-loop-carousel .swiper-pagination,
    .elementor-widget-loop-carousel .swiper-pagination-fraction,
    .elementor-widget-loop-carousel .swiper-pagination-custom,
    .elementor-widget-loop-carousel
      .swiper-horizontal
      > .swiper-pagination-bullets,
    .elementor-widget-loop-carousel
      .swiper-pagination-bullets.swiper-pagination-horizontal {
      font-size: var(--swiper-pagination-size);
      line-height: var(--swiper-pagination-size);
    }
    .elementor-widget-loop-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper-container,
    .elementor-widget-loop-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper {
      padding-bottom: var(--swiper-padding-bottom);
    }
    .elementor-widget-loop-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper-container
      .elementor-background-slideshow,
    .elementor-widget-loop-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper
      .elementor-background-slideshow {
      padding-bottom: 0px;
    }
    .elementor-widget-loop-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      .swiper-pagination-bullet {
      vertical-align: top;
    }
    .elementor-widget-loop-carousel .swiper-pagination-bullets {
      top: calc(var(--dots-vertical-position) + var(--dots-vertical-offset));
      left: calc(
        var(--dots-horizontal-position) + var(--dots-horizontal-offset)
      );
      transform: translate(
        calc(
          var(--dots-horizontal-transform) *
            var(--horizontal-transform-modifier)
        ),
        var(--dots-vertical-transform)
      );
      width: -moz-max-content;
      width: max-content;
      height: -moz-max-content;
      height: max-content;
      z-index: 3;
    }
    .elementor-widget-loop-carousel .swiper-pagination-fraction {
      top: calc(
        var(--fraction-vertical-position) + var(--fraction-vertical-offset)
      );
      left: calc(
        var(--fraction-horizontal-position) + var(--fraction-horizontal-offset)
      );
      transform: translate(
        calc(
          var(--fraction-horizontal-transform) *
            var(--horizontal-transform-modifier)
        ),
        var(--fraction-vertical-transform)
      );
      width: -moz-max-content;
      width: max-content;
      height: -moz-max-content;
      height: max-content;
      z-index: 3;
    }

    .e-loop-item * {
      word-break: break-word;
    }

    [class*='elementor-widget-loop'] .elementor-page-title,
    [class*='elementor-widget-loop'] .product_title.entry-title {
      display: initial;
    }

    .e-loop-template-canvas {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
    }
    .e-loop-template-canvas [data-elementor-type='loop-item'].e-loop-item {
      max-width: var(--preview-width, 410px);
      width: var(--preview-width, 410px);
    }
    .e-loop-template-canvas
      [data-elementor-type='loop-item'].e-loop-item
      #elementor-add-new-section {
      width: var(--preview-width, 410px);
    }

    .elementor-widget-n-menu {
      --n-menu-direction: column;
      --n-menu-wrapper-display: flex;
      --n-menu-content-display: flex;
      --n-menu-heading-display: flex;
      --n-menu-heading-justify-content: initial;
      --n-menu-title-color-normal: #1f2124;
      --n-menu-title-color-active: #58d0f5;
      --n-menu-icon-color: var(--n-menu-title-color-normal);
      --n-menu-icon-color-active: var(--n-menu-title-color-active);
      --n-menu-icon-color-hover: var(--n-menu-title-color-hover);
      --n-menu-title-normal-color-dropdown: var(--n-menu-title-color-normal);
      --n-menu-title-active-color-dropdown: var(--n-menu-title-color-active);
      --n-menu-title-hover-color-fallback: #1f2124;
      --n-menu-title-font-size: 1rem;
      --n-menu-title-justify-content: initial;
      --n-menu-title-flex-grow: initial;
      --n-menu-title-justify-content-mobile: initial;
      --n-menu-title-space-between: 0px;
      --n-menu-title-distance-from-content: 0px;
      --n-menu-title-color-hover: #1f2124;
      --n-menu-title-padding: 0.5rem 1rem;
      --n-menu-title-transition: 0.3s;
      --n-menu-title-line-height: 1.5;
      --n-menu-title-order: initial;
      --n-menu-title-direction: initial;
      --n-menu-title-align-items: center;
      --n-menu-toggle-align: center;
      --n-menu-toggle-icon-wrapper-animation-duration: 500ms;
      --n-menu-toggle-icon-hover-duration: 500ms;
      --n-menu-toggle-icon-size: 20px;
      --n-menu-toggle-icon-color: #1f2124;
      --n-menu-toggle-icon-color-hover: var(--n-menu-toggle-icon-color);
      --n-menu-toggle-icon-color-active: var(--n-menu-toggle-icon-color);
      --n-menu-toggle-icon-border-radius: initial;
      --n-menu-toggle-icon-padding: initial;
      --n-menu-toggle-icon-distance-from-dropdown: 0px;
      --n-menu-icon-align-items: center;
      --n-menu-icon-order: initial;
      --n-menu-icon-gap: 5px;
      --n-menu-dropdown-icon-gap: 5px;
      --n-menu-dropdown-content-max-width: initial;
      --n-menu-dropdown-content-box-border-color: #ffffff;
      --n-menu-dropdown-content-box-border-inline-start-width: medium;
      --n-menu-dropdown-content-box-border-block-end-width: medium;
      --n-menu-dropdown-content-box-border-block-start-width: medium;
      --n-menu-dropdown-content-box-border-inline-end-width: medium;
      --n-menu-dropdown-content-box-border-style: none;
      --n-menu-dropdown-headings-height: 0px;
      --n-menu-divider-border-width: var(--n-menu-divider-width, 2px);
      --n-menu-open-animation-duration: 500ms;
      --n-menu-heading-overflow-x: initial;
      --n-menu-heading-wrap: wrap;
      --stretch-width: 100%;
      --stretch-left: initial;
      --stretch-right: initial;
    }
    .elementor-widget-n-menu .e-n-menu {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .elementor-widget-n-menu .e-n-menu-wrapper {
      display: var(--n-menu-wrapper-display);
      flex-direction: column;
    }
    .elementor-widget-n-menu .e-n-menu-heading {
      display: var(--n-menu-heading-display);
      flex-direction: row;
      justify-content: var(--n-menu-heading-justify-content);
      row-gap: var(--n-menu-title-space-between);
      margin: initial;
      padding: initial;
      overflow-x: var(--n-menu-heading-overflow-x);
      flex-wrap: var(--n-menu-heading-wrap);
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .elementor-widget-n-menu .e-n-menu-heading::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
    .elementor-widget-n-menu .e-n-menu-heading.e-scroll {
      cursor: grabbing;
      cursor: -webkit-grabbing;
    }
    .elementor-widget-n-menu .e-n-menu-heading.e-scroll-active {
      position: relative;
    }
    .elementor-widget-n-menu .e-n-menu-heading.e-scroll-active::before {
      content: '';
      position: absolute;
      inset-block: 0;
      inset-inline: -1000vw;
      z-index: 2;
    }
    .elementor-widget-n-menu .e-n-menu-heading .e-n-menu-title {
      position: relative;
    }
    .elementor-widget-n-menu
      .e-n-menu-heading
      .e-n-menu-title:not(:last-child) {
      margin-inline-end: var(--n-menu-title-space-between);
    }
    .elementor-widget-n-menu
      .e-n-menu-heading
      .e-n-menu-title:not(:last-child):after {
      content: var(--n-menu-divider-content, none);
      border-inline-start-width: var(--n-menu-divider-border-width);
      border-inline-start-style: var(--n-menu-divider-style, solid);
      height: var(--n-menu-divider-height, 35%);
      border-color: var(--n-menu-divider-color, #000);
      align-self: center;
      position: absolute;
      right: calc(
        var(--n-menu-title-space-between) / 2 * -1 -
          var(--n-menu-divider-border-width) / 2
      );
    }
    .elementor-widget-n-menu
      .e-n-menu-heading
      .e-n-menu-title
      button:focus-visible,
    .elementor-widget-n-menu .e-n-menu-heading .e-n-menu-title a:focus-visible {
      outline: 5px auto -webkit-focus-ring-color;
    }
    .elementor-widget-n-menu .e-n-menu-content {
      display: var(--n-menu-content-display);
      flex-direction: column;
      position: absolute;
      z-index: 2147483640;
      background-color: transparent;
      min-width: 0;
      width: var(--stretch-width);
      left: var(--stretch-left);
      right: var(--stretch-right);
    }
    .elementor-widget-n-menu .e-n-menu-content > .e-con {
      animation-duration: var(--n-menu-open-animation-duration);
      max-width: calc(
        100% - var(--margin-inline-start, var(--margin-left)) -
          var(--margin-inline-end, var(--margin-right))
      );
    }
    :where(.elementor-widget-n-menu .e-n-menu-content > .e-con) {
      background-color: #ffffff;
    }
    .elementor-widget-n-menu .e-n-menu-content > .e-con:not(.e-active) {
      display: none;
    }
    .elementor-widget-n-menu .e-n-menu-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: var(--n-menu-title-padding);
      font-weight: 500;
      color: var(--n-menu-title-color-normal);
      justify-content: var(--n-menu-title-justify-content);
      flex-grow: var(--n-menu-title-flex-grow);
      border: medium none #ffffff;
      white-space: nowrap;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      margin: initial;
    }
    .elementor-widget-n-menu .e-n-menu-title.e-click,
    .elementor-widget-n-menu .e-n-menu-title.e-click * {
      cursor: pointer;
    }
    .elementor-widget-n-menu .e-n-menu-title-container {
      display: flex;
      flex-direction: var(--n-menu-title-direction);
      align-items: var(--n-menu-title-align-items);
      align-self: var(--n-menu-icon-align-items);
      gap: var(--n-menu-icon-gap);
      justify-content: var(--n-menu-title-justify-content);
    }
    .elementor-widget-n-menu .e-n-menu-title-text {
      display: flex;
      align-items: center;
      font-size: var(--n-menu-title-font-size);
      line-height: var(--n-menu-title-line-height);
      transition: all var(--n-menu-title-transition);
    }
    .elementor-widget-n-menu .e-n-menu-title-text.e-link {
      cursor: pointer;
    }
    .elementor-widget-n-menu .e-n-menu-title-text:not(.e-link),
    .elementor-widget-n-menu .e-n-menu-title-text:not(.e-link) > span {
      cursor: default;
    }
    .elementor-widget-n-menu .e-n-menu-title .e-n-menu-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: var(--n-menu-icon-order);
    }
    .elementor-widget-n-menu .e-n-menu-title .e-n-menu-icon span {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 0s;
    }
    .elementor-widget-n-menu .e-n-menu-title .e-n-menu-icon span i {
      font-size: var(--n-menu-icon-size, var(--n-menu-title-font-size));
      transition: all var(--n-menu-title-transition);
    }
    .elementor-widget-n-menu .e-n-menu-title .e-n-menu-icon span svg {
      fill: var(--n-menu-title-color-normal);
      width: var(--n-menu-icon-size, var(--n-menu-title-font-size));
      height: var(--n-menu-icon-size, var(--n-menu-title-font-size));
      transition: all var(--n-menu-title-transition);
    }
    .elementor-widget-n-menu .e-n-menu-title .e-n-menu-dropdown-icon {
      all: unset;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      display: flex;
      text-align: center;
      height: calc(
        var(--n-menu-title-font-size) * var(--n-menu-title-line-height)
      );
      align-self: var(--n-menu-icon-align-items);
      justify-content: center;
      flex-direction: column;
      position: relative;
      width: -moz-fit-content;
      width: fit-content;
      margin-inline-start: var(--n-menu-dropdown-icon-gap);
      transition: all var(--n-menu-title-transition);
    }
    .elementor-widget-n-menu .e-n-menu-title .e-n-menu-dropdown-icon span i {
      font-size: var(--n-menu-title-font-size);
      width: var(--n-menu-title-font-size);
      transition: all var(--n-menu-title-transition);
    }
    .elementor-widget-n-menu .e-n-menu-title .e-n-menu-dropdown-icon span svg {
      width: var(--n-menu-title-font-size);
      height: var(--n-menu-title-font-size);
      transition: all var(--n-menu-title-transition);
      fill: var(--n-menu-title-color-normal);
    }
    .elementor-widget-n-menu
      .e-n-menu-title
      .e-n-menu-dropdown-icon[aria-expanded='false']
      .e-n-menu-dropdown-icon-opened {
      display: none;
    }
    .elementor-widget-n-menu
      .e-n-menu-title
      .e-n-menu-dropdown-icon[aria-expanded='false']
      .e-n-menu-dropdown-icon-closed {
      display: flex;
    }
    .elementor-widget-n-menu
      .e-n-menu-title
      .e-n-menu-dropdown-icon[aria-expanded='true']
      .e-n-menu-dropdown-icon-closed {
      display: none;
    }
    .elementor-widget-n-menu
      .e-n-menu-title
      .e-n-menu-dropdown-icon[aria-expanded='true']
      .e-n-menu-dropdown-icon-opened {
      display: flex;
    }
    .elementor-widget-n-menu
      .e-n-menu-title:not(.e-current):not(:hover)
      .e-n-menu-title-container
      .e-n-menu-title-text {
      color: var(--n-menu-title-color-normal);
    }
    .elementor-widget-n-menu
      .e-n-menu-title:not(.e-current):not(:hover)
      .e-n-menu-icon
      i {
      color: var(--n-menu-icon-color);
    }
    .elementor-widget-n-menu
      .e-n-menu-title:not(.e-current):not(:hover)
      .e-n-menu-icon
      svg {
      fill: var(--n-menu-icon-color);
    }
    .elementor-widget-n-menu .e-n-menu-title:not(.e-current) .icon-active {
      transform: translate(0, -100%);
      height: 0;
      opacity: 0;
    }
    .elementor-widget-n-menu .e-n-menu-title.e-current span > svg {
      fill: var(--n-menu-title-color-active);
    }
    .elementor-widget-n-menu .e-n-menu-title.e-current,
    .elementor-widget-n-menu .e-n-menu-title.e-current a {
      color: var(--n-menu-title-color-active);
    }
    .elementor-widget-n-menu .e-n-menu-title.e-current .icon-inactive {
      transform: translate(0, -100%);
      height: 0;
      opacity: 0;
    }
    .elementor-widget-n-menu .e-n-menu-title.e-current .e-n-menu-icon span > i {
      color: var(--n-menu-icon-color-active);
    }
    .elementor-widget-n-menu
      .e-n-menu-title.e-current
      .e-n-menu-icon
      span
      > svg {
      fill: var(--n-menu-icon-color-active);
    }
    .elementor-widget-n-menu
      .e-n-menu-title:hover:not(.e-current)
      .e-n-menu-title-text:not(.e-link) {
      cursor: default;
    }
    .elementor-widget-n-menu .e-n-menu-title:hover:not(.e-current) svg {
      fill: var(
        --n-menu-title-color-hover,
        var(--n-menu-title-hover-color-fallback)
      );
    }
    .elementor-widget-n-menu .e-n-menu-title:hover:not(.e-current) i {
      color: var(
        --n-menu-title-color-hover,
        var(--n-menu-title-hover-color-fallback)
      );
    }
    .elementor-widget-n-menu .e-n-menu-title:hover:not(.e-current),
    .elementor-widget-n-menu .e-n-menu-title:hover:not(.e-current) a {
      color: var(--n-menu-title-color-hover);
    }
    .elementor-widget-n-menu
      .e-n-menu-title:hover:not(.e-current)
      .e-n-menu-icon
      i {
      color: var(--n-menu-icon-color-hover);
    }
    .elementor-widget-n-menu
      .e-n-menu-title:hover:not(.e-current)
      .e-n-menu-icon
      svg {
      fill: var(--n-menu-icon-color-hover);
    }
    .elementor-widget-n-menu .e-n-menu-toggle {
      all: unset;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      display: none;
      align-self: var(--n-menu-toggle-align);
      position: relative;
      z-index: 1000;
    }
    .elementor-widget-n-menu .e-n-menu-toggle:focus-visible {
      outline: 5px auto -webkit-focus-ring-color;
    }
    .elementor-widget-n-menu .e-n-menu-toggle i {
      font-size: var(--n-menu-toggle-icon-size);
      color: var(--n-menu-toggle-icon-color);
      transition: all var(--n-menu-toggle-icon-hover-duration);
    }
    .elementor-widget-n-menu .e-n-menu-toggle svg {
      fill: var(--n-menu-toggle-icon-color);
      width: var(--n-menu-toggle-icon-size);
      height: auto;
      transition: all var(--n-menu-toggle-icon-hover-duration);
    }
    .elementor-widget-n-menu .e-n-menu-toggle span {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: var(--n-menu-toggle-icon-padding);
      border-radius: var(--n-menu-toggle-icon-border-radius);
    }
    .elementor-widget-n-menu .e-n-menu-toggle span.e-close {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .elementor-widget-n-menu .e-n-menu-toggle span.e-close svg {
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
    .elementor-widget-n-menu .e-n-menu-toggle [class^='elementor-animation-'] {
      animation-duration: var(--n-menu-toggle-icon-wrapper-animation-duration);
      transition-duration: var(--n-menu-toggle-icon-wrapper-animation-duration);
    }
    .elementor-widget-n-menu .e-n-menu-toggle:hover i {
      color: var(--n-menu-toggle-icon-color-hover);
    }
    .elementor-widget-n-menu .e-n-menu-toggle:hover svg {
      fill: var(--n-menu-toggle-icon-color-hover);
    }
    .elementor-widget-n-menu .e-n-menu-toggle[aria-expanded='true'] .e-open {
      opacity: 0;
    }
    .elementor-widget-n-menu .e-n-menu-toggle[aria-expanded='true'] .e-close {
      opacity: 1;
    }
    .elementor-widget-n-menu .e-n-menu-toggle[aria-expanded='true'] i {
      color: var(--n-menu-toggle-icon-color-active);
    }
    .elementor-widget-n-menu .e-n-menu-toggle[aria-expanded='true'] svg {
      fill: var(--n-menu-toggle-icon-color-active);
    }
    .elementor-widget-n-menu
      .e-n-menu:not([data-layout='dropdown']):not(.content-above)
      .e-n-menu-content {
      top: 100%;
      z-index: 1;
    }
    .elementor-widget-n-menu
      .e-n-menu:not([data-layout='dropdown']):not(.content-above)
      .e-n-menu-content.e-active {
      padding-block-start: var(--n-menu-title-distance-from-content);
      z-index: 2;
    }
    .elementor-widget-n-menu
      .e-n-menu:not([data-layout='dropdown']).content-above
      .e-n-menu-content {
      bottom: 100%;
      z-index: 1;
    }
    .elementor-widget-n-menu
      .e-n-menu:not([data-layout='dropdown']).content-above
      .e-n-menu-content.e-active {
      padding-block-end: var(--n-menu-title-distance-from-content);
      z-index: 2;
    }
    .elementor-widget-n-menu .e-n-menu[data-layout='dropdown'] {
      gap: 0;
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-wrapper {
      min-width: 0px;
      width: var(--stretch-width);
      flex-direction: column;
      position: absolute;
      z-index: 2147483640;
      background-color: transparent;
      top: 100%;
      left: var(--stretch-left);
      right: var(--stretch-right);
      border-inline-start: var(
        --n-menu-dropdown-content-box-border-width-inline-start
      );
      border-block-end: var(
        --n-menu-dropdown-content-box-border-width-block-end
      );
      border-block-start: var(
        --n-menu-dropdown-content-box-border-width-block-start
      );
      border-inline-end: var(
        --n-menu-dropdown-content-box-border-width-inline-end
      );
      border-style: var(--n-menu-dropdown-content-box-border-style);
      border-color: var(--n-menu-dropdown-content-box-border-color);
      border-radius: var(--n-menu-dropdown-content-box-border-radius);
      box-shadow: var(--n-menu-dropdown-content-box-shadow-horizontal)
        var(--n-menu-dropdown-content-box-shadow-vertical)
        var(--n-menu-dropdown-content-box-shadow-blur)
        var(--n-menu-dropdown-content-box-shadow-spread)
        var(--n-menu-dropdown-content-box-shadow-color)
        var(--n-menu-dropdown-content-box-shadow-position);
      max-height: var(--n-menu-dropdown-content-box-height);
      transition: max-height 0.3s;
      animation: hide-scroll 0.3s backwards;
      overflow-y: auto;
      overflow-x: hidden;
      margin-block-start: var(--n-menu-toggle-icon-distance-from-dropdown);
    }
    .elementor-widget-n-menu .e-n-menu[data-layout='dropdown'] .e-n-menu-title {
      justify-content: var(--n-menu-title-justify-content-mobile);
      width: initial;
      background-color: #ffffff;
      white-space: normal;
      order: var(--n-menu-title-order);
      margin-inline-end: initial;
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-title:not(:first-child) {
      margin-block-start: var(--n-menu-title-space-between);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-title:not(.e-current)
      .e-n-menu-title-container
      .e-n-menu-title-text {
      color: var(--n-menu-title-normal-color-dropdown);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-title:not(.e-current)
      .e-n-menu-dropdown-icon
      i {
      color: var(--n-menu-title-normal-color-dropdown);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-title:not(.e-current)
      .e-n-menu-dropdown-icon
      svg {
      fill: var(--n-menu-title-normal-color-dropdown);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-title.e-current,
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-title.e-current
      a {
      color: var(--n-menu-title-active-color-dropdown);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-title.e-current
      .e-n-menu-dropdown-icon
      span
      svg {
      fill: var(--n-menu-title-active-color-dropdown);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-content {
      overflow: hidden;
      width: initial;
      left: initial;
      right: initial;
      --n-menu-dropdown-content-max-width: initial;
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-content
      > .e-con {
      order: var(--n-menu-title-order);
      width: var(--width);
      margin-block-start: var(--n-menu-title-distance-from-content);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-content
      > .e-con
      .elementor-empty-view {
      min-width: initial;
      width: 100%;
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-content
      > .e-con-inner {
      max-width: var(--content-width);
    }
    .elementor-widget-n-menu
      .e-n-menu[data-layout='dropdown']
      .e-n-menu-toggle[aria-expanded='true']
      + .e-n-menu-wrapper {
      --n-menu-wrapper-display: flex;
    }
    .elementor-widget-n-menu.e-fit_to_content
      :where(.e-n-menu-content > .e-con) {
      --width: fit-content;
    }
    .elementor-widget-n-menu.e-fit_to_content
      :where(.e-n-menu-content > .e-con).e-empty {
      --empty-top-level-menu-content-container-min-width: 400px;
      --width: var(--empty-top-level-menu-content-container-min-width);
    }
    .elementor-widget-n-menu.e-fit_to_content
      :where(.e-n-menu-content > .e-con)
      .e-con.e-empty {
      --nested-containers-min-width-for-dropzone: 150px;
      min-width: var(--nested-containers-min-width-for-dropzone);
    }
    .elementor-widget-n-menu.elementor-loading {
      pointer-events: none;
    }

    .elementor.elementor .elementor-widget-n-menu.e-n-menu-layout-dropdown {
      --n-menu-wrapper-display: none;
      --n-menu-heading-display: contents;
      --n-menu-content-display: contents;
    }
    .elementor.elementor
      .elementor-widget-n-menu.e-n-menu-layout-dropdown
      .e-n-menu-toggle {
      display: flex;
    }

    @media (max-width: 767px) {
      .elementor.elementor .elementor-widget-n-menu.e-n-menu-mobile {
        --n-menu-wrapper-display: none;
        --n-menu-heading-display: contents;
        --n-menu-content-display: contents;
      }
      .elementor.elementor
        .elementor-widget-n-menu.e-n-menu-mobile
        .e-n-menu-toggle {
        display: flex;
      }
    }
    @media (max-width: -1) {
      .elementor.elementor .elementor-widget-n-menu.e-n-menu-mobile_extra {
        --n-menu-wrapper-display: none;
        --n-menu-heading-display: contents;
        --n-menu-content-display: contents;
      }
      .elementor.elementor
        .elementor-widget-n-menu.e-n-menu-mobile_extra
        .e-n-menu-toggle {
        display: flex;
      }
    }
    @media (max-width: 1024px) {
      .elementor.elementor .elementor-widget-n-menu.e-n-menu-tablet {
        --n-menu-wrapper-display: none;
        --n-menu-heading-display: contents;
        --n-menu-content-display: contents;
      }
      .elementor.elementor
        .elementor-widget-n-menu.e-n-menu-tablet
        .e-n-menu-toggle {
        display: flex;
      }
    }
    @media (max-width: -1) {
      .elementor.elementor .elementor-widget-n-menu.e-n-menu-tablet_extra {
        --n-menu-wrapper-display: none;
        --n-menu-heading-display: contents;
        --n-menu-content-display: contents;
      }
      .elementor.elementor
        .elementor-widget-n-menu.e-n-menu-tablet_extra
        .e-n-menu-toggle {
        display: flex;
      }
    }
    @media (max-width: -1) {
      .elementor.elementor .elementor-widget-n-menu.e-n-menu-laptop {
        --n-menu-wrapper-display: none;
        --n-menu-heading-display: contents;
        --n-menu-content-display: contents;
      }
      .elementor.elementor
        .elementor-widget-n-menu.e-n-menu-laptop
        .e-n-menu-toggle {
        display: flex;
      }
    }
    @keyframes hide-scroll {
      from,
      to {
        overflow: hidden;
      }
    }
    .e-con > .elementor-widget-n-menu,
    .e-con-inner > .elementor-widget-n-menu {
      --flex-grow: var(--container-widget-flex-grow);
    }

    [data-core-v316-plus='true']
      .elementor-widget-n-menu
      .e-n-menu
      .e-n-menu-content
      > .e-con {
      --padding-top: initial;
      --padding-right: initial;
      --padding-bottom: initial;
      --padding-left: initial;
    }

    .elementor-widget-n-carousel {
      --e-n-carousel-slides-to-show: 3;
      --e-n-carousel-swiper-slides-gap: 10px;
      --e-n-carousel-swiper-slides-to-display: 3;
      --e-n-carousel-swiper-pagination-size: 0;
      --e-n-carousel-swiper-pagination-spacing: 10px;
      --e-n-carousel-swiper-offset-size: 0;
      height: -moz-fit-content;
      height: fit-content;
      --e-n-carousel-arrow-prev-top-align: 50%;
      --e-n-carousel-arrow-prev-top-position: 0px;
      --e-n-carousel-arrow-prev-caption-spacing: 15px;
      --e-n-carousel-arrow-next-top-align: 50%;
      --e-n-carousel-arrow-next-top-position: 0px;
      --e-n-carousel-arrow-next-caption-spacing: 15px;
      --e-n-carousel-arrow-prev-left-align: 0px;
      --e-n-carousel-arrow-prev-left-position: 0px;
      --e-n-carousel-arrow-next-right-align: 0px;
      --e-n-carousel-arrow-next-right-position: 0px;
      --e-n-carousel-arrow-next-translate-x: 0px;
      --e-n-carousel-arrow-next-translate-y: 0px;
      --e-n-carousel-arrow-prev-translate-x: 0px;
      --e-n-carousel-arrow-prev-translate-y: 0px;
      --e-n-carousel-swiper-padding-bottom: calc(
        var(--e-n-carousel-swiper-pagination-size) +
          var(--e-n-carousel-swiper-pagination-spacing)
      );
      --e-n-carousel-dots-vertical-position: 100%;
      --e-n-carousel-dots-vertical-offset: 0px;
      --e-n-carousel-dots-horizontal-position: 50%;
      --e-n-carousel-dots-horizontal-offset: 0px;
      --e-n-carousel-dots-horizontal-transform: -50%;
      --e-n-carousel-dots-vertical-transform: -100%;
      --e-n-carousel-fraction-vertical-position: 100%;
      --e-n-carousel-fraction-vertical-offset: 0px;
      --e-n-carousel-fraction-horizontal-position: 50%;
      --e-n-carousel-fraction-horizontal-offset: 0px;
      --e-n-carousel-fraction-horizontal-transform: -50%;
      --e-n-carousel-fraction-vertical-transform: -100%;
      --e-n-carousel-horizontal-transform-modifier: 1;
    }
    .elementor-widget-n-carousel .e-n-carousel {
      display: flex;
      min-width: 0;
    }
    .elementor-widget-n-carousel
      .e-n-carousel
      > .swiper-wrapper
      > .swiper-slide-active.elementor-edit-area-active {
      overflow: initial;
    }
    .elementor-widget-n-carousel .e-n-carousel.offset-left {
      padding-inline-start: var(--e-n-carousel-swiper-offset-size, 0);
    }
    .elementor-widget-n-carousel .e-n-carousel.offset-right {
      padding-inline-end: var(--e-n-carousel-swiper-offset-size, 0);
    }
    .elementor-widget-n-carousel .e-n-carousel.offset-both {
      padding-inline-start: var(--e-n-carousel-swiper-offset-size, 0);
      padding-inline-end: var(--e-n-carousel-swiper-offset-size, 0);
    }
    .elementor-widget-n-carousel
      .e-n-carousel
      > .swiper-wrapper
      .swiper-slide
      a.e-con {
      display: var(--display);
    }
    .elementor-widget-n-carousel
      .e-n-carousel
      > .swiper-wrapper
      > .swiper-slide {
      flex-shrink: 0;
      height: var(--e-n-carousel-slide-height, initial);
    }
    .elementor-widget-n-carousel
      .e-n-carousel
      > .swiper-wrapper
      > .swiper-slide
      > .e-con {
      max-width: calc(
        100% - var(--margin-inline-start, var(--margin-left)) -
          var(--margin-inline-end, var(--margin-right))
      );
      height: var(--e-n-carousel-slide-container-height, initial);
    }
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-prev,
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-next {
      font-size: var(--e-n-carousel-arrow-size, 25px);
      color: var(--e-n-carousel-arrow-normal-color, rgba(238, 238, 238, 0.9));
      border-style: var(--e-n-carousel-arrow-normal-border-type);
      transition-duration: 0.25s;
      z-index: 2;
    }
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-prev
      svg,
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-next
      svg {
      fill: var(--e-n-carousel-arrow-normal-color, rgba(238, 238, 238, 0.9));
    }
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-prev:hover,
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-next:hover {
      color: var(--e-n-carousel-arrow-hover-color, rgba(238, 238, 238, 0.9));
      border-style: var(--e-n-carousel-arrow-hover-border-type);
    }
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-prev:hover
      svg,
    .elementor-widget-n-carousel
      .elementor-swiper-button.elementor-swiper-button-next:hover
      svg {
      fill: var(--e-n-carousel-arrow-hover-color, rgba(238, 238, 238, 0.9));
    }
    .elementor-widget-n-carousel.elementor-element
      :is(.swiper, .swiper-container)
      ~ .elementor-swiper-button-next {
      top: calc(
        var(--e-n-carousel-arrow-next-top-align) +
          var(--e-n-carousel-arrow-next-top-position) -
          var(--e-n-carousel-arrow-next-caption-spacing)
      );
      right: calc(
        var(--e-n-carousel-arrow-next-right-align) +
          var(--e-n-carousel-arrow-next-right-position)
      );
      transform: translate(
        var(--e-n-carousel-arrow-next-translate-x),
        var(--e-n-carousel-arrow-next-translate-y)
      );
    }
    .elementor-widget-n-carousel.elementor-element
      :is(.swiper, .swiper-container)
      ~ .elementor-swiper-button-prev {
      top: calc(
        var(--e-n-carousel-arrow-prev-top-align) +
          var(--e-n-carousel-arrow-prev-top-position) -
          var(--e-n-carousel-arrow-prev-caption-spacing)
      );
      left: calc(
        var(--e-n-carousel-arrow-prev-left-align) +
          var(--e-n-carousel-arrow-prev-left-position)
      );
      transform: translate(
        var(--e-n-carousel-arrow-prev-translate-x),
        var(--e-n-carousel-arrow-prev-translate-y)
      );
    }
    .elementor-widget-n-carousel.elementor-pagination-type-bullets {
      --e-n-carousel-swiper-pagination-size: 6px;
    }
    .elementor-widget-n-carousel.elementor-pagination-type-fraction {
      --e-n-carousel-swiper-pagination-size: 16px;
    }
    .elementor-widget-n-carousel.elementor-pagination-type-progressbar {
      --e-n-carousel-swiper-pagination-size: 4px;
    }
    .elementor-widget-n-carousel .swiper-pagination-progressbar {
      height: var(--e-n-carousel-swiper-pagination-size);
    }
    .elementor-widget-n-carousel
      .swiper-pagination-progressbar
      .swiper-pagination-progressbar-fill {
      background: var(--e-n-carousel-progressbar-normal-color, #000000);
    }
    .elementor-widget-n-carousel
      .swiper-pagination-progressbar
      .swiper-pagination-progressbar-fill:hover {
      background: var(--e-n-carousel-progressbar-hover-color, #000000);
    }
    .elementor-widget-n-carousel .swiper-pagination-fraction {
      color: var(--e-n-carousel-fraction-color, #000000);
    }
    .elementor-widget-n-carousel .swiper-pagination-bullet {
      width: var(--e-n-carousel-swiper-pagination-size);
      height: var(--e-n-carousel-swiper-pagination-size);
      background: var(--e-n-carousel-dots-normal-color, #000000);
    }
    .elementor-widget-n-carousel .swiper-pagination-bullet:hover {
      background: var(--e-n-carousel-dots-hover-color, #000000);
      opacity: 1;
    }
    .elementor-widget-n-carousel .swiper-pagination,
    .elementor-widget-n-carousel .swiper-pagination-fraction,
    .elementor-widget-n-carousel .swiper-pagination-custom,
    .elementor-widget-n-carousel
      .swiper-horizontal
      > .swiper-pagination-bullets,
    .elementor-widget-n-carousel
      .swiper-pagination-bullets.swiper-pagination-horizontal {
      font-size: var(--e-n-carousel-swiper-pagination-size);
      line-height: var(--e-n-carousel-swiper-pagination-size);
    }
    .elementor-widget-n-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper-container,
    .elementor-widget-n-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper {
      padding-bottom: var(--e-n-carousel-swiper-padding-bottom);
    }
    .elementor-widget-n-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper-container
      .elementor-background-slideshow,
    .elementor-widget-n-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      > .swiper
      .elementor-background-slideshow {
      padding-bottom: 0px;
    }
    .elementor-widget-n-carousel.elementor-pagination-position-outside
      > .elementor-widget-container
      .swiper-pagination-bullet {
      vertical-align: top;
    }
    .elementor-widget-n-carousel .swiper-pagination-bullets {
      top: calc(
        var(--e-n-carousel-dots-vertical-position) +
          var(--e-n-carousel-dots-vertical-offset)
      );
      left: calc(
        var(--e-n-carousel-dots-horizontal-position) +
          var(--e-n-carousel-dots-horizontal-offset)
      );
      transform: translate(
        calc(
          var(--e-n-carousel-dots-horizontal-transform) *
            var(--e-n-carousel-horizontal-transform-modifier)
        ),
        var(--e-n-carousel-dots-vertical-transform)
      );
      width: -moz-max-content;
      width: max-content;
      height: -moz-max-content;
      height: max-content;
      z-index: 3;
    }
    .elementor-widget-n-carousel .swiper-pagination-fraction {
      top: calc(
        var(--e-n-carousel-fraction-vertical-position) +
          var(--e-n-carousel-fraction-vertical-offset)
      );
      left: calc(
        var(--e-n-carousel-fraction-horizontal-position) +
          var(--e-n-carousel-fraction-horizontal-offset)
      );
      transform: translate(
        calc(
          var(--e-n-carousel-fraction-horizontal-transform) *
            var(--e-n-carousel-horizontal-transform-modifier)
        ),
        var(--e-n-carousel-fraction-vertical-transform)
      );
      width: -moz-max-content;
      width: max-content;
      height: -moz-max-content;
      height: max-content;
      z-index: 3;
    }

    .swiper-container:not(.swiper-container-initialized) > .swiper-wrapper,
    .swiper:not(.swiper-initialized) > .swiper-wrapper {
      gap: var(--e-n-carousel-swiper-slides-gap);
      overflow: hidden;
    }
    .swiper-container:not(.swiper-container-initialized)
      > .swiper-wrapper
      > .swiper--slide,
    .swiper:not(.swiper-initialized) > .swiper-wrapper > .swiper--slide {
      --e-n-carousel-number-of-gaps: max(
        calc(var(--e-n-carousel-swiper-slides-to-display) - 1),
        0
      );
      --e-n-carousel-gaps-width-total: calc(
        var(--e-n-carousel-number-of-gaps) *
          var(--e-n-carousel-swiper-slides-gap)
      );
      max-width: calc(
        (100% - var(--e-n-carousel-gaps-width-total)) /
          var(--e-n-carousel-swiper-slides-to-display, 1)
      );
    }

    [data-core-v316-plus='true']
      .elementor-widget-n-carousel
      .e-n-carousel
      .swiper-slide
      > .e-con {
      --padding-top: initial;
      --padding-right: initial;
      --padding-bottom: initial;
      --padding-left: initial;
    }

    .elementor-widget-taxonomy-filter {
      --e-filter-display: flex;
      --e-filter-direction: row;
      --e-filter-justify-content: center;
      --e-filter-wrap: wrap;
      --e-filter-align-items: center;
      --e-filter-item-width: initial;
      --e-filter-item-max-width: calc(
        100% - calc(var(--e-filter-item-padding) * 3)
      );
      --e-filter-item-justify-content: center;
      --e-filter-item-flex-grow: 0;
      --e-filter-item-align-items: center;
      --e-filter-item-border-radius: initial;
      --e-filter-space-between: 20px;
      --e-filter-normal-text-color: #69727d;
      --e-filter-active-text-color: #0c0d0e;
      --e-filter-hover-text-color: #0c0d0e;
      --e-filter-overflow-x: initial;
      --e-filter-white-space: nowrap;
      --e-filter-item-align-text: center;
      --e-filter-item-padding: 8px;
      --e-filter-item-box-sizing: initial;
    }
    .elementor-widget-taxonomy-filter .e-filter {
      display: var(--e-filter-display);
      flex-direction: var(--e-filter-direction);
      flex-shrink: 0;
      justify-content: var(--e-filter-justify-content);
      align-items: var(--e-filter-align-items);
      gap: var(--e-filter-space-between);
      overflow-x: var(--e-filter-overflow-x);
      flex-wrap: var(--e-filter-wrap);
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .elementor-widget-taxonomy-filter .e-filter::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
    .elementor-widget-taxonomy-filter .e-filter.e-scroll {
      cursor: grabbing;
      cursor: -webkit-grabbing;
    }
    .elementor-widget-taxonomy-filter .e-filter.e-scroll-active {
      position: relative;
    }
    .elementor-widget-taxonomy-filter .e-filter.e-scroll-active::before {
      content: '';
      position: absolute;
      inset-block: 0;
      inset-inline: -1000vw;
      z-index: 2;
    }
    .elementor-widget-taxonomy-filter .e-filter-item {
      all: unset;
      border-width: 1px;
      border-style: none;
      border-color: #69727d;
      border-radius: var(--e-filter-item-border-radius);
      line-height: initial;
      color: var(--e-filter-normal-text-color);
      padding: var(--e-filter-item-padding);
      display: var(--e-filter-display);
      justify-content: var(--e-filter-item-justify-content);
      align-items: var(--e-filter-item-align-items);
      flex-grow: var(--e-filter-item-flex-grow);
      flex-basis: content;
      flex-shrink: 0;
      width: var(--e-filter-item-width);
      max-width: var(--e-filter-item-max-width);
      white-space: var(--e-filter-white-space);
      text-align: var(--e-filter-item-align-text);
      box-sizing: var(--e-filter-item-box-sizing);
      overflow-wrap: break-word;
      overflow: hidden;
    }
    .elementor-widget-taxonomy-filter .e-filter-item[aria-pressed='true'] {
      color: var(--e-filter-active-text-color);
      border-color: #0c0d0e;
    }
    .elementor-widget-taxonomy-filter
      .e-filter-item:hover:not([aria-pressed='true']) {
      color: var(--e-filter-hover-text-color);
      border-color: #0c0d0e;
    }
    .elementor-widget-taxonomy-filter .e-filter-item[aria-pressed='true'],
    .elementor-widget-taxonomy-filter .e-filter-item:hover {
      background-color: initial;
    }
    .elementor-widget-taxonomy-filter .e-filter-item[data-filter='__all'] {
      order: -1;
    }
    .elementor-widget-taxonomy-filter .e-filter-item:focus {
      background-color: initial;
      outline: none;
    }
    .elementor-widget-taxonomy-filter .e-filter-item:focus-visible {
      outline: 5px auto -webkit-focus-ring-color;
    }
    .elementor-widget-taxonomy-filter .e-filter-empty {
      background-color: rgba(213, 216, 220, 0.8);
      color: #0c0d0e;
      text-align: center;
      font-size: 1rem;
      padding: 1rem 0;
    }

    .elementor-widget-loop-grid .e-loop-item.e-inactive {
      display: none;
    }
  }
}
