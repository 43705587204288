.customElementor {
  :global {
    #fbuilder .clearer {
      clear: both;
    }
    #fbuilder,
    #fbuilder * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    #fbuilder .fform,
    #fbuilder .fields {
      padding: 5px 0;
      clear: both;
    }
    #fbuilder .fform h1,
    #fbuilder .fform h2 {
      margin: 5px 0;
      padding: 0;
    }
    #fbuilder a,
    #fbuilder h1,
    #fbuilder h2,
    #fbuilder h3,
    #fbuilder h4,
    #fbuilder h5,
    #fbuilder label,
    #fbuilder p,
    #fbuilder span {
      white-space: normal;
    }
    #fbuilder label {
      position: relative;
    }
    #fbuilder .cff-help-icon {
      clear: none;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
    #fbuilder input[type='date'],
    #fbuilder input[type='datetime'],
    #fbuilder input[type='email'],
    #fbuilder input[type='number'],
    #fbuilder input[type='password'],
    #fbuilder input[type='search'],
    #fbuilder input[type='text'],
    #fbuilder input[type='time'],
    #fbuilder input[type='url'],
    #fbuilder select,
    #fbuilder textarea {
      width: auto;
      display: inline-block;
      max-width: none;
    }
    #fbuilder .small {
      width: 25% !important;
    }
    #fbuilder .medium {
      width: 50% !important;
    }
    #fbuilder .large {
      width: 100% !important;
    }
    @media screen and (min-width: 740px), print {
      #fbuilder .fields.column2 {
        float: left;
        width: 49.9%;
        clear: none;
      }
      #fbuilder .fields.column3 {
        float: left;
        width: 33.3%;
        clear: none;
      }
      #fbuilder .fields.column4 {
        float: left;
        width: 24.9%;
        clear: none;
      }
      #fbuilder .fields.column5 {
        float: left;
        width: 19.9%;
        clear: none;
      }
      [dir='rtl'] #fbuilder .fields.column2,
      [dir='rtl'] #fbuilder .fields.column3,
      [dir='rtl'] #fbuilder .fields.column4,
      [dir='rtl'] #fbuilder .fields.column5 {
        float: right;
      }
    }
    #fbuilder .col-xs-1,
    #fbuilder .col-xs-10,
    #fbuilder .col-xs-11,
    #fbuilder .col-xs-2,
    #fbuilder .col-xs-3,
    #fbuilder .col-xs-4,
    #fbuilder .col-xs-5,
    #fbuilder .col-xs-6,
    #fbuilder .col-xs-7,
    #fbuilder .col-xs-8,
    #fbuilder .col-xs-9 {
      float: left;
      clear: none;
    }
    #fbuilder .col-xs-12 {
      float: none;
      clear: both;
    }
    #fbuilder .col-xs-1 {
      width: 8.33333333%;
    }
    #fbuilder .col-xs-2 {
      width: 16.66666667%;
    }
    #fbuilder .col-xs-3 {
      width: 25%;
    }
    #fbuilder .col-xs-4 {
      width: 33.33333333%;
    }
    #fbuilder .col-xs-5 {
      width: 41.66666667%;
    }
    #fbuilder .col-xs-6 {
      width: 50%;
    }
    #fbuilder .col-xs-7 {
      width: 58.33333333%;
    }
    #fbuilder .col-xs-8 {
      width: 66.66666667%;
    }
    #fbuilder .col-xs-9 {
      width: 75%;
    }
    #fbuilder .col-xs-10 {
      width: 83.33333333%;
    }
    #fbuilder .col-xs-11 {
      width: 91.66666667%;
    }
    #fbuilder .col-xs-offset-1 {
      margin-left: 8.33333333%;
    }
    #fbuilder .col-xs-offset-2 {
      margin-left: 16.66666667%;
    }
    #fbuilder .col-xs-offset-3 {
      margin-left: 25%;
    }
    #fbuilder .col-xs-offset-4 {
      margin-left: 33.33333333%;
    }
    #fbuilder .col-xs-offset-5 {
      margin-left: 41.66666667%;
    }
    #fbuilder .col-xs-offset-6 {
      margin-left: 50%;
    }
    #fbuilder .col-xs-offset-7 {
      margin-left: 58.33333333%;
    }
    #fbuilder .col-xs-offset-8 {
      margin-left: 66.66666667%;
    }
    #fbuilder .col-xs-offset-9 {
      margin-left: 75%;
    }
    #fbuilder .col-xs-offset-10 {
      margin-left: 83.33333333%;
    }
    #fbuilder .col-xs-offset-11 {
      margin-left: 91.66666667%;
    }
    [dir='rtl'] #fbuiler .col-xs-1,
    [dir='rtl'] #fbuiler .col-xs-10,
    [dir='rtl'] #fbuiler .col-xs-11,
    [dir='rtl'] #fbuiler .col-xs-2,
    [dir='rtl'] #fbuiler .col-xs-3,
    [dir='rtl'] #fbuiler .col-xs-4,
    [dir='rtl'] #fbuiler .col-xs-5,
    [dir='rtl'] #fbuiler .col-xs-6,
    [dir='rtl'] #fbuiler .col-xs-7,
    [dir='rtl'] #fbuiler .col-xs-8,
    [dir='rtl'] #fbuiler .col-xs-9 {
      float: right;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-1 {
      margin-right: 8.33333333%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-2 {
      margin-right: 16.66666667%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-3 {
      margin-right: 25%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-4 {
      margin-right: 33.33333333%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-5 {
      margin-right: 41.66666667%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-6 {
      margin-right: 50%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-7 {
      margin-right: 58.33333333%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-8 {
      margin-right: 66.66666667%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-9 {
      margin-right: 75%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-10 {
      margin-right: 83.33333333%;
    }
    [dir='rtl'] #fbuilder .col-xs-offset-11 {
      margin-right: 91.66666667%;
    }
    @media only screen and (min-width: 48em) {
      #fbuilder .col-sm-1,
      #fbuilder .col-sm-10,
      #fbuilder .col-sm-11,
      #fbuilder .col-sm-2,
      #fbuilder .col-sm-3,
      #fbuilder .col-sm-4,
      #fbuilder .col-sm-5,
      #fbuilder .col-sm-6,
      #fbuilder .col-sm-7,
      #fbuilder .col-sm-8,
      #fbuilder .col-sm-9 {
        float: left;
        clear: none;
        margin-left: 0;
        margin-right: 0;
      }
      #fbuilder .col-sm-12 {
        float: none;
        clear: both;
        margin-left: 0;
        margin-right: 0;
      }
      #fbuilder .col-sm-1 {
        width: 8.33333333%;
      }
      #fbuilder .col-sm-2 {
        width: 16.66666667%;
      }
      #fbuilder .col-sm-3 {
        width: 25%;
      }
      #fbuilder .col-sm-4 {
        width: 33.33333333%;
      }
      #fbuilder .col-sm-5 {
        width: 41.66666667%;
      }
      #fbuilder .col-sm-6 {
        width: 50%;
      }
      #fbuilder .col-sm-7 {
        width: 58.33333333%;
      }
      #fbuilder .col-sm-8 {
        width: 66.66666667%;
      }
      #fbuilder .col-sm-9 {
        width: 75%;
      }
      #fbuilder .col-sm-10 {
        width: 83.33333333%;
      }
      #fbuilder .col-sm-11 {
        width: 91.66666667%;
      }
      #fbuilder .col-sm-12 {
        width: 100%;
      }
      #fbuilder .col-sm-offset-0 {
        margin-left: 0;
      }
      #fbuilder .col-sm-offset-1 {
        margin-left: 8.33333333%;
      }
      #fbuilder .col-sm-offset-2 {
        margin-left: 16.66666667%;
      }
      #fbuilder .col-sm-offset-3 {
        margin-left: 25%;
      }
      #fbuilder .col-sm-offset-4 {
        margin-left: 33.33333333%;
      }
      #fbuilder .col-sm-offset-5 {
        margin-left: 41.66666667%;
      }
      #fbuilder .col-sm-offset-6 {
        margin-left: 50%;
      }
      #fbuilder .col-sm-offset-7 {
        margin-left: 58.33333333%;
      }
      #fbuilder .col-sm-offset-8 {
        margin-left: 66.66666667%;
      }
      #fbuilder .col-sm-offset-9 {
        margin-left: 75%;
      }
      #fbuilder .col-sm-offset-10 {
        margin-left: 83.33333333%;
      }
      #fbuilder .col-sm-offset-11 {
        margin-left: 91.66666667%;
      }
      [dir='rtl'] #fbuilder .col-sm-1,
      [dir='rtl'] #fbuilder .col-sm-10,
      [dir='rtl'] #fbuilder .col-sm-11,
      [dir='rtl'] #fbuilder .col-sm-2,
      [dir='rtl'] #fbuilder .col-sm-3,
      [dir='rtl'] #fbuilder .col-sm-4,
      [dir='rtl'] #fbuilder .col-sm-5,
      [dir='rtl'] #fbuilder .col-sm-6,
      [dir='rtl'] #fbuilder .col-sm-7,
      [dir='rtl'] #fbuilder .col-sm-8,
      [dir='rtl'] #fbuilder .col-sm-9 {
        float: right;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-0 {
        margin-right: 0;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-1 {
        margin-right: 8.33333333%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-2 {
        margin-right: 16.66666667%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-3 {
        margin-right: 25%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-4 {
        margin-right: 33.33333333%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-5 {
        margin-right: 41.66666667%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-6 {
        margin-right: 50%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-7 {
        margin-right: 58.33333333%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-8 {
        margin-right: 66.66666667%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-9 {
        margin-right: 75%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-10 {
        margin-right: 83.33333333%;
      }
      [dir='rtl'] #fbuilder .col-sm-offset-11 {
        margin-right: 91.66666667%;
      }
    }
    @media only screen and (min-width: 64em) {
      #fbuilder .col-md-1,
      #fbuilder .col-md-10,
      #fbuilder .col-md-11,
      #fbuilder .col-md-2,
      #fbuilder .col-md-3,
      #fbuilder .col-md-4,
      #fbuilder .col-md-5,
      #fbuilder .col-md-6,
      #fbuilder .col-md-7,
      #fbuilder .col-md-8,
      #fbuilder .col-md-9 {
        float: left;
        clear: none;
        margin-left: 0;
        margin-right: 0;
      }
      #fbuilder .col-md-12 {
        float: none;
        clear: both;
        margin-left: 0;
        margin-right: 0;
      }
      #fbuilder .col-md-1 {
        width: 8.33333333%;
      }
      #fbuilder .col-md-2 {
        width: 16.66666667%;
      }
      #fbuilder .col-md-3 {
        width: 25%;
      }
      #fbuilder .col-md-4 {
        width: 33.33333333%;
      }
      #fbuilder .col-md-5 {
        width: 41.66666667%;
      }
      #fbuilder .col-md-6 {
        width: 50%;
      }
      #fbuilder .col-md-7 {
        width: 58.33333333%;
      }
      #fbuilder .col-md-8 {
        width: 66.66666667%;
      }
      #fbuilder .col-md-9 {
        width: 75%;
      }
      #fbuilder .col-md-10 {
        width: 83.33333333%;
      }
      #fbuilder .col-md-11 {
        width: 91.66666667%;
      }
      #fbuilder .col-md-12 {
        width: 100%;
      }
      #fbuilder .col-md-offset-0 {
        margin-left: 0;
      }
      #fbuilder .col-md-offset-1 {
        margin-left: 8.33333333%;
      }
      #fbuilder .col-md-offset-2 {
        margin-left: 16.66666667%;
      }
      #fbuilder .col-md-offset-3 {
        margin-left: 25%;
      }
      #fbuilder .col-md-offset-4 {
        margin-left: 33.33333333%;
      }
      #fbuilder .col-md-offset-5 {
        margin-left: 41.66666667%;
      }
      #fbuilder .col-md-offset-6 {
        margin-left: 50%;
      }
      #fbuilder .col-md-offset-7 {
        margin-left: 58.33333333%;
      }
      #fbuilder .col-md-offset-8 {
        margin-left: 66.66666667%;
      }
      #fbuilder .col-md-offset-9 {
        margin-left: 75%;
      }
      #fbuilder .col-md-offset-10 {
        margin-left: 83.33333333%;
      }
      #fbuilder .col-md-offset-11 {
        margin-left: 91.66666667%;
      }
      [dir='rtl'] #fbuilder .col-md-1,
      [dir='rtl'] #fbuilder .col-md-10,
      [dir='rtl'] #fbuilder .col-md-11,
      [dir='rtl'] #fbuilder .col-md-2,
      [dir='rtl'] #fbuilder .col-md-3,
      [dir='rtl'] #fbuilder .col-md-4,
      [dir='rtl'] #fbuilder .col-md-5,
      [dir='rtl'] #fbuilder .col-md-6,
      [dir='rtl'] #fbuilder .col-md-7,
      [dir='rtl'] #fbuilder .col-md-8,
      [dir='rtl'] #fbuilder .col-md-9 {
        float: right;
      }
      [dir='rtl'] #fbuilder .col-md-offset-0 {
        margin-right: 0;
      }
      [dir='rtl'] #fbuilder .col-md-offset-1 {
        margin-right: 8.33333333%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-2 {
        margin-right: 16.66666667%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-3 {
        margin-right: 25%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-4 {
        margin-right: 33.33333333%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-5 {
        margin-right: 41.66666667%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-6 {
        margin-right: 50%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-7 {
        margin-right: 58.33333333%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-8 {
        margin-right: 66.66666667%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-9 {
        margin-right: 75%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-10 {
        margin-right: 83.33333333%;
      }
      [dir='rtl'] #fbuilder .col-md-offset-11 {
        margin-right: 91.66666667%;
      }
    }
    @media only screen and (min-width: 75em) {
      #fbuilder .col-lg-1,
      #fbuilder .col-lg-10,
      #fbuilder .col-lg-11,
      #fbuilder .col-lg-2,
      #fbuilder .col-lg-3,
      #fbuilder .col-lg-4,
      #fbuilder .col-lg-5,
      #fbuilder .col-lg-6,
      #fbuilder .col-lg-7,
      #fbuilder .col-lg-8,
      #fbuilder .col-lg-9 {
        float: left;
        clear: none;
        margin-left: 0;
        margin-right: 0;
      }
      #fbuilder .col-lg-12 {
        float: none;
        clear: both;
        margin-left: 0;
        margin-right: 0;
      }
      #fbuilder .col-lg-1 {
        width: 8.33333333%;
      }
      #fbuilder .col-lg-2 {
        width: 16.66666667%;
      }
      #fbuilder .col-lg-3 {
        width: 25%;
      }
      #fbuilder .col-lg-4 {
        width: 33.33333333%;
      }
      #fbuilder .col-lg-5 {
        width: 41.66666667%;
      }
      #fbuilder .col-lg-6 {
        width: 50%;
      }
      #fbuilder .col-lg-7 {
        width: 58.33333333%;
      }
      #fbuilder .col-lg-8 {
        width: 66.66666667%;
      }
      #fbuilder .col-lg-9 {
        width: 75%;
      }
      #fbuilder .col-lg-10 {
        width: 83.33333333%;
      }
      #fbuilder .col-lg-11 {
        width: 91.66666667%;
      }
      #fbuilder .col-lg-12 {
        width: 100%;
      }
      #fbuilder .col-lg-offset-0 {
        margin-left: 0;
      }
      #fbuilder .col-lg-offset-1 {
        margin-left: 8.33333333%;
      }
      #fbuilder .col-lg-offset-2 {
        margin-left: 16.66666667%;
      }
      #fbuilder .col-lg-offset-3 {
        margin-left: 25%;
      }
      #fbuilder .col-lg-offset-4 {
        margin-left: 33.33333333%;
      }
      #fbuilder .col-lg-offset-5 {
        margin-left: 41.66666667%;
      }
      #fbuilder .col-lg-offset-6 {
        margin-left: 50%;
      }
      #fbuilder .col-lg-offset-7 {
        margin-left: 58.33333333%;
      }
      #fbuilder .col-lg-offset-8 {
        margin-left: 66.66666667%;
      }
      #fbuilder .col-lg-offset-9 {
        margin-left: 75%;
      }
      #fbuilder .col-lg-offset-10 {
        margin-left: 83.33333333%;
      }
      #fbuilder .col-lg-offset-11 {
        margin-left: 91.66666667%;
      }
      [dir='rtl'] #fbuilder .col-lg-1,
      [dir='rtl'] #fbuilder .col-lg-10,
      [dir='rtl'] #fbuilder .col-lg-11,
      [dir='rtl'] #fbuilder .col-lg-2,
      [dir='rtl'] #fbuilder .col-lg-3,
      [dir='rtl'] #fbuilder .col-lg-4,
      [dir='rtl'] #fbuilder .col-lg-5,
      [dir='rtl'] #fbuilder .col-lg-6,
      [dir='rtl'] #fbuilder .col-lg-7,
      [dir='rtl'] #fbuilder .col-lg-8,
      [dir='rtl'] #fbuilder .col-lg-9 {
        float: right;
      }
      [dir='rtl'] [dir='rtl'] #fbuilder .col-lg-offset-0 {
        margin-right: 0;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-1 {
        margin-right: 8.33333333%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-2 {
        margin-right: 16.66666667%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-3 {
        margin-right: 25%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-4 {
        margin-right: 33.33333333%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-5 {
        margin-right: 41.66666667%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-6 {
        margin-right: 50%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-7 {
        margin-right: 58.33333333%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-8 {
        margin-right: 66.66666667%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-9 {
        margin-right: 75%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-10 {
        margin-right: 83.33333333%;
      }
      [dir='rtl'] #fbuilder .col-lg-offset-11 {
        margin-right: 91.66666667%;
      }
    }
    @media screen and (max-width: 640px) {
      #fbuilder .medium,
      #fbuilder .small {
        width: 100% !important;
      }
    }
    #fbuilder .two_column {
      float: left;
      width: 48%;
    }
    #fbuilder .three_column {
      float: left;
      width: 30%;
    }
    #fbuilder .side_by_side {
      float: left;
      margin-right: 10px;
    }
    [dir='rtl'] #fbuilder .two_column {
      float: right;
    }
    [dir='rtl'] #fbuilder .three_column {
      float: right;
    }
    [dir='rtl'] #fbuilder .side_by_side {
      float: right;
      margin-left: 10px;
    }
    #fbuilder .dfield {
      position: relative;
    }
    @media print, screen and (min-width: 640px) {
      #fbuilder .left_aligned .fields:not(.section_breaks) label:not(:empty) {
        float: left;
        width: 35%;
        padding-right: 10px;
      }
      #fbuilder .left_aligned .fields label:not(:empty) + .dfield {
        float: left;
        width: 65%;
      }
      #fbuilder .right_aligned .fields:not(.section_breaks) label:not(:empty) {
        float: left;
        width: 35%;
        text-align: right;
        padding-right: 10px;
      }
      #fbuilder .right_aligned .fields label:not(:empty) + .dfield {
        float: left;
        width: 65%;
      }
    }
    #fbuilder .right_aligned .fields .dfield label {
      text-align: left;
    }
    [dir='rtl'] #fbuilder .left_aligned .fields label:not(:empty) {
      float: right;
    }
    [dir='rtl'] #fbuilder .left_aligned .fields label:not(:empty) + .dfield {
      float: right;
    }
    [dir='rtl']
      #fbuilder
      .right_aligned
      .fields:not(.section_breaks)
      label:not(:empty) {
      float: right;
      text-align: left;
    }
    [dir='rtl'] #fbuilder .right_aligned .fields label:not(:empty) + .dfield {
      float: right;
    }
    [dir='rtl']
      #fbuilder
      .right_aligned
      .fields:not(.section_breaks)
      .dfield
      label {
      text-align: right;
    }
    #fbuilder .one_column label,
    #fbuilder .side_by_side label,
    #fbuilder .three_column label,
    #fbuilder .two_column label {
      width: 100% !important;
    }
    #fbuilder .left_aligned .cff-html-field .dfield,
    #fbuilder .left_aligned .comment_area.fields label,
    #fbuilder .left_aligned .section_breaks.fields label,
    #fbuilder .right_aligned .cff-html-field .dfield,
    #fbuilder .right_aligned .comment_area.fields label,
    #fbuilder .right_aligned .section_breaks.fields label {
      width: 100%;
      clear: both;
    }
    #fbuilder .cpefb_error,
    #fbuilder .cpefb_error + select,
    #fbuilder .cpefb_error + select + select,
    #fbuilder .cpefb_error + select + select + select {
      border: 1px solid red;
      color: red !important;
    }
    #fbuilder .left_aligned .fields label.cpefb_error,
    #fbuilder .right_aligned .fields label.cpefb_error,
    #fbuilder .top_aligned .fields label.cpefb_error {
      display: block;
      border: 0;
      color: red;
      float: none;
    }
    #fbuilder div.cpefb_error {
      background: #fdf9e5;
      border: 1px solid red;
      opacity: 0.9;
      margin-top: 7px;
      position: relative;
      padding: 0 5px;
      z-index: 1000;
    }
    #fbuilder div.cpefb_error:after {
      content: '';
      position: absolute;
      background: transparent no-repeat scroll 10px center;
      color: red;
      font-weight: 700;
      height: 8px;
      width: 100%;
      top: -8px;
      left: 0;
      z-index: 1000;
    }
    .ui-datepicker {
      z-index: 9999 !important;
    }
    #fbuilder span.files-list,
    #fbuilder span.uh {
      display: block;
      font-size: 80%;
      clear: both;
    }
    #fbuilder span.files-list {
      padding: 5px 0;
    }
    #fbuilder span.files-list span {
      display: inline-block;
    }
    #fbuilder .section_break {
      border-top: 1px dotted #555;
      clear: both;
    }
    #fbuilder .uh_phone {
      float: left;
      margin: 0 5px;
      font-size: 80%;
      text-align: center;
      position: relative;
    }
    [dir='rtl'] #fbuilder .uh_phone {
      float: right;
    }
    #fbuilder .uh_phone input {
      text-align: center;
      font-size: 100%;
    }
    #fbuilder .cpp_form {
      text-align: left;
    }
    [dir='rtl'] #fbuilder .cpp_form {
      text-align: right;
    }
    #fbuilder .pbreak {
      display: none;
    }
    #fbuilder .pbreak.pb0 {
      display: block;
    }
    #fbuilder .pbNext,
    #fbuilder .pbPrevious,
    #fbuilder .pbSubmit {
      background: #ccc;
      color: #000;
      display: block;
      float: left;
      cursor: pointer;
      margin: 10px 10px 0 0;
      padding: 5px 20px;
    }
    #fbuilder .submitbtn-disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
    [dir='rtl'] #fbuilder .pbSubmit,
    [dir='rtl'] #fbuilder .pbreak .pbNext,
    [dir='rtl'] #fbuilder .pbreak .pbPrevious {
      float: right;
      margin: 10px 0 0 10px;
    }
    #fbuilder .pbreak.pb0 .pbPrevious,
    #fbuilder .pbreak.pbEnd .pbNext {
      display: none !important;
    }
    #fbuilder .ignore {
      display: none;
    }
    #fbuilder fieldset {
      border: 1px solid #ccc;
      padding: 10px;
    }
    #fbuilder legend {
      border: 1px solid #ccc;
      padding: 5px 10px;
    }
    #fbuilder .cpcaptchalayer,
    .cp_subbtn {
      display: none;
    }
    #fbuilder .captcha img {
      cursor: pointer;
    }
    #fbuilder input[type='checkbox'],
    #fbuilder input[type='radio'] {
      width: auto;
    }
    .ui-tooltip.uh-tooltip {
      width: 250px;
      font-size: 10px;
      font-weight: 400;
      padding: 5px;
      z-index: 9999 !important;
    }
    .ui-tooltip img {
      max-width: 100%;
    }
    #fbuilder input[type='checkbox'] {
      -webkit-appearance: checkbox !important;
    }
    #fbuilder input[type='radio'] {
      -webkit-appearance: radio !important;
    }
    #fbuilder .dfield.slider-container {
      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-top: 5px;
    }
    #fbuilder .dfield .slider {
      margin-bottom: 5px;
    }
    #fbuilder .cff-summary-title:after {
      content: ': ';
    }
    #fbuilder .hide {
      display: none;
    }
    #fbuilder .hide-strong {
      display: none !important;
    }
    #fbuilder .clear {
      clear: both;
    }
    #fbuilder .apply-coupon {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTE5IDNINWMtMS4xMSAwLTIgLjktMiAydjE0YzAgMS4xLjg5IDIgMiAyaDE0YzEuMTEgMCAyLS45IDItMlY1YzAtMS4xLS44OS0yLTItMnptLTkgMTRsLTUtNSAxLjQxLTEuNDFMMTAgMTQuMTdsNy41OS03LjU5TDE5IDhsLTkgOXoiLz48L3N2Zz4=);
      cursor: pointer;
      vertical-align: middle;
    }
    #fbuilder .discount-container:empty {
      display: none;
    }
    @media print {
      #fbuilder .captcha,
      #fbuilder .pbNext,
      #fbuilder .pbPrevious,
      #fbuilder .pbSubmit,
      #fbuilder input[type='button'],
      #fbuilder input[type='reset'] {
        display: none !important;
      }
      #fbuilder .pbreak {
        display: block;
      }
    }
    #fbuilder .discount-container {
      margin-top: 10px;
      font-weight: 700;
    }
    #fbuilder .cff-close-dlg {
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAowAAAKMB8MeazgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAB5SURBVDiNrZPRCcAwCEQfnUiySAZuF8kSWeH6Yz8KrQZMQAicJ+epAB0YwAmYJKIADLic0/GPPCbQAnLznCd/4NWUFfkgy1VjH8CryA95ApYltAiTRCZxpuoW+gz9WXE6NPeg+ra1UDIxGlWEObe4SGxY5fIxlc75Bkt9V4JS7KWJAAAAAElFTkSuQmCC59ef34356faa7edebc7ed5432ddb673d);
    }
    #fbuilder .cff-dialog {
      position: fixed;
      z-index: 999999;
      padding: 30px;
      width: 600px;
      height: 300px;
      background: #fff;
      top: 50%;
      left: 50%;
      box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px 0;
    }
    #fbuilder .cff-dialog-content {
      width: 100%;
      height: 100%;
      overflow: auto;
      white-space: normal;
    }
  }
}
