@import 'assets/styles/elementor/elementor.scss';
@import 'assets/styles/slider-pro/slider-pro.scss';
@import 'assets/styles/jquery-ui-1.8.20.custom.scss';
@import 'assets/styles/stylepublic.scss';

.customPage {
  flex: 1;
  margin-top: -71px;

  &.transparentHeader {
    margin-top: 0px;
  }
}
