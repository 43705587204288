.customElementor {
  :global {
    .elementor-button {
      background-color: #d24f45;
      border-radius: 4px;
      border: none;
      color: #ffffff;
      display: inline-block;
      font-weight: 600;
      max-height: 50px;
      text-align: center;
      text-decoration: none;
    }

    .elementor-button.hovered,
    .elementor-button:hover {
      background-color: #9d302b;
    }

    .elementor-button:disabled {
      background-color: lighten(#d24f45, 30);
    }
  }
}
